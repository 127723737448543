import React, {
  FC, memo, useCallback, useEffect, useMemo, useReducer, useState,
} from 'react';
import classNames from 'classnames';
import { deleteCookie, normalizeData, sendSettingsToServer } from '@/helpers/cookiesHelper';
import { ModalName, useToggleModalsContext } from '@/common/contexts/globalModalContext';
import { ReactComponent as Spinner } from '@/assets/img/spinner.svg';
import ModalFrame from '@/common/layouts/MasterLayout/components/ModalFrame/ModalFrame';
import { CookieSettingsModalData, CookieSettingsModalTab, GDPRDataProps } from './types';
import TabContent from './TabContent';
import styles from './CookieSettingsModal.module.scss';

const globalAny: any = global; // hack to add properties to Global object

const reducer = (state: CookieSettingsModalData, action: any) => {
  switch (action.type) {
    case 'setActiveTab':
      return { ...state, activeTabId: action.payload };
    case 'toggleSetting':
      return {
        ...state,
        cookieSettingsTabs: state.cookieSettingsTabs.map(
          (el: CookieSettingsModalTab) => (
            el.id === action.payload ? { ...el, active: !el.active } : el),
        ),
      };
    case 'setData':
      return action.payload;
    default:
      throw new Error('Unexpected action');
  }
};

interface CookieSettingsModalProps {
  gdprData: GDPRDataProps;
}

const CookieSettingsModal: FC<CookieSettingsModalProps> = memo(({
  gdprData,
}: CookieSettingsModalProps) => {
  const [
    {
      cookieSettingsTabs, activeTabId, logoUrl, commonLabels, userId, gaId,
    }, dispatch,
  ] = useReducer(reducer, normalizeData(gdprData));

  const toggleModal = useToggleModalsContext();

  const [isPending, setIsPending] = useState(false);

  const activeTabContent = useMemo(
    () => cookieSettingsTabs.find(
      (cookieSettingsTab: CookieSettingsModalTab) => cookieSettingsTab.id === activeTabId,
    ),
    [cookieSettingsTabs, activeTabId],
  );
  // allow scripts and send event to gtm
  useEffect(() => {
    document.querySelectorAll('[data-gdpr-type]').forEach((el: HTMLElement) => {
      const gdprTypes = el.dataset.gdprType?.split(',') || [];
      const allow = gdprTypes.every((type: string) => {
        const cookieSettings = cookieSettingsTabs.find(
          (cookieSettingsTab: CookieSettingsModalTab) => cookieSettingsTab.types
            && cookieSettingsTab.types.includes(type),
        );

        return cookieSettings ? cookieSettings.active : false;
      });
      if (allow) {
        const activeScript = document.createElement('script');
        Object.keys(el.attributes).forEach((attributeKey: string) => {
          const attribute = el.attributes[attributeKey];
          if (attribute.name === 'type') {
            activeScript.setAttribute('type', 'text/javascript');

            return;
          }
          activeScript.setAttribute(attribute.name, attribute.value);
        });

        activeScript.innerHTML = el.innerHTML;
        el.parentNode?.replaceChild(activeScript, el);

        gdprTypes.forEach((gdprType: string) => {
          if (globalAny.dataLayer && el.hasAttribute('data-gdpr-gtm')) {
            globalAny.dataLayer.push({
              event: `gdprEnable${gdprType.charAt(0).toUpperCase()}${gdprType.slice(1)}Cookies`,
            });
          }
        });
      }
    });
  }, []);

  const onAllowAll = useCallback(() => {
    if (isPending) {
      return;
    }
    const body: any = {
      AllowPerformanceCookies: true,
      AllowAdvertisingCookies: true,
      AllowFunctionalCookies: true,
      AllowABTestingCookies: true,
    };
    if (userId) {
      body.id = userId;
    }
    setIsPending(true);
    sendSettingsToServer(userId, body, gaId).then(() => {
      setIsPending(false);
      toggleModal({ [ModalName.cookies]: false });
      window.location.reload();
    });
  }, [
    userId, gaId, isPending,
  ]);

  const onUpdateSettings = useCallback(() => {
    if (isPending) {
      return;
    }
    const body = cookieSettingsTabs.reduce((
      acc: any,
      cookieSettingsTab: CookieSettingsModalTab,
    ) => {
      const options = cookieSettingsTab.options
        ? cookieSettingsTab.options.reduce((accum, option) => ({ ...accum, [option]: cookieSettingsTab.active }), {})
        : {};

      return {
        ...acc,
        ...options,
      };
    }, userId ? { Id: userId } : {});
    body.AllowABTestingCookies = true;
    setIsPending(true);
    sendSettingsToServer(userId, body, gaId).then(() => {
      cookieSettingsTabs.forEach((tab: CookieSettingsModalTab) => {
        if (tab.active === false) {
          if (tab.cookieList) {
            tab.cookieList.forEach((cookie) => {
              deleteCookie(cookie.name);
            });
          }
        }
      });

      setIsPending(false);
      toggleModal({ [ModalName.cookies]: false });
      window.location.reload();
    });
  }, [
    userId, cookieSettingsTabs, gaId, isPending,
  ]);

  return (
    <ModalFrame
      customClassName={styles.container}
      logoUrl={logoUrl}
      modalName={ModalName.cookies}
      title={commonLabels.modalTitle}
    >
      <div className={styles.modalContent}>
        <div>
          {cookieSettingsTabs.map(({ onClick, id, title }: CookieSettingsModalTab) => (
            <div
              key={id}
              className={classNames(styles.modalTabLabel, (id === activeTabId) && styles.modalTabLabelActive)}
              dangerouslySetInnerHTML={{ __html: title }}
              onClick={(): void => {
                if (typeof onClick === 'function') {
                  onClick();
                } else {
                  dispatch({ type: 'setActiveTab', payload: id });
                }
              }}
            />
          ))}
        </div>
        <TabContent
          commonLabels={commonLabels}
          content={activeTabContent}
          onToggle={useCallback(() => {
            dispatch({ type: 'toggleSetting', payload: activeTabId });
          }, [activeTabId])}
        />
      </div>
      <div className={styles.modalFooter}>
        <button
          className={styles.modalFooterBtn}
          onClick={onAllowAll}
          type="button"
        >
          {isPending ? <Spinner /> : commonLabels.allowAll}
        </button>
        <button
          className={styles.modalFooterBtn}
          onClick={onUpdateSettings}
          type="button"
        >
          {isPending ? <Spinner /> : commonLabels.updateSettings}
        </button>
      </div>
    </ModalFrame>
  );
});

export default CookieSettingsModal;
