import React, { FC, memo } from 'react';
import classNames from 'classnames';
import keys from 'weak-key';
import grid from '@/common/css/grid.module.scss';
import styles from './AdditionalLinks.module.scss';

interface AdditionalLinksProps {
  openCookiePreferenceCentre: () => void;
  openCommunicationTermsModal: () => void;
  links: MenuLink[];
}

interface MenuLink {
  text: string;
  href: string | null;
  modalClass?: string | null;
  isTarget?: boolean;
}

const AdditionalLinks: FC<AdditionalLinksProps> = memo(({
  links, openCommunicationTermsModal, openCookiePreferenceCentre,
}: AdditionalLinksProps) => (
  <ul className={classNames(grid.row, styles.additionalLinks)}>
    {links.map((link: MenuLink) => {
      let clickHandler;
      if (link.modalClass === 'Communications terms') {
        clickHandler = openCommunicationTermsModal;
      }
      if (link.modalClass === 'Cookie Preferences') {
        clickHandler = openCookiePreferenceCentre;
      }

      return (
        <li
          className={classNames(
            grid.col6, grid.colSm4, grid.colMdAuto,
            styles.additionalLinksItem,
          )}
          key={keys(link)}
        >
          <a
            className={styles.additionalLinksItemLink}
            {...(link.isTarget && { target: '_blank' })}
            title={link.text}
            {...(link.href && { href: link.href })}
            onClick={clickHandler}
          >
            {link.text}
          </a>
        </li>
      );
    })}
  </ul>
));

export default AdditionalLinks;
