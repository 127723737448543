import React, {
  FC, KeyboardEvent, MouseEvent, SVGProps, useMemo,
} from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { ReactComponent as SpinnerIcon } from '@/assets/icons/spinner.svg';
import { ReactComponent as ArrowBoldIcon } from '@/assets/icons/arrowBold.svg';
import styles from './Button.module.scss';

export enum ButtonType {
  default = 1,
  myAccount,
}

export enum ButtonStyleType {
  primary = 1,
  secondary,
  grey,
  outlined,
  linkButton,
  link,
  filter,
}

export enum ButtonSizeType {
  sm = 1,
  md,
  lg,
}

export enum ButtonArrowType {
  left = 1,
  right,
  top,
  bottom,
}

export enum ButtonIconPosition {
  left,
  right,
}

type DefaultButtonStylesConfig = {
  type: ButtonType.default,
};

type MyAccountButtonStylesConfig = {
  type: ButtonType.myAccount,
  styleType?: ButtonStyleType,
  size?: ButtonSizeType,
};

type ButtonStylesConfig = DefaultButtonStylesConfig | MyAccountButtonStylesConfig;

export interface ButtonProps {
  text?: string;
  href?: string | null;
  onClick?: (event?: MouseEvent) => void;
  onKeyDown?: (event?: KeyboardEvent) => void;
  buttonStylesConfig?: ButtonStylesConfig;
  arrowType?: ButtonArrowType,
  isInProcess?: boolean;
  isDisabled?: boolean;
  customClassNames?: {
    button?: string;
    arrow?: string;
    icon?: string;
  }
  iconComponent?: FC<SVGProps<SVGSVGElement>>;
  iconPosition?: ButtonIconPosition;
  isRouterLink?: boolean;
  additionalAttributes?: {
    [key: string]: string;
  };
}

const buttonStyleClassNames = {
  [ButtonStyleType.primary]: styles.primary,
  [ButtonStyleType.secondary]: styles.secondary,
  [ButtonStyleType.grey]: styles.grey,
  [ButtonStyleType.outlined]: styles.outlined,
  [ButtonStyleType.linkButton]: styles.linkButton,
  [ButtonStyleType.link]: styles.link,
  [ButtonStyleType.filter]: styles.filter,
};

const buttonSizeClassNames = {
  [ButtonSizeType.sm]: styles.small,
  [ButtonSizeType.md]: styles.medium,
  [ButtonSizeType.lg]: styles.large,
};

const arrowClassNames = {
  [ButtonArrowType.left]: styles.left,
  [ButtonArrowType.right]: styles.right,
  [ButtonArrowType.top]: styles.top,
  [ButtonArrowType.bottom]: styles.bottom,
};

const customIconClassNames = {
  [ButtonIconPosition.left]: styles.left,
  [ButtonIconPosition.right]: styles.right,
};

const buttonWithArrowClassNames = {
  [ButtonArrowType.left]: styles.withLeftArrow,
  [ButtonArrowType.right]: styles.withRightArrow,
  [ButtonArrowType.top]: styles.withTopArrow,
  [ButtonArrowType.bottom]: styles.withBottomArrow,
};

const getStyles = (config: ButtonStylesConfig) => {
  if (config.type === ButtonType.myAccount) {
    return [
      styles.button,
      buttonStyleClassNames[config.styleType ?? ButtonStyleType.secondary],
      buttonSizeClassNames[config.size ?? ButtonSizeType.lg],
    ];
  }

  return [styles.defaultButton];
};

const Button: FC<ButtonProps> = ({
  text,
  href,
  onClick,
  onKeyDown,
  arrowType,
  isInProcess,
  isDisabled,
  customClassNames,
  isRouterLink,
  iconComponent: IconComponent,
  iconPosition = ButtonIconPosition.right,
  additionalAttributes,
  buttonStylesConfig = { type: ButtonType.default },
}) => {
  const buttonStyles = getStyles(buttonStylesConfig);
  const buttonCommonStyles = [
    !text && styles.withoutText,
    (arrowType && !!text) && buttonWithArrowClassNames[arrowType],
    isInProcess && styles.isInProcess,
    isDisabled && styles.isDisabled,
    customClassNames?.button,
  ];

  const buttonClassName = classNames(...buttonStyles, ...buttonCommonStyles);

  const content = useMemo(() => {
    const customIconPositionClassName = !!IconComponent && customIconClassNames[iconPosition];
    const iconWithText = (
      <>
        {text}
        {(IconComponent && !isInProcess) && (
          <IconComponent className={classNames(
            styles.customIcon,
            customIconPositionClassName,
            customClassNames?.icon,
          )}
          />
        )}
        {isInProcess && (
          <SpinnerIcon className={classNames(
            styles.spinnerIcon,
            customIconPositionClassName,
            customClassNames?.icon,
          )}
          />
        )}
      </>
    );

    if (!arrowType) {
      return iconWithText;
    }

    return (
      <>
        {iconWithText}
        <ArrowBoldIcon className={classNames(styles.arrowIcon, customClassNames?.arrow, arrowClassNames[arrowType])} />
      </>
    );
  }, [
    isInProcess, text, IconComponent, arrowType, iconPosition, customClassNames?.arrow, customClassNames?.icon,
  ]);

  if (href) {
    if (isRouterLink) {
      return (
        <Link
          className={buttonClassName}
          to={href}
          {...additionalAttributes}
        >
          {content}
        </Link>
      );
    }

    return (
      <a
        className={buttonClassName}
        href={href}
        {...additionalAttributes}
      >
        {content}
      </a>
    );
  }

  return (
    <button
      className={buttonClassName}
      disabled={isDisabled || isInProcess}
      onClick={onClick}
      onKeyDown={onKeyDown}
      type="button"
      {...additionalAttributes}
    >
      {content}
    </button>
  );
};

export default Button;
