import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import { ReactComponent as ArrowIcon } from '@/assets/icons/arrow.svg';
import { ReactComponent as ArrowBoldIcon } from '@/assets/icons/arrowBold.svg';
import styles from '@/common/components/Carousel/Arrows/Arrows.module.scss';

interface ArrowsProps {
  goToPrevSlide: () => void;
  goToNextSlide: () => void;
  classNameLeft?: string;
  classNameRight?: string;
  isBoldIcon?: boolean;
}

const cyNames = {
  prevArrow: 'carousel-arrow-prev',
  nextArrow: 'carousel-arrow-next',
};

const Arrows: FC<ArrowsProps> = ({
  goToPrevSlide,
  goToNextSlide,
  classNameLeft,
  classNameRight,
  isBoldIcon,
}) => useMemo(() => {
  const Icon = isBoldIcon ? ArrowBoldIcon : ArrowIcon;

  return (
    <>
      <button
        className={classNames(styles.button, styles.buttonLeft, classNameLeft)}
        data-cy={cyNames.prevArrow}
        onClick={goToPrevSlide}
        type="button"
      >
        <Icon className={styles.icon} />
      </button>
      <button
        className={classNames(styles.button, styles.buttonRight, classNameRight)}
        data-cy={cyNames.nextArrow}
        onClick={goToNextSlide}
        type="button"
      >
        <Icon className={styles.icon} />
      </button>
    </>
  );
}, [
  goToPrevSlide, goToNextSlide, classNameLeft, classNameRight, isBoldIcon,
]);
Arrows.displayName = 'Arrows';

export default Arrows;
