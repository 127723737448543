import React, {
  FC, memo, ReactNode, useRef,
} from 'react';
import classNames from 'classnames';
import ParallaxPageLayout, { ParallaxPageLayoutProps } from '@/common/layouts/ParallaxPageLayout/ParallaxPageLayout';
import BreadcrumbsSection, { BreadcrumbsSectionProps }
  from '@/common/layouts/ArticlePreviewLayout/components/BreadcrumbsSection/BreadcrumbsSection';
import ReadNext, { ReadNextProps } from '@/common/layouts/ArticlePreviewLayout/components/ReadNext/ReadNext';
import Sidebar from '@/common/layouts/ArticlePreviewLayout/components/Sidebar/Sidebar';
import grid from '@/common/css/grid.module.scss';
import styles from './ArticlePreviewLayout.module.scss';

export interface ArticlePreviewLayoutProps extends ParallaxPageLayoutProps, BreadcrumbsSectionProps {
  title?: string;
  date?: string;
  sidebarContent?: ReactNode;
  readNextBlock?: ReadNextProps;
}

const emptyTitleText = 'No Title';

const ArticlePreviewLayout: FC<ArticlePreviewLayoutProps> = memo(({
  date,
  title,
  backToListingButton,
  socialLinksBlock,
  sidebarContent,
  readNextBlock,
  children,
  ...pageLayoutProps
}: ArticlePreviewLayoutProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <ParallaxPageLayout {...pageLayoutProps}>
      <>
        <BreadcrumbsSection backToListingButton={backToListingButton} socialLinksBlock={socialLinksBlock} />
        <div
          ref={containerRef}
          id="article-content"
          className={classNames(grid.container, styles.container)}
        >
          <div className={grid.row}>
            <div className={classNames(
              grid.colSm10, grid.colMd8, grid.colLg7,
              grid.offsetSm1, grid.offsetMd0, grid.offsetLg1,
            )}
            >
              {date && (date !== emptyTitleText) && (
                <div>{date}</div>
              )}
              <h2 className={styles.title}>{title}</h2>
              {children}
              {!!readNextBlock && (
                <ReadNext {...readNextBlock} />
              )}
            </div>
          </div>

          {!!sidebarContent && (
            <div className={styles.sidebarWrapper}>
              <Sidebar parentContainerRef={containerRef}>
                {sidebarContent}
              </Sidebar>
            </div>
          )}
        </div>
      </>
    </ParallaxPageLayout>
  );
});

export default ArticlePreviewLayout;
