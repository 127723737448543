import React, { CSSProperties, FC } from 'react';
import classNames from 'classnames';
import { ResponsiveImageUrls } from '@/types';
import ResponsiveImage from '@/common/components/ResponsiveImage/ResponsiveImage';
import grid from '@/common/css/grid.module.scss';
import styles from './ListCard.module.scss';

export interface ListCardProps {
  title: string;
  description: string;
  imageUrls: ResponsiveImageUrls;
  link?: string;
  mark?: string;
  onClick?: () => void;
  customClasses?: {
    container?: string;
    imageContainer?: string;
    textContainer?: string;
    title?: string;
    description?: string;
  };
  style?: CSSProperties;
}

const ListCard: FC<ListCardProps> = ({
  customClasses,
  title,
  description,
  imageUrls,
  link,
  onClick,
  mark,
  style,
}: ListCardProps) => (
  <div
    className={classNames(grid.colSm6, grid.colMd4, styles.wrapper, customClasses?.container)}
    style={style}
  >
    <a className={styles.link} href={link} onClick={onClick}>
      {mark && (
        <div className={styles.mark}>{mark}</div>
      )}
      <div className={classNames(styles.imageContainer, customClasses?.imageContainer)}>
        <ResponsiveImage className={styles.image} alt={title} urls={imageUrls} />
      </div>
      <div className={classNames(styles.textContainer, customClasses?.textContainer)}>
        <div className={classNames(styles.title, customClasses?.title)}>
          {title}
        </div>
        <div className={classNames(styles.description, customClasses?.description)}>
          {description}
        </div>
      </div>
    </a>
  </div>
);

export default ListCard;
