import { deleteCookie, getCookie } from '@/helpers/cookiesHelper';
import jwtDecode, { JwtPayload } from 'jwt-decode';

interface DecodedAccessToken extends JwtPayload {
  firstname: string;
  lastname: string;
  companyname: string;
  emailaddress: string;
}

interface UserData {
  firstName: string;
  lastName: string;
  company: string;
  email: string;
}

const accessTokenCookieName = 'access-token';
const defaultEmailAddress = 'emailaddress';

export const getAccessToken = (): string => getCookie(accessTokenCookieName);

export const handleUnauthorizedUser = (url?: string | null): void => {
  deleteCookie(accessTokenCookieName);
  if (url) {
    window.location.assign(url);
  }
};

export const getUserData = (): UserData | null => {
  const accessToken = getAccessToken();
  if (!accessToken) {
    return null;
  }
  try {
    const decodedInfo = jwtDecode<DecodedAccessToken>(accessToken);
    if (decodedInfo.emailaddress === defaultEmailAddress) {
      return null;
    }

    return {
      firstName: decodedInfo.firstname,
      lastName: decodedInfo.lastname,
      company: decodedInfo.companyname,
      email: decodedInfo.emailaddress,
    };
  } catch (e) {
    return null;
  }
};

export const checkAndRedirectUser = (url?: string): void => {
  const userData = getUserData();
  if (userData?.email) {
    return;
  }
  handleUnauthorizedUser(url);
};

export const getUserEmail = (): string => {
  const userData = getUserData();
  if (!userData) {
    return '';
  }

  return userData.email;
};
