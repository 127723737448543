import React, { FC } from 'react';
import classNames from 'classnames';
import Pages, { PagesProps } from '@/common/components/Pagination/Pages';
import { ReactComponent as ArrowIcon } from '@/assets/icons/arrow.svg';
import styles from './Pagination.module.scss';

export interface PaginationProps extends PagesProps {
  isDisplayed: boolean;
  prevButtonUrl: string | null;
  nextButtonUrl: string | null;
}

const Pagination: FC<PaginationProps> = ({
  isDisplayed, buttons, prevButtonUrl, nextButtonUrl,
}: PaginationProps) => {
  if (!isDisplayed || !buttons || !buttons.length) {
    return null;
  }

  return (
    <ul className={styles.pagination}>
      <li className={styles.paginationItemArrowPrev}>
        <a href={prevButtonUrl || ''}>
          <ArrowIcon
            className={classNames(
              styles.paginationArrow, !prevButtonUrl && styles.paginationArrowDisabled,
            )}
          />
        </a>
      </li>
      <Pages buttons={buttons} />
      <li className={styles.paginationItemArrowNext}>
        <a href={nextButtonUrl || ''}>
          <ArrowIcon
            className={classNames(
              styles.paginationArrow, styles.paginationArrowNext, !nextButtonUrl && styles.paginationArrowDisabled,
            )}
          />
        </a>
      </li>
    </ul>
  );
};

export default Pagination;
