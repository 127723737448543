import React, {
  FC, memo, useMemo,
} from 'react';
import { ResponsiveImageUrls } from '@/types';
import Carousel from '@/common/components/Carousel/Carousel';
import styles from './Gallery.module.scss';

export interface GalleryProps {
  galleryImageCrops?: ResponsiveImageUrls[];
}

const Gallery: FC<GalleryProps> = memo(({ galleryImageCrops }: GalleryProps) => {
  const arrowsProps = useMemo(() => ({
    classNameLeft: styles.arrowLeft,
    classNameRight: styles.arrowRight,
  }), []);

  const dotsProps = useMemo(() => ({
    className: styles.dots,
  }), []);

  if (!galleryImageCrops?.length) {
    return null;
  }

  const galleryImages = galleryImageCrops?.map((galleryImage: ResponsiveImageUrls) => {
    const galleryImageUrls = {};
    Object.keys(galleryImage).forEach((galleryImageKey) => {
      galleryImageUrls[galleryImageKey] = galleryImage[galleryImageKey].replace(/&height=\d+/g, '');
    });

    return galleryImageUrls;
  });

  return (
    <div className={styles.container}>
      <Carousel
        dotsProps={dotsProps}
        arrowsProps={arrowsProps}
        items={galleryImages}
        shouldOpenFullscreen
      />
    </div>
  );
});

export default Gallery;
