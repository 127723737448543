import React, { FC, useCallback, useEffect } from 'react';
import { GDPROptions } from '@/common/layouts/ProtectedLayout/components/LoginModal/LoginForm/types';
import { ModalName, useModalsContext, useToggleModalsContext } from '@/common/contexts/globalModalContext';
import ModalLayout, { ModalLayoutProps } from '@/common/components/Modal/ModalLayout/ModalLayout';
import LoginForm, { LoginFormProps } from '@/common/layouts/ProtectedLayout/components/LoginModal/LoginForm/LoginForm';

export interface LoginModalProps extends LoginFormProps, ModalLayoutProps {
  isAuthorized?: boolean;
  closePopupRedirectUrl?: string;
}

interface LoginModalComponentProps extends LoginModalProps {
  gdprOptions: GDPROptions;
}

const LoginModal: FC<LoginModalComponentProps> = ({
  modalWindowTitle,
  closePopupRedirectUrl,
  isAuthorized,
  ...formProps
}) => {
  const toggleModal = useToggleModalsContext();
  const { modalsState } = useModalsContext();

  const closeHandler = useCallback((): void => {
    toggleModal({ [ModalName.login]: false });
    if (closePopupRedirectUrl) {
      window.location.assign(closePopupRedirectUrl);
    }
  }, [toggleModal, closePopupRedirectUrl]);

  const showLoginModal = useCallback((): void => {
    toggleModal({ [ModalName.login]: true });
  }, [toggleModal]);

  useEffect(() => {
    if (!isAuthorized) {
      showLoginModal();
    }
  }, []);

  const isShown = modalsState[ModalName.login];

  if (!isShown) {
    return null;
  }

  return (
    <ModalLayout
      closeHandler={closeHandler}
      isSingleColumn
      modalWindowTitle={modalWindowTitle}
    >
      <LoginForm {...formProps} />
    </ModalLayout>
  );
};

export default LoginModal;
