import React, {
  FC, ReactNode, RefObject, useRef,
} from 'react';
import classNames from 'classnames';
import useStickyPosition from '@/common/layouts/ArticlePreviewLayout/components/Sidebar/useStickyPosition';
import styles from './Sidebar.module.scss';

interface SidebarProps {
  parentContainerRef: RefObject<HTMLDivElement> | null;
  children?: ReactNode;
}

const Sidebar: FC<SidebarProps> = ({
  parentContainerRef, children,
}: SidebarProps) => {
  const cardRef = useRef<HTMLDivElement>(null);

  const cardTopPosition = useStickyPosition(cardRef, parentContainerRef);

  let wrapperStyle = {};
  if (cardTopPosition || cardTopPosition === 0) {
    wrapperStyle = { top: `${cardTopPosition}px`, position: 'fixed' };
  }

  return (
    <div ref={cardRef} style={wrapperStyle} className={classNames(styles.container)}>
      {children}
    </div>
  );
};

export default Sidebar;
