import React, { FC, memo } from 'react';
import key from 'weak-key';
import { ModalName } from '@/common/contexts/globalModalContext';
import ExternalModalForm, { ExternalFormProps } from '@/common/components/Modal/ExternalModalForms/ExternalModalForm';

export interface ExternalFormsProps {
  forms?: ExternalFormProps[];
}

export interface ExternalModalFormsProps {
  pardot?: ExternalFormsProps;
}

interface ExternalModalFormsComponentProps extends ExternalModalFormsProps {
  titles?: {
    [key in ModalName]?: string;
  };
}

const ExternalModalForms: FC<ExternalModalFormsComponentProps> = ({
  pardot,
  titles,
}) => {
  if (!pardot?.forms?.length) {
    return null;
  }

  return (
    <>
      {pardot.forms.map((pardotForm) => {
        if (!pardotForm.name || !pardotForm.url) {
          return null;
        }

        return (
          <ExternalModalForm
            key={key(pardotForm)}
            title={titles?.[pardotForm.name]}
            {...pardotForm}
          />
        );
      })}
    </>
  );
};

export default memo(ExternalModalForms);
