import { useCallback, useState } from 'react';
import { validate, Validator } from '@/helpers/validation';

interface Field {
  value: string;
  isValid?: boolean;
  errorMessage?: string;
}

export interface FormState {
  [field: string]: Field;
}

export interface ValidationSchema {
  [field: string]: Validator[];
}

export const useForm = (
  stateSchema: FormState,
  validationSchema: ValidationSchema,
): [FormState, (name: string, value: string) => void, (onSubmit: () => void
  ) => void] => {
  const [state, setState] = useState<FormState>(stateSchema);

  const handleFieldChange = (name: string, value: string): void => {
    setState((prevState: FormState) => ({
      ...prevState,
      [name]: { value, isValid: true, errorMessage: '' },
    }));
  };

  const handleFormSubmit = useCallback((onSubmit: () => void): void => {
    let hasError = false;

    const validateField = (name: string): void => {
      const fieldSchema = validationSchema[name];
      const field = state[name];

      if (!field || !fieldSchema || !fieldSchema.length) {
        return;
      }

      const { value } = field;

      const { isValid, errorMessage } = validate(value, fieldSchema);

      setState((prevState: FormState) => ({
        ...prevState,
        [name]: { value, isValid, errorMessage },
      }));

      if (!isValid) {
        hasError = true;
      }
    };

    Object.keys(state).map(validateField);

    if (hasError) {
      return;
    }

    onSubmit();
  }, [
    state,
    validationSchema,
  ]);

  return [
    state,
    handleFieldChange,
    handleFormSubmit,
  ];
};
