import React, { FC } from 'react';
import key from 'weak-key';
import { useSwipeable } from 'react-swipeable';
import { CarouselImagesType, CarouselSlideProps } from '@/common/components/Carousel/types';
import Slide from '@/common/components/Carousel/Slide/Slide';
import styles from '@/common/components/Carousel/Slides/Slides.module.scss';

export interface SlidesProps {
  items: CarouselImagesType;
  goToPrevSlide: () => void;
  goToNextSlide: () => void;
  activeSlideIndex: number;
  slideProps: CarouselSlideProps;
}

const Slides: FC<SlidesProps> = ({
  items,
  goToPrevSlide,
  goToNextSlide,
  activeSlideIndex,
  slideProps,
}) => {
  const trackStyle = { transform: `translateX(-${activeSlideIndex * 100}%)` };
  const handlers = useSwipeable({
    onSwipedLeft: goToNextSlide,
    onSwipedRight: goToPrevSlide,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.track} style={trackStyle} {...handlers}>
        {items.map((item, index) => (
          <Slide
            key={key(item)}
            index={index}
            isActive={index === activeSlideIndex}
            item={item}
            slideProps={slideProps}
          />
        ))}
      </div>
    </div>
  );
};

export default Slides;
