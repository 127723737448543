import React, {
  createContext, FC, memo, ReactNode, useCallback, useContext, useMemo, useState,
} from 'react';
import lockPageScroll from '@/helpers/lockPageScroll';

export enum ModalName {
  newsletter = 'Newsletter',
  industryInsight = 'IndustryInsight',
  marketReport = 'MarketReport',
  customReport = 'CustomReport',
  managedOffices = 'ManagedOffices',
  enterprise = 'Enterprise',
  joinACT = 'JoinACT',
  contactUs = 'ContactUs',
  getInTouch = 'GetInTouch',
  readMore = 'ReadMore',

  cookies = 'Cookies',
  communicationTerms = 'CommunicationTerms',
  login = 'Login',
  careers = 'Careers',
  discardEnquiry = 'DiscardEnquiry',
}

type ModalsState = {
  [key in ModalName]?: boolean;
};

interface ModalsContextValue {
  modalsState: ModalsState;
  properties: any;
}

interface ModalsProviderProps {
  children: ReactNode;
}

type Toggle = (modalsStatePayload: ModalsState, modalProperties?: any) => void;

const ModalsContext = createContext<ModalsContextValue | null>(null);
const ToggleContext = createContext<Toggle | null>(null);

const checkIfScrollShouldBeLocked = (modifiedModalsState: ModalsState): boolean => (
  Object.keys(modifiedModalsState).some((key) => modifiedModalsState[key])
);

const ModalsProvider: FC<ModalsProviderProps> = ({ children }) => {
  const [modalsState, setModalsState] = useState<ModalsState>({ });
  const [properties, setProperties] = useState<any>({});

  const toggleModal = useCallback((modalsStatePayload: ModalsState, modalProperties?: any) => {
    if (modalProperties) {
      setProperties(modalProperties);
    }

    const modifiedModalsState = { ...modalsState, ...modalsStatePayload };
    setModalsState(modifiedModalsState);

    lockPageScroll(checkIfScrollShouldBeLocked(modifiedModalsState));
  }, [modalsState]);

  const value = useMemo(() => ({
    modalsState,
    properties,
  }), [modalsState, properties]);

  return (
    <ModalsContext.Provider value={value}>
      <ToggleContext.Provider value={toggleModal}>
        {children}
      </ToggleContext.Provider>
    </ModalsContext.Provider>
  );
};
ModalsProvider.displayName = 'ModalsProvider';

export const useModalsContext = (): ModalsContextValue => {
  const context = useContext(ModalsContext);
  if (!context) {
    throw new Error('useModalsContext must be used within a ModalsProvider');
  }

  return context;
};

export const useToggleModalsContext = (): Toggle => {
  const context = useContext(ToggleContext);
  if (!context) {
    throw new Error('useToggleModalsContext must be used within a ModalsProvider');
  }

  return context;
};

export default memo(ModalsProvider);
