import React, { FC } from 'react';
import classNames from 'classnames';
import { ResponsiveImageUrls } from '@/types';
import ResponsiveImage from '@/common/components/ResponsiveImage/ResponsiveImage';
import grid from '@/common/css/grid.module.scss';
import styles from './PageHeader.module.scss';

export interface PageHeaderProps {
  backgroundImages?: ResponsiveImageUrls;
  title?: string;
  titleH1?: string;
  description?: string;
  h2?: string;
  imageTitleH1?: string;
  headerText?: string;
}

const PageHeader: FC<PageHeaderProps> = ({
  backgroundImages, title, description, titleH1, h2, headerText, imageTitleH1,
}: PageHeaderProps) => {
  const h1 = title || titleH1;
  const titleH2 = description || h2 || headerText || imageTitleH1;

  return (
    <div className={classNames(grid.container, styles.container)}>
      {!!backgroundImages && (
        <ResponsiveImage urls={backgroundImages} alt={title} className={styles.image} />
      )}
      <div className={classNames(styles.rowWrapper, grid.row)}>
        <div className={classNames(styles.textContainer, grid.colSm9, grid.colMd8, grid.colLg7, grid.offsetSm1)}>
          {h1 && (
            <h1 className={styles.title}>
              {h1}
            </h1>
          )}
          {titleH2 && (
            <h2 className={styles.description}>
              {titleH2}
            </h2>
          )}
        </div>
      </div>
    </div>
  );
};

PageHeader.displayName = 'PageHeader';

export default PageHeader;
