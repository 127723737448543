const lockPageScroll = (shouldBeLocked: boolean): void => {
  const { documentElement, body } = document;
  if (shouldBeLocked) {
    const scrollbarWidth = (window.innerWidth - document.body.scrollWidth);
    body.style.paddingRight = `${scrollbarWidth}px`;
    documentElement.classList.add('scrollLock');

    return;
  }

  body.style.paddingRight = '0';
  documentElement.classList.remove('scrollLock');
};

export default lockPageScroll;
