import React, { FC } from 'react';
import styles from './ErrorMessage.module.scss';

interface ErrorMessageProps {
  title?: string;
}

const ErrorMessage: FC<ErrorMessageProps> = ({
  title,
}: ErrorMessageProps) => {
  if (!title) {
    return null;
  }

  return (
    <div className={styles.validationError}>
      {title}
    </div>
  );
};

ErrorMessage.displayName = 'ErrorMessage';

export default ErrorMessage;
