import React, { FC } from 'react';
import { ModalName } from '@/common/contexts/globalModalContext';
import CustomRequestModal, { CustomRequestModalProps } from '@/common/components/CustomRequestModal/CustomRequestModal';

export type CustomReportModalProps = CustomRequestModalProps;

const CustomReportModal: FC<CustomReportModalProps> = (
  props,
) => (
  <CustomRequestModal
    {...props}
    endpoint="custominsight"
    modalName={ModalName.customReport}
  />
);

export default CustomReportModal;
