import React, { FC, useCallback } from 'react';
import { ModalName, useToggleModalsContext } from '@/common/contexts/globalModalContext';
import NewsletterModal, {
  NewsletterModalProps,
} from '@/common/components/SignUpSection/NewsletterModal/NewsletterModal';
import Button, { ButtonArrowType } from '@/common/components/Button/Button';
import styles from './SignUpSection.module.scss';

export interface SignUpSectionProps extends NewsletterModalProps {
  h1: string;
  h2: string;
}

interface SignUpSectionComponentProps extends SignUpSectionProps {
  shouldRenderModal?: boolean;
}

const SignUpSection: FC<SignUpSectionComponentProps> = ({
  h1,
  h2,
  shouldRenderModal = true,
  ...newsletterModalProps
}) => {
  const { signUpButtonText } = newsletterModalProps;

  const toggleModal = useToggleModalsContext();

  const showNewsletterModal = useCallback((): void => {
    toggleModal({ [ModalName.newsletter]: true });
  }, [toggleModal]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.mainTitle}>{h1}</div>
        <div className={styles.secondaryTitle}>{h2}</div>
        <Button
          arrowType={ButtonArrowType.right}
          customClassNames={{ button: styles.signUpButton }}
          onClick={showNewsletterModal}
          text={signUpButtonText}
        />
      </div>
      {shouldRenderModal && (
        <NewsletterModal {...newsletterModalProps} />
      )}
    </>
  );
};

export default SignUpSection;
