import React, { FC, useContext } from 'react';
import classNames from 'classnames';
import { ScrollIntoViewContext } from '@/common/contexts/scrollIntoViewContext';
import { ReactComponent as ChevronIcon } from '@/assets/icons/chevron.svg';
import styles from './IntroCard.module.scss';

export interface IntroCardData {
  title: string;
  description: string;
  target?: string;
  targetPageUrl?: {
    href?: string;
    isTarget?: boolean | null;
  };
}

const IntroCard: FC<IntroCardData> = ({
  description,
  title,
  target,
  targetPageUrl,
}) => {
  const { scrollIntoView } = useContext(ScrollIntoViewContext);

  const content = (
    <div className={styles.innerContainer}>
      <div className={styles.titleWrapper}>
        <span className={styles.title}>{title}</span>
        &nbsp;
        <ChevronIcon className={styles.chevron} />
      </div>
      <div className={styles.description}>{description}</div>
    </div>
  );

  if (targetPageUrl?.href) {
    return (
      <a
        className={classNames(styles.container, styles.linkContainer)}
        href={targetPageUrl.href}
        {...(targetPageUrl.isTarget && { target: '_blank' })}
      >
        {content}
      </a>
    );
  }

  const scrollToTarget = (): void => {
    if (!target) {
      return;
    }
    scrollIntoView(target);
  };

  return (
    <div
      className={classNames(styles.container, styles.anchorContainer)}
      onClick={scrollToTarget}
    >
      {content}
    </div>
  );
};

export default IntroCard;
