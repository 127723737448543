import React, { FC, Fragment, ReactElement } from 'react';
import classNames from 'classnames';
import keys from 'weak-key';
import Author, { AuthorProps } from '@/common/components/Author/Author';
import Gallery, { GalleryProps } from '@/common/components/Gallery/Gallery';
import Button, { ButtonArrowType } from '@/common/components/Button/Button';
import styles from './ArticleContent.module.scss';
import './ArticleContent.scss';

export interface ArticleContentComponentProps extends ArticleContentProps, GalleryProps {
  author?: AuthorProps;
  downloadButton?: {
    title: string;
    url: string;
  };
}

export interface ArticleContentProps {
  content?: ArticleContentSectionProps[];
}

interface ArticleContentSectionProps {
  contentType: string;
  contentText: string;
  largeImageUrl?: string | null;
  smallImageUrl?: string | null;
  altImageText?: string | null;
  sectionTitle?: string | null;
}

const emptyTitleText = 'No Title';

const ArticleContent: FC<ArticleContentComponentProps> = ({
  content, galleryImageCrops, author, downloadButton,
}: ArticleContentComponentProps) => (
  <div className={classNames((author || downloadButton) && styles.container)}>
    {content?.map((item: ArticleContentSectionProps): ReactElement => (
      <Fragment key={keys(item)}>
        {item.sectionTitle && (item.sectionTitle !== emptyTitleText) && (
          <div className={styles.sectionTitle}>{item.sectionTitle}</div>
        )}
        <div
          className="articleContentContainer"
          dangerouslySetInnerHTML={{ __html: item.contentText }}
        />
        {!!item.largeImageUrl && (
          <div>
            <img
              alt={item.altImageText || ''}
              className={styles.sectionImage}
              src={item.largeImageUrl}
            />
          </div>
        )}
      </Fragment>
    ))}
    {!!galleryImageCrops && (
      <Gallery galleryImageCrops={galleryImageCrops} />
    )}
    {!!author && (
      <Author {...author} />
    )}
    {!!downloadButton && (
      <Button
        additionalAttributes={{ target: '_blank' }}
        arrowType={ButtonArrowType.right}
        customClassNames={{ button: styles.downloadButton }}
        href={downloadButton.url}
        text={downloadButton.title}
      />
    )}
  </div>
);

export default ArticleContent;
