export enum ValidatorType {
  REQUIRED,
  MAX,
  MIN,
  REGEXP,
  PHONE,
}

const PHONE_NUMBER_REGEXP = /^\+(?:[0-9] ?){6,14}[0-9]$/;

export interface Validator {
  type: ValidatorType;
  errorMessage: string;
}

export interface RequiredValidator extends Validator {
  isRequired: boolean;
}

export interface MaxValidator extends Validator {
  max: number;
}

export interface MinValidator extends Validator {
  min: number;
}

export interface RegExpValidator extends Validator {
  regExp: RegExp;
}

export interface PhoneNumberValidator extends Validator {
  regExp?: RegExp;
}

interface ValidationResult {
  isValid: boolean;
  errorMessage?: string;
}

export const required = (value: string): boolean => !!value.trim();

export const max = (value: string, maxLength: number): boolean => value.length <= maxLength;

export const min = (value: string, minLength: number): boolean => value.length >= minLength;

export const regExp = (value: string, regEx: RegExp): boolean => regEx.test(value);

export const validate = (value: string, validators: Validator[]): ValidationResult => {
  const result: ValidationResult = {
    isValid: true,
  };

  const setResultInvalid = (validator: Validator): void => {
    result.isValid = false;
    result.errorMessage = validator.errorMessage;
  };

  validators.some((validator: Validator) => {
    switch (validator.type) {
      case ValidatorType.REQUIRED:
        if (!(validator as RequiredValidator).isRequired) {
          return false;
        }

        if (!required(value)) {
          setResultInvalid(validator);

          return true;
        }

        return false;
      case ValidatorType.MAX:
        if (!max(value, (validator as MaxValidator).max)) {
          setResultInvalid(validator);

          return true;
        }

        return false;
      case ValidatorType.MIN:
        if (!min(value, (validator as MinValidator).min)) {
          setResultInvalid(validator);

          return true;
        }

        return false;
      case ValidatorType.REGEXP:
        if (!regExp(value, (validator as RegExpValidator).regExp)) {
          setResultInvalid(validator);

          return true;
        }

        return false;
      case ValidatorType.PHONE:
        if (!regExp(value, (validator as PhoneNumberValidator).regExp || PHONE_NUMBER_REGEXP)) {
          setResultInvalid(validator);

          return true;
        }

        return false;
      default:
        return false;
    }
  });

  return result;
};
