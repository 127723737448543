import React, { FC } from 'react';
import { ReactComponent as Tick } from '@/assets/icons/tick.svg';
import LinkedInConversionPixel from '@/common/components/LinkedInConversionPixel/LinkedInConversionPixel';
import styles from './TickIconSuccess.module.scss';

export interface TickIconSuccessProps {
  successTitle: string;
  successSubtitle: string;
  conversionId: string;
}

const TickIconSuccess: FC<TickIconSuccessProps> = ({
  successTitle,
  successSubtitle,
  conversionId,
}: TickIconSuccessProps) => (
  <div className={styles.container}>
    <div className={styles.icon}>
      <Tick className={styles.iconTick} />
    </div>
    <div className={styles.title} data-name="submit-success-title">{successTitle}</div>
    <LinkedInConversionPixel conversionId={conversionId} />
    <div className={styles.subtitle}>{successSubtitle}</div>
  </div>
);

export default TickIconSuccess;
