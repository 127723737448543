import React, {
  FC, memo, ReactNode, RefObject,
} from 'react';
import classNames from 'classnames';
import useScrollPosition from '@/common/layouts/ListingLayout/useScrollPosition';
import ParallaxPageLayout, { ParallaxPageLayoutProps } from '@/common/layouts/ParallaxPageLayout/ParallaxPageLayout';
import Pagination, { PaginationProps } from '@/common/components/Pagination/Pagination';
import SignUpSection, { SignUpSectionProps } from '@/common/components/SignUpSection/SignUpSection';
import grid from '@/common/css/grid.module.scss';
import styles from './ListingLayout.module.scss';

export interface ListingLayoutProps extends ParallaxPageLayoutProps {
  paginationData?: PaginationProps;
  children?: ReactNode;
  signUpSection?: SignUpSectionProps;
}

interface ListingLayoutComponentProps extends ListingLayoutProps {
  itemRefToScroll?: RefObject<HTMLDivElement> | null;
  shouldRenderNewsletterModal?: boolean;
}

const ListingLayout: FC<ListingLayoutComponentProps> = memo(({
  paginationData,
  signUpSection,
  children,
  itemRefToScroll,
  shouldRenderNewsletterModal,
  ...pageLayoutProps
}: ListingLayoutComponentProps) => {
  useScrollPosition(itemRefToScroll);

  return (
    <ParallaxPageLayout {...pageLayoutProps}>
      <div className={classNames(styles.contentContainer, grid.container)}>
        {children}
      </div>
      {paginationData && (
        <Pagination {...paginationData} />
      )}
      {signUpSection && (
        <SignUpSection
          {...signUpSection}
          shouldRenderModal={shouldRenderNewsletterModal}
        />
      )}
    </ParallaxPageLayout>
  );
});

export default ListingLayout;
