import {
  CookieSettingsModalData,
  GDPRDataProps,
} from '@/common/layouts/MasterLayout/components/CookieSettingsModal/types';

const globalAny: any = global; // hack to add properties to Global object

export const getCookie = (name: string): any => {
  const nameLenPlus = (name.length + 1);

  return document.cookie
    .split(';')
    .map((cookie) => cookie.trim())
    .filter((cookie) => cookie.substring(0, nameLenPlus) === `${name}=`)
    .map((cookie) => decodeURIComponent(cookie.substring(nameLenPlus)))[0] || null;
};

export const setCookie = (name: string, val: string, exp: number): void => {
  let expDate = '';
  if (exp) {
    const date = new Date();
    date.setTime(date.getTime() + (exp * 24 * 60 * 60 * 1000));
    expDate = `;expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${val}${expDate};path=/`;
};

export const deleteCookie = (name: string): void => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};
export const normalizeData = (props: GDPRDataProps): CookieSettingsModalData => {
  const cookieConsent = typeof window !== 'undefined' && JSON.parse(getCookie('cookieConsent'));
  const options = cookieConsent || null;

  let userId;
  if (options) {
    userId = options.id;
  }

  return ({
    userId,
    commonLabels: {
      modalTitle: props.labels.commonLabels.cookieSettings,
      active: props.labels.commonLabels.active,
      inActive: props.labels.commonLabels.inActive,
      alwaysActive: props.labels.commonLabels.alwaysActive,
      allowAll: props.labels.commonLabels.allowAll,
      updateSettings: props.labels.commonLabels.updateSettings,
      cookiesListTitle: props.labels.commonLabels.cookiesListTitle,
    },
    logoUrl: props.labels.logoImageUrl,
    cookieSettingsTabs: [
      {
        id: 'GeneralInfo',
        title: props.labels.commonLabels.generalInfo,
        description: `<div>${props.labels.commonLabels.generalInfoDescription}</div>`,
      },
      {
        id: 'StrictCookies',
        title: props.labels.strictCookiesLabels.title,
        description: `<div>${props.labels.strictCookiesLabels.description}</div>`,
        alwaysActive: true,
        cookieList: Object.entries(props.labels.strictCookiesLabels.cookiesList).map((el) => {
          let name = el[0];
          name = name[0].toUpperCase() + name.slice(1);

          return { name, link: el[1] };
        }),
      },
      {
        id: 'PerformAndTargetingCookies',
        title: `${props.labels.performCookiesLabels.title} & ${props.labels.adCookiesLabels.title}`,
        description: `
            <span>${props.labels.performCookiesLabels.title}</span>
            <div>${props.labels.performCookiesLabels.description}</div>
            <span>${props.labels.adCookiesLabels.title}</span>
            <div>${props.labels.adCookiesLabels.description}</div>`,
        active: options ? options.allowPerformanceCookies && options.allowAdvertisingCookies : true,
        cookieList: Object.entries({
          ...props.labels.performCookiesLabels.cookiesList,
          ...props.labels.adCookiesLabels.cookiesList,
        }).map((el) => {
          let name = el[0];
          name = name[0].toUpperCase() + name.slice(1);

          return { name, link: el[1] };
        }),
        types: ['performance', 'advertising'],
        options: ['AllowPerformanceCookies', 'AllowAdvertisingCookies'],
      },
      {
        id: 'FuncCookies',
        title: props.labels.funcCookiesLabels.title,
        description: `<div>${props.labels.funcCookiesLabels.description}</div>`,
        active: options ? options.allowFunctionalCookies : true,
        cookieList: Object.entries(props.labels.funcCookiesLabels.cookiesList).map((el) => (
          { name: el[0], link: el[1] })),
        types: ['functional'],
        options: ['AllowFunctionalCookies'],
      },
      // {
      //   id: 'AbTestCookies',
      //   title: props.labels.abTestCookiesLabels.title,
      //   description: `<div>${props.labels.abTestCookiesLabels.description}</div>`,
      //   active: options ? options.AllowABTestingCookies : true,
      //   cookieList: Object.entries(props.labels.abTestCookiesLabels.cookiesList).map(el => (
      //     { name: el[0], link: el[1] })),
      //   types: ['abtesting'],
      //   options: ['AllowABTestingCookies'],
      // },
      {
        id: 'MoreInfo',
        title: props.labels.commonLabels.moreInfoLabel,
        onClick: () => {
          window.open(`${props.labels.commonLabels.moreInfoUrl}`, '_blank');
        },
      },
    ],
    activeTabId: 'GeneralInfo',
    gaId: props.gald,
  });
};

export const sendStatisticsToGA = (options: any, gaId?: string) => {
  if (!globalAny.ga) {
    return;
  }

  const statsValues = [
    'Deactivate Performance',
    'Deactivate Advertising',
    'Deactivate Functional',
    'Deactivate ABTesting',
    'Deactivate all',
    'Allowed all',
  ];

  let deactivatedOptions: string[] = [];

  Object.keys(options).forEach((option) => {
    switch (option) {
      case 'allowPerformanceCookies':
        if (!options[option]) {
          deactivatedOptions.push(statsValues[0]);
        }
        break;
      case 'allowAdvertisingCookies':
        if (!options[option]) {
          deactivatedOptions.push(statsValues[1]);
        }
        break;
      case 'allowFunctionalCookies':
        if (!options[option]) {
          deactivatedOptions.push(statsValues[2]);
        }
        break;
      case 'allowABTestingCookies':
        if (!options[option]) {
          deactivatedOptions.push(statsValues[3]);
        }
        break;
      default:
        break;
    }
  });

  if (!deactivatedOptions.length) {
    deactivatedOptions = ['Allowed all'];
  } else if (deactivatedOptions.length === 4) {
    deactivatedOptions = ['Deactivate all'];
  }

  deactivatedOptions.forEach((optionLabel) => {
    globalAny.ga('create', gaId, 'auto');
    globalAny.ga('send', {
      hitType: 'event',
      eventCategory: 'GDPR Settings',
      eventAction: 'Update',
      eventLabel: optionLabel,
    });
  });
};

const propFirstLetterUpperToLowerCase = (obj: any) => {
  const output = {};
  Object.keys(obj).forEach((i) => {
    if (Object.prototype.toString.apply(obj[i]) === '[object Object]' && i !== 'CookiesList') {
      output[i.charAt(0).toLowerCase() + i.slice(1)] = propFirstLetterUpperToLowerCase(obj[i]);
    } else if (Object.prototype.toString.apply(obj[i]) === '[object Array]') {
      output[i.charAt(0).toLowerCase() + i.slice(1)] = [];
      output[i.charAt(0).toLowerCase() + i.slice(1)]
        .push(propFirstLetterUpperToLowerCase(obj[i][0]));
    } else {
      output[i.charAt(0).toLowerCase() + i.slice(1)] = obj[i];
    }
  });

  return output;
};

export const sendSettingsToServer = async (userId: string, body: any, gaId?: string) => {
  const rawResponse = await fetch(
    `/apis/gdpr/settings${userId ? `?id=${userId}` : ''}${userId ? '&lang=en-GB' : '?lang=en-GB'}`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      body: JSON.stringify(body),
    },
  );
  const response: any = await rawResponse.json();
  if (response && response.Data && response.Data.Options) {
    setCookie('cookieConsent', JSON.stringify(propFirstLetterUpperToLowerCase(response.Data.Options)), 365);
    const { Id, ...options } = body;
    sendStatisticsToGA(options, gaId);
  }
};
