interface LoginFormData {
  password: string;
  isRememberMe: boolean;
  gRecaptchaResponse: string;
}

const statusesWithErrorMessages = [400, 401];

export const login = (
  body: LoginFormData,
  successCallback: () => void,
  errorCallback: (text?: string) => void,
): void => {
  const url = '/umbraco/api/request/authorization';

  fetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
  }).then((res: Response): void => {
    if (res.ok) {
      successCallback();

      return;
    }

    if (statusesWithErrorMessages.includes(res.status)) {
      res.text().then((
        (text) => errorCallback(text)
      ));

      return;
    }

    errorCallback();
  }).catch((): void => {
    errorCallback();
  });
};
