import React, {
  FC, memo, ReactNode, useCallback, useMemo,
} from 'react';
import 'lazysizes';
import 'lazysizes/plugins/respimg/ls.respimg';
import classNames from 'classnames';
import keys from 'weak-key';
import { ModalName, useToggleModalsContext } from '@/common/contexts/globalModalContext';
import LangSwitcher, {
  LangSwitcherProps,
} from '@/common/layouts/MasterLayout/components/Footer/LangSwitcher/LangSwitcher';
import AdditionalLinks from '@/common/layouts/MasterLayout/components/Footer/AdditionalLinks/AdditionalLinks';
import NameWithAwards, {
  NameWithAwardsProps,
} from '@/common/layouts/MasterLayout/components/Footer/NameWithAwards/NameWithAwards';
import grid from '@/common/css/grid.module.scss';
import styles from './Footer.module.scss';

interface MenuLink {
  text: string;
  href: string | null;
  modalClass?: string | null;
  isTarget?: boolean;
  isActive?: boolean;
}

interface MenuHeadLink extends Omit<MenuLink, 'text'> {
  text?: string | null;
}

interface MenuColumn {
  title?: string;
  headLink: MenuHeadLink;
  links: MenuLink[];
}
export interface FooterProps extends LangSwitcherProps {
  commonInformationColumn: CommonInfoColumn;
  aboutUsColumn: MenuColumn;
  companyColumn: MenuColumn;
  socialColumn: MenuColumn;
  contactColumn: MenuColumn;
  otherLinksColumn: {
    links: MenuLink[];
  };
}

interface CommonInfoColumn extends NameWithAwardsProps {
  copyright: string;
}

const renderMenuColumnItem = (link: MenuLink): ReactNode => {
  const {
    text, isTarget, href,
  } = link;

  return (
    <li key={keys(link)} className={styles.menuListItem}>
      <a
        className={styles.menuListItemLink}
        {...(isTarget && { target: '_blank' })}
        title={text}
        {...(href && { href })}
      >
        {text}
      </a>
    </li>
  );
};

const renderMenuColumn = (column: MenuColumn): ReactNode => {
  if (!column) {
    return null;
  }

  const { title, links, headLink: { href, isTarget } } = column;

  return (
    <div key={keys(column)} className={classNames(grid.col6, grid.colSm3)}>
      <div className={styles.menuTitle}>
        <a
          {...(href && { href })}
          className={styles.menuTitleLink}
          {...(isTarget && { target: '_blank' })}
        >
          {title}
        </a>
      </div>
      <ul className={styles.menuList}>
        {links.map(renderMenuColumnItem)}
      </ul>
    </div>
  );
};

const Footer: FC<FooterProps> = ({
  aboutUsColumn,
  companyColumn,
  socialColumn,
  contactColumn,
  footerLanguages,
  otherLinksColumn,
  commonInformationColumn,
}) => {
  const toggleModal = useToggleModalsContext();

  const openCommunicationTermsModal = useCallback(() => {
    toggleModal({ [ModalName.communicationTerms]: true });
  }, [toggleModal]);

  const openCookiePreferenceCentre = useCallback(() => {
    toggleModal({ [ModalName.cookies]: true });
  }, [toggleModal]);

  const languageSwitcher = useMemo(() => {
    if (!footerLanguages) {
      return null;
    }

    return (
      <div className={classNames(grid.container, styles.langSwitcherWrapper)}>
        <LangSwitcher footerLanguages={footerLanguages} />
      </div>
    );
  }, [footerLanguages]);

  const menu = useMemo(() => {
    if (!socialColumn && !aboutUsColumn && !companyColumn && !contactColumn) {
      return null;
    }

    const menuColumnsModel: MenuColumn[] = [
      socialColumn,
      aboutUsColumn,
      companyColumn,
      contactColumn,
    ];

    return (
      <div className={classNames(grid.container, styles.sectionContainer, styles.menuWrapper)}>
        <div className={grid.row}>
          {menuColumnsModel.map(renderMenuColumn)}
        </div>
      </div>
    );
  }, [
    aboutUsColumn, companyColumn, socialColumn, contactColumn,
  ]);

  const copyright = useMemo(() => (
    <div className={classNames(grid.container, styles.copyright)}>
      {commonInformationColumn?.copyright}
    </div>
  ), [commonInformationColumn]);

  return (
    <footer className={styles.footer} id="footer">
      {languageSwitcher}
      {menu}

      <NameWithAwards icons={commonInformationColumn.icons} link={commonInformationColumn.link} />

      {otherLinksColumn && (
        <div className={classNames(grid.container, styles.sectionContainer)}>
          <AdditionalLinks
            links={otherLinksColumn.links}
            openCommunicationTermsModal={openCommunicationTermsModal}
            openCookiePreferenceCentre={openCookiePreferenceCentre}
          />
        </div>
      )}
      {copyright}
    </footer>
  );
};

Footer.displayName = 'Footer';

export default memo(Footer);
