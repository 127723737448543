import React, {
  FC, useCallback, useEffect, useRef,
} from 'react';
import { useModalsContext, useToggleModalsContext } from '@/common/contexts/globalModalContext';
import ModalLayout from '@/common/components/Modal/ModalLayout/ModalLayout';
import styles from './ExternalModalForm.module.scss';

export interface ExternalFormProps {
  url?: string | null;
  name: string;
}

interface ExternalModalFormProps extends ExternalFormProps {
  title?: string;
}

const ExternalModalForm: FC<ExternalModalFormProps> = ({
  title,
  name,
  url,
}) => {
  const toggleModal = useToggleModalsContext();
  const { modalsState } = useModalsContext();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const isShown = modalsState[name];

  useEffect(() => {
    const handleMessage = (e: MessageEvent) => {
      if (!Number.isNaN(e.data)) {
        if (iframeRef.current) {
          iframeRef.current.style.height = `${e.data}px`;
        }
      }
    };

    window.addEventListener('message', handleMessage, false);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const closeHandler = useCallback((): void => {
    toggleModal({ [name]: false });
  }, [name, toggleModal]);

  if (!isShown || !url) {
    return null;
  }

  return (
    <ModalLayout closeHandler={closeHandler} modalWindowTitle={title}>
      <iframe
        ref={iframeRef}
        className={styles.formContainer}
        src={url}
        title={title}
      />
    </ModalLayout>
  );
};

export default ExternalModalForm;
