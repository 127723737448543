import React, {
  FC, memo, ReactNode, useCallback,
} from 'react';
import 'lazysizes';
import 'lazysizes/plugins/respimg/ls.respimg';
import classNames from 'classnames';
import { ModalName, useModalsContext, useToggleModalsContext } from '@/common/contexts/globalModalContext';
import { ReactComponent as CloseIcon } from '@/assets/icons/close.svg';
import styles from './ModalFrame.module.scss';

export interface CommunicationTermsProps {
  title: string;
  logoUrl: string;
  modalName: ModalName;
  children: ReactNode;
  customClassName?: string;
}

const ModalFrame: FC<CommunicationTermsProps> = ({
  title,
  logoUrl,
  modalName,
  children,
  customClassName,
}) => {
  const toggleModal = useToggleModalsContext();
  const { modalsState } = useModalsContext();

  const closeHandler = useCallback(() => {
    toggleModal({ [modalName]: false });
  }, [toggleModal, modalName]);

  if (!modalsState[modalName]) {
    return null;
  }

  const logoUrlPart = `${logoUrl}?anchor=center&width=`;
  const srcSet = `${logoUrlPart}105 105w,  ${logoUrlPart}210 210w`;

  return (
    <div className={styles.overlay}>
      <div className={classNames(styles.container, customClassName)}>
        <div className={styles.header}>
          <img
            alt="Instant"
            className={classNames(styles.logo, 'lazyload')}
            data-sizes="auto"
            data-srcset={srcSet}
          />
          <div className={styles.title}>
            {title}
          </div>
          <button
            className={styles.closeButton}
            onClick={closeHandler}
            type="button"
          >
            <CloseIcon className={styles.closeButtonIcon} />
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};

export default memo(ModalFrame);
