import React, {
  FC, KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import classNames from 'classnames';
import keys from 'weak-key';
import {
  autoUpdate, offset, useClick, useDismiss, useFloating, useInteractions,
} from '@floating-ui/react';
import useKeyboardInDropdown from '@/common/hooks/useKeyboardInDropdown';
import Button, {
  ButtonArrowType,
  ButtonSizeType,
  ButtonStyleType,
  ButtonType,
} from '@/common/components/Button/Button';
import DropdownListItem, {
  DropdownItem,
  DropdownListItemProps,
  ListItemType,
} from '@/common/components/Dropdown/DropdownListItem/DropdownListItem';
import styles from './Dropdown.module.scss';

type OffsetValue = number | {
  mainAxis?: number;
  crossAxis?: number;
  alignmentAxis?: number | null;
};

type Alignment = 'start' | 'end';
type Side = 'top' | 'right' | 'bottom' | 'left';
type AlignedPlacement = `${Side}-${Alignment}`;
type Placement = Side | AlignedPlacement;

interface DropdownButtonProps {
  className?: string;
  isActive?: boolean;
  isFocused?: boolean;
  onClick?: () => void;
  onKeyDown?: (event: KeyboardEvent) => void;
  text?: string;
}

interface DropdownProps extends DropdownListItemProps {
  items: DropdownItem[];
  buttonLabel?: string;
  ButtonComponent?: FC<DropdownButtonProps>;
  toggleCallback?: (isDropdownOpen: boolean) => void;
  offsetValue?: OffsetValue;
  placement?: Placement;
  isActive?: boolean;
  shouldCloseOnScroll?: boolean;
  customClassNames?: {
    container?: string;
    button?: string;
    dropdown?: string;
  };
}

const buttonConfig = {
  type: ButtonType.myAccount,
  styleType: ButtonStyleType.filter,
  size: ButtonSizeType.md,
};

let handleScrollTimeout: ReturnType<typeof setTimeout>;
const maxScrollingOffset = 100;

const cyNames = {
  button: 'dropdown-button',
};

const Dropdown: FC<DropdownProps> = ({
  items,
  buttonLabel,
  ButtonComponent,
  toggleCallback,
  offsetValue = 8,
  placement = 'bottom-start',
  isActive,
  shouldCloseOnScroll,
  checkItemActivity,
  selectItemCallback,
  customClassNames,
  listItemType,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const initialScrollPosition = useRef(0);

  const toggleDropdown = useCallback((isOpen: boolean): void => {
    setIsDropdownOpen(isOpen);
    toggleCallback?.(isOpen);
  }, [toggleCallback]);

  const {
    x,
    y,
    reference,
    floating,
    strategy,
    context,
  } = useFloating({
    open: isDropdownOpen,
    onOpenChange: toggleDropdown,
    middleware: [offset(offsetValue)],
    placement,
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);

  const { getFloatingProps } = useInteractions([
    click,
    dismiss,
  ]);

  const selectItem = useCallback((item: DropdownItem): void => {
    if (item.isDisabled) {
      return;
    }
    toggleDropdown(false);
    selectItemCallback(item);
  }, [selectItemCallback, toggleDropdown]);

  const selectItemByKey = useCallback((index: number): void => {
    const selectedByKeyItem = items[index];
    if (!selectedByKeyItem) {
      return;
    }
    selectItem(selectedByKeyItem);
  }, [selectItem, items]);

  const handleButtonClick = useCallback((): void => {
    setIsDropdownOpen((prevIsDropdownOpened) => {
      const newValue = !prevIsDropdownOpened;
      if (newValue) {
        initialScrollPosition.current = window.scrollY;
      }
      toggleCallback?.(newValue);

      return newValue;
    });
  }, [toggleCallback]);

  const checkScrollPosition = useCallback((): void => {
    if (Math.abs(initialScrollPosition.current - window.scrollY) > maxScrollingOffset) {
      toggleDropdown(false);
    }
  }, [toggleDropdown]);

  const handleScroll = useCallback((): void => {
    clearTimeout(handleScrollTimeout);
    handleScrollTimeout = setTimeout(checkScrollPosition, 100);
  }, [checkScrollPosition]);

  useEffect(() => {
    if (!shouldCloseOnScroll || !isDropdownOpen) {
      return () => {};
    }

    window.addEventListener('scroll', handleScroll);

    return (): void => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [
    shouldCloseOnScroll, isDropdownOpen, handleScroll,
  ]);

  const { focusedItemIndex, handleKeyDown } = useKeyboardInDropdown({
    isDropdownOpen, setIsDropdownOpen: toggleDropdown, selectItemByKey, itemsLength: items.length,
  });

  const renderItem = useCallback((item: DropdownItem, index) => (
    <DropdownListItem
      key={keys(item)}
      checkItemActivity={checkItemActivity}
      isFocused={(focusedItemIndex === index)}
      item={item}
      listItemType={listItemType}
      selectItemCallback={selectItem}
    />
  ), [
    focusedItemIndex, selectItem, listItemType, checkItemActivity,
  ]);

  const button = useMemo(() => {
    if (ButtonComponent) {
      return (
        <ButtonComponent
          className={classNames(customClassNames?.button)}
          isActive={isActive}
          isFocused={isDropdownOpen}
          onClick={handleButtonClick}
          onKeyDown={handleKeyDown}
          text={buttonLabel}
        />
      );
    }

    return (
      <Button
        additionalAttributes={{ 'data-cy': cyNames.button }}
        arrowType={isDropdownOpen ? ButtonArrowType.top : ButtonArrowType.bottom}
        buttonStylesConfig={buttonConfig}
        customClassNames={{
          button: classNames(styles.button, isActive && styles.isActive, customClassNames?.button),
          arrow: styles.arrowIcon,
        }}
        onClick={handleButtonClick}
        onKeyDown={handleKeyDown}
        text={buttonLabel}
      />
    );
  }, [
    ButtonComponent,
    buttonLabel,
    isActive,
    isDropdownOpen,
    handleButtonClick,
    handleKeyDown,
    customClassNames?.button,
  ]);

  if (!items?.length) {
    return null;
  }

  return (
    <div ref={reference} className={classNames(styles.container, customClassNames?.container)}>
      {button}
      {isDropdownOpen && (
        <ul
          ref={floating}
          className={classNames(
            styles.dropdown,
            customClassNames?.dropdown,
          )}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
          }}
          {...getFloatingProps()}
        >
          {items.map(renderItem)}
        </ul>
      )}
    </div>
  );
};
Dropdown.displayName = 'Dropdown';

export { ListItemType };
export default Dropdown;
