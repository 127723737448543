import React, {
  FC, useCallback, useEffect, useRef, useState, ReactNode, memo,
} from 'react';
import classNames from 'classnames';
import keys from 'weak-key';
import ErrorMessage from '@/common/components/Form/ErrorMessage/ErrorMessage';
import { ReactComponent as DropdownTick } from '@/assets/icons/dropdown-tick.svg';
import { ReactComponent as Tick } from '@/assets/icons/tick.svg';
import FormInputWrapper from '@/common/components/Form/FormInputWrapper/FormInputWrapper';
import styles from './Select.module.scss';

interface SelectOption {
  value: string;
  label: string;
}

export interface SelectProps {
  options: SelectOption[];
  label?: string;
  className?: string;
  onChange?: (option: string) => void;
  isValid?: boolean;
  errorMessage?: string;
  defaultValue?: string;
  value?: string;
  inputAttributes?: {
    [key: string]: string;
  };
}

const Select: FC<SelectProps> = ({
  label,
  className,
  options,
  onChange,
  errorMessage,
  isValid = true,
  defaultValue,
  value,
  inputAttributes,
}) => {
  let initialSelectedOption: SelectOption | null = null;
  let initialFocusedItem = -1;

  for (let i = 0; i < options.length; i++) {
    if (options[i].value === defaultValue) {
      initialSelectedOption = options[i];
      initialFocusedItem = i;
      break;
    }
  }

  const [selectedOption, setSelectedOption] = useState<SelectOption | null>(initialSelectedOption);
  const [isDropdownOpen, setDropdownOpenState] = useState<boolean>(false);
  const [focusedItemIndex, setFocusedItemIndex] = useState<number>(initialFocusedItem);
  const [isActive, setIsActive] = useState<boolean>(!!initialSelectedOption);

  const listRef = useRef<HTMLUListElement>(null);

  const openDropdown = (): void => {
    setIsActive(true);
    setDropdownOpenState(true);
  };

  const closeDropdown = useCallback((): void => {
    setDropdownOpenState(false);
    const focusedItem = selectedOption ? options.indexOf(selectedOption) : -1;
    setFocusedItemIndex(focusedItem);
    setIsActive(focusedItem >= 0);
  }, [options, selectedOption]);

  const selectItem = useCallback((option: SelectOption): void => {
    setSelectedOption(option);
    setFocusedItemIndex(options.indexOf(option));
    setDropdownOpenState(false);
    setIsActive(true);
    if (!onChange) {
      return;
    }
    onChange(option.value);
  }, [onChange, options]);

  const resetSelectedOption = useCallback((): void => {
    setSelectedOption(initialSelectedOption);
    setFocusedItemIndex(initialFocusedItem);
  }, [initialFocusedItem, initialSelectedOption]);

  const toggleDropdown = (): void => {
    if (isDropdownOpen) {
      closeDropdown();

      return;
    }

    openDropdown();
  };

  useEffect(() => {
    if (!isDropdownOpen) {
      return (): void => {
      };
    }

    if (focusedItemIndex === -1) {
      setFocusedItemIndex(0);
    }

    const list = listRef.current;
    if (list) {
      const focusItemElement: HTMLElement | null = list.querySelector(`li:nth-child(${focusedItemIndex + 1})`);
      list.scrollTop = focusItemElement?.offsetTop || 0;
    }

    const clickOutsideHandler = (e: MouseEvent): void => {
      const target = e.target as HTMLElement;
      if (isDropdownOpen && target && !target.closest(`.${styles.list}`)) {
        closeDropdown();
      }
    };

    const keyboardHandler = (e: KeyboardEvent): void => {
      const { key } = e;

      switch (key) {
        case 'ArrowUp': {
          if (focusedItemIndex > 0 && list) {
            e.preventDefault();
            setFocusedItemIndex(focusedItemIndex - 1);
            list.scrollTop = list.querySelectorAll('li')[focusedItemIndex - 1].offsetTop;
          }
          break;
        }

        case 'ArrowDown': {
          if (focusedItemIndex < options.length - 1 && list) {
            e.preventDefault();
            setFocusedItemIndex(focusedItemIndex + 1);
            list.scrollTop = list.querySelectorAll('li')[focusedItemIndex + 1].offsetTop;
          }
          break;
        }

        case 'Escape': {
          e.preventDefault();
          setDropdownOpenState(false);
          break;
        }

        case 'Enter': {
          selectItem(options[focusedItemIndex]);
          e.preventDefault();
          break;
        }

        default: {
          if (!/^[a-zA-Z]$/.test(key)) {
            return;
          }

          const newIndex = options.findIndex(
            (option) => (option.label?.charAt(0).toLowerCase() === key.toLowerCase()),
          );

          setFocusedItemIndex(newIndex);
        }
      }
    };

    window.document.addEventListener('click', clickOutsideHandler);
    window.document.addEventListener('keydown', keyboardHandler);

    return (): void => {
      window.document.removeEventListener('click', clickOutsideHandler);
      window.document.removeEventListener('keydown', keyboardHandler);
    };
  }, [
    closeDropdown, focusedItemIndex, isDropdownOpen, options, selectItem,
  ]);

  useEffect(() => {
    if (value) {
      return;
    }

    resetSelectedOption();
  }, [resetSelectedOption, value]);

  const inputKeyPressHandler = (e: React.KeyboardEvent): void => {
    const { key } = e;

    if (key === 'Enter' || key === 'ArrowDown') {
      setDropdownOpenState(true);
    }
  };

  const renderListItem = (option: SelectOption, index: number): ReactNode => {
    const optionLabel = option.label;

    const isFocused = index === focusedItemIndex;
    const isSelected = option.value === selectedOption?.value;

    const itemClickHandler = (): void => {
      selectItem(option);
    };

    return (
      <li
        key={keys(option)}
        className={classNames(styles.listItem, isFocused && styles.listItem_focused)}
        onClick={itemClickHandler}
      >
        {optionLabel}
        {isSelected && <Tick className={styles.tick} />}
      </li>
    );
  };

  return (
    <div className={classNames(styles.container, className)}>
      <FormInputWrapper className={styles.wrapper} isActive={isActive} isValid={isValid} label={label}>
        <div
          className={classNames(
            styles.input,
            !isValid && styles.isInvalid,
            !selectedOption?.value && styles.inputNoValue,
          )}
          onClick={toggleDropdown}
          onKeyDown={inputKeyPressHandler}
          tabIndex={0}
          {...inputAttributes}
        >
          {selectedOption?.label}
          <DropdownTick className={classNames(
            styles.inputTick,
            isDropdownOpen && styles.inputTickTurned,
            !isValid && styles.inputTickRed,
          )}
          />
        </div>
      </FormInputWrapper>

      {isDropdownOpen && (
        <ul
          ref={listRef}
          className={styles.list}
        >
          {options.map(renderListItem)}
        </ul>
      )}

      {!isValid && (
        <ErrorMessage title={(!selectedOption && errorMessage) || ''} />
      )}
    </div>
  );
};
Select.displayName = 'Select';

export default memo(Select);
