import { RefObject, useEffect, useState } from 'react';
import Breakpoints from '@/common/constants/breakpoints';
import { getHeaderHeight } from '@/helpers/domElements';

type TopPositionType = number | null;

const useStickyPosition = (
  itemRef: RefObject<HTMLDivElement> | null,
  containerRef: RefObject<HTMLDivElement> | null,
): TopPositionType => {
  const [topPosition, setTopPosition] = useState<TopPositionType>(null);

  const computeCardPosition = (): void => {
    const viewportWidth = window.innerWidth;

    if (viewportWidth < Breakpoints.md) {
      setTopPosition(null);

      return;
    }

    if (!itemRef || !itemRef.current || !containerRef || !containerRef.current) {
      return;
    }

    const itemRefBoundaries = itemRef.current.getBoundingClientRect();
    const parentElementBoundaries = containerRef.current.getBoundingClientRect();

    if (!itemRefBoundaries || !parentElementBoundaries) {
      return;
    }

    const initialStickyWrapperTop = getHeaderHeight();
    const parentElementTop = parentElementBoundaries.top;

    if (parentElementTop > initialStickyWrapperTop) {
      setTopPosition(null);

      return;
    }

    const parentElementBottom = parentElementBoundaries.bottom || 0;
    const wrapperHeight = itemRefBoundaries.height;
    const wrapperOffset = wrapperHeight + initialStickyWrapperTop;
    const position = parentElementBottom < wrapperOffset ? (wrapperOffset - parentElementBottom) : 0;

    setTopPosition(initialStickyWrapperTop - position);
  };

  useEffect((): void => {
    setTimeout(computeCardPosition, 0);
  }, []);

  useEffect((): () => void => {
    window.addEventListener('scroll', computeCardPosition);
    window.addEventListener('resize', computeCardPosition);

    return (): void => {
      window.removeEventListener('scroll', computeCardPosition);
      window.removeEventListener('resize', computeCardPosition);
    };
  }, []);

  return topPosition;
};

export default useStickyPosition;
