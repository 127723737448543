import React, { FC } from 'react';
import keys from 'weak-key';
import classNames from 'classnames';
import { ResponsiveImageUrls } from '@/types';
import ResponsiveImage from '@/common/components/ResponsiveImage/ResponsiveImage';
import grid from '@/common/css/grid.module.scss';
import styles from './NameWithAwards.module.scss';

export interface NameWithAwardsProps {
  link: {
    textPart: string;
    text: string;
    href: string;
    isTarget?: boolean;
  };
  icons: AwardIcon[];
}

interface AwardIcon {
  url: string;
}

const imagesWidths = [
  99, 129, 50, 44,
];

const imagesWidthsCount = imagesWidths.length - 1;

const getResponsiveImageUrls = (url: string, width: number): ResponsiveImageUrls => {
  const imagePartUrl = `${url}?rmode=pad&anchor=center&width=`;
  const doubleWidth = width * 2;

  return {
    [width]: `${imagePartUrl}${width}`,
    [doubleWidth]: `${imagePartUrl}${doubleWidth}`,
  };
};

const NameWithAwards: FC<NameWithAwardsProps> = ({
  link,
  icons,
}: NameWithAwardsProps) => (
  <div className={classNames(grid.container, styles.container)}>
    <div>
      <div className={styles.partText}>{link?.text}</div>
      <div className={styles.logoText}>Instant Group</div>
    </div>
    <div className={styles.awardsContainer}>
      {icons?.map((icon, index) => {
        const currentImageWidthsIndex = (imagesWidthsCount < index) ? imagesWidthsCount : index;
        const currentImageWidth = imagesWidths[currentImageWidthsIndex];
        const images = getResponsiveImageUrls(icon.url, currentImageWidth);

        return (
          <ResponsiveImage
            key={keys(icon)}
            alt="Award"
            className={styles.awardImage}
            shouldRegardDPR
            urls={images}
          />
        );
      })}
    </div>
  </div>
);

export default NameWithAwards;
