import React, { FC } from 'react';
import keys from 'weak-key';
import PageButton, { PageButtonProps } from '@/common/components/Pagination/PageButton';
import styles from './Pagination.module.scss';

export interface PagesProps {
  buttons: PageButtonProps[];
}

const maxShownButtonsCount = 3;
const shownAroundButtonsCount = (maxShownButtonsCount - 1) / 2;

const Pages: FC<PagesProps> = ({
  buttons,
}: PagesProps) => {
  const pages = [];
  const totalPages = buttons.length;
  const shownButtonsCount = Math.min(totalPages, maxShownButtonsCount);
  const lastRelativeButtonIndex = (shownButtonsCount - 1);
  const currentPageIndex = buttons.findIndex((button): boolean => button.isActive);

  let lastShownButtonIndex = (currentPageIndex + shownAroundButtonsCount);

  if (lastShownButtonIndex >= totalPages) {
    lastShownButtonIndex = (totalPages - 1);
  }

  let firstShownButtonIndex = (currentPageIndex - shownAroundButtonsCount);

  if (firstShownButtonIndex <= 0) {
    firstShownButtonIndex = 0;
    lastShownButtonIndex = lastRelativeButtonIndex;
  }

  if ((lastShownButtonIndex - firstShownButtonIndex) < lastRelativeButtonIndex) {
    firstShownButtonIndex = (lastShownButtonIndex - lastRelativeButtonIndex);
  }

  for (let i = firstShownButtonIndex; i <= lastShownButtonIndex; i += 1) {
    const button = buttons[i];
    pages.push(<PageButton key={keys(button)} {...button} />);
  }

  if (lastShownButtonIndex !== totalPages - 1) {
    if (lastShownButtonIndex !== (totalPages - 2)) {
      pages.push(<li key="dots" className={styles.paginationDotsItem}>...</li>);
    }

    const lastButton = buttons[totalPages - 1];
    pages.push(<PageButton key={keys(lastButton)} {...lastButton} />);
  }

  return (
    <>
      {pages}
    </>
  );
};

export default Pages;
