import React, { FC } from 'react';
import classNames from 'classnames';
import { ResponsiveImageUrls } from '@/types';
import { AdditionalContent } from '@/common/components/Products/types';
import ResponsiveImage from '@/common/components/ResponsiveImage/ResponsiveImage';
import IndustryPoster from '@/common/components/IndustryPoster/IndustryPoster';
import styles from './LargeImageBlock.module.scss';

interface LargeImageBlockProps {
  additionalContent?: AdditionalContent | null;
  images?: ResponsiveImageUrls;
  className?: string;
}

const LargeImageBlock: FC<LargeImageBlockProps> = ({
  additionalContent,
  images,
  className,
}: LargeImageBlockProps) => {
  if (additionalContent?.bigImageCrops) {
    const { bigImageCrops, bigImageTopText } = additionalContent;

    return (
      <IndustryPoster
        customClassNames={{
          container: classNames(className, styles.industry),
          title: styles.industryTitle,
        }}
        imageUrls={bigImageCrops}
        title={bigImageTopText}
      />
    );
  }

  if (images) {
    return (
      <ResponsiveImage urls={images} className={classNames(className, styles.image)} />
    );
  }

  return null;
};

export default LargeImageBlock;
