import { ModalLayoutProps } from '@/common/components/Modal/ModalLayout/ModalLayout';
import { Validator } from '@/helpers/validation';
import { ReactNode } from 'react';

export interface FormProps extends FormBaseProps {
  columns: FormColumn[];
  mainFieldsetHeadline?: string;
  endpoint: string;
  subtitleSection?: ReactNode;
  onSubmitSuccess?: () => void;
}

export interface FormBaseProps {
  privacyAndPolicy: string;
  submitRequestButtonText?: string;
  serverErrorMessage: string;
  captchaSiteKey: string;
  captchaErrorMessage: string;
  isoCode?: string;
  getAdditionalParams?: () => any;
}

export interface ModalFormBaseProps extends ModalLayoutProps, FormBaseProps {
}

export interface FormColumn {
  fields: FormField[];
}

export interface FormField {
  fieldType: FieldType;
  name: string;
  validationSchema: Validator[];
  fieldData: any;
}

export interface FieldState {
  value: string;
  isValid?: boolean;
  errorMessage?: string;
}

export interface FormState {
  [field: string]: FieldState;
}

export enum FieldType {
  INPUT,
  SELECT,
  TEXT_AREA,
  PASSWORD,
  CAPTCHA,
}
