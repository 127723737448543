import { AccountMenuLink, AccountMenuLinkKey } from '@/common/layouts/MasterLayout/components/Header/AccountMenu/types';

interface SelectItemAdditionalProps {
  loginUrl: string;
}

export const selectItem = async (item: AccountMenuLink, props: SelectItemAdditionalProps): Promise<void> => {
  switch (item.key) {
    case AccountMenuLinkKey.login: {
      window.location.assign(props.loginUrl);
      break;
    }
    default: {
      if (!item.href) {
        return;
      }
      if (item.isTarget) {
        window.open(item.href, '_blank');

        return;
      }

      window.location.assign(item.href);
    }
  }
};

export const checkItemActivity = (item: AccountMenuLink): boolean => {
  if (!item.href) {
    return false;
  }

  const currentPathname = window.location.pathname;

  return (currentPathname === item.href);
};
