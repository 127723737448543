import React, {
  FC, memo, useCallback, useEffect, useState,
} from 'react';
import classNames from 'classnames';
import useScroll from '@/common/hooks/useScroll';
import NavBar, { NavBarProps } from '@/common/layouts/MasterLayout/components/Header/NavBar/NavBar';
import HeaderLogo, { LogoProps } from '@/common/layouts/MasterLayout/components/Header/HeaderLogo/HeaderLogo';
import AccountMenu, { AccountMenuProps } from '@/common/layouts/MasterLayout/components/Header/AccountMenu/AccountMenu';
import grid from '@/common/css/grid.module.scss';
import WarningBar, { WarningBarProps } from '@/common/layouts/MasterLayout/components/WarningBar/WarningBar';
import styles from './Header.module.scss';

export interface HeaderProps {
  headerLogo: LogoProps;
  headerLinks: NavBarProps;
  accountMenu: AccountMenuProps;
}

export interface CustomHeaderProps {
  isInvertedByDefault?: boolean;
  isAccountActive?: boolean;
  customClassNames?: {
    container?: string;
    logo?: string;
    navBarBurgerIcon?: string;
    accountMenuContainer?: string;
  };
  warningBar?: WarningBarProps;
}

interface HeaderComponentProps extends HeaderProps, CustomHeaderProps {
}

const minScrollOffset = 60;

const Header: FC<HeaderComponentProps> = memo(({
  headerLogo,
  headerLinks,
  accountMenu,
  isInvertedByDefault,
  isAccountActive,
  customClassNames,
  warningBar,
}: HeaderComponentProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
  const isScrolled = useScroll(minScrollOffset);
  const isInverted = isInvertedByDefault || isScrolled;
  const isActive = isHovered || isMenuOpen || isAccountMenuOpen;
  const areChildrenInverted = isInverted || isActive;

  const addHoverEffect = useCallback(() => setIsHovered(true), []);
  const removeHoverEffect = useCallback(() => setIsHovered(false), []);

  const handleTouch = useCallback(() => {
    if (isHovered) {
      removeHoverEffect();
    }
  }, [removeHoverEffect, isHovered]);

  useEffect(() => {
    document.addEventListener('touchmove', handleTouch);

    return (): void => {
      document.removeEventListener('touchmove', handleTouch);
    };
  }, [handleTouch]);

  return (
    <header className={classNames(styles.container)}>
      {warningBar && <WarningBar {...warningBar} />}

      <div
        className={classNames(
          grid.container,
          styles.header,
          isActive && styles.isActive,
          isInverted && styles.isInverted,
          !!warningBar && styles.withWarningBar,
          customClassNames?.container,
        )}
        id="header"
        onClick={addHoverEffect}
        onMouseEnter={addHoverEffect}
        onMouseLeave={removeHoverEffect}
      >
        <HeaderLogo
          {...headerLogo}
          className={customClassNames?.logo}
          isInverted={areChildrenInverted}
        />
        {!!headerLinks && (
          <NavBar
            {...headerLinks}
            burgerClassName={customClassNames?.navBarBurgerIcon}
            isInverted={areChildrenInverted}
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
          />
        )}
        {!!accountMenu && (
          <AccountMenu
            className={customClassNames?.accountMenuContainer}
            isActive={isAccountActive}
            isInverted={areChildrenInverted}
            setIsOpen={setIsAccountMenuOpen}
            {...accountMenu}
          />
        )}
      </div>
    </header>
  );
});

export default Header;
