import React, { FC, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { ReactComponent as TickIcon } from '@/assets/icons/tick.svg';
import styles from './DropdownListItem.module.scss';

export interface DropdownItem {
  text?: string;
  isActive?: boolean;
  isDisabled?: boolean;
  href?: string | null;
  isTarget?: boolean | null;
  value?: string;
}

export interface DropdownListItemProps {
  checkItemActivity?: (item: DropdownItem) => boolean;
  selectItemCallback: (item: DropdownItem) => void;
  listItemType?: ListItemType;
}

interface DropdownListItemComponent extends DropdownListItemProps {
  item: DropdownItem;
  isFocused: boolean;
}

export enum ListItemType {
  text,
  link,
}

const DropdownListItem: FC<DropdownListItemComponent> = ({
  item,
  isFocused,
  listItemType,
  checkItemActivity,
  selectItemCallback,
}) => {
  const isSelected = useMemo(() => (
    item.isActive || checkItemActivity?.(item)
  ), [item, checkItemActivity]);

  const selectItem = useCallback(() => {
    selectItemCallback(item);
  }, [selectItemCallback, item]);

  const itemClassNames = classNames(
    styles.dropdownItem,
    isFocused && styles.isFocused,
    isSelected && styles.isSelected,
    item.isDisabled && styles.isDisabled,
  );

  switch (listItemType) {
    case ListItemType.link: {
      return (
        <li>
          <a
            className={itemClassNames}
            href={item.href || ''}
            {...(item.isTarget && { target: '_blank' })}
          >
            {item.text}
            {isSelected && (
              <TickIcon className={styles.tickIcon} />
            )}
          </a>
        </li>
      );
    }
    case ListItemType.text:
    default: {
      return (
        <li
          className={classNames(itemClassNames)}
          onClick={selectItem}
        >
          {item.text}
          {isSelected && (
            <TickIcon className={styles.tickIcon} />
          )}
        </li>
      );
    }
  }
};

export default DropdownListItem;
