import React, { FC } from 'react';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import classNames from 'classnames';
import { ResponsiveImageUrls } from '@/types';
import ResponsiveImage from '@/common/components/ResponsiveImage/ResponsiveImage';
import styles from './MarketReportPoster.module.scss';

export interface MarketReportPosterProps {
  title: string;
  quarter: string;
  marketReportLabel: string;
}

export interface MarketReportPosterComponentProps extends MarketReportPosterProps {
  imageUrls?: ResponsiveImageUrls;
  imageUrl?: string;
  customClassNames?: {
    container?: string;
    title?: string;
    quarter?: string;
    label?: string;
  };
}

const MarketReportPoster: FC<MarketReportPosterComponentProps> = ({
  imageUrls, imageUrl, quarter, title, marketReportLabel, customClassNames = {},
}: MarketReportPosterComponentProps) => (
  <div className={classNames(styles.container, customClassNames.container)}>
    {imageUrls && <ResponsiveImage urls={imageUrls} alt={title} className={styles.image} />}
    {imageUrl && <img alt={title} src={imageUrl} className={styles.image} />}
    <div className={classNames(styles.title, customClassNames.title)}>
      {title}
    </div>
    <div className={classNames(styles.quarter, customClassNames.quarter)}>
      {quarter}
    </div>
    <div className={classNames(styles.label, customClassNames.label)}>
      {marketReportLabel}
    </div>
  </div>
);

export default MarketReportPoster;
