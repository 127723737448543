import {
  KeyboardEvent, useCallback, useEffect, useState,
} from 'react';

interface KeyboardInDropdownProps {
  isDropdownOpen: boolean;
  setIsDropdownOpen: (value: boolean) => void;
  selectItemByKey: (itemIndex: number) => void;
  getItemIndexByChar?: (letter: string) => number;
  itemsLength: number;
  initialFocusedItemIndex?: number;
}

interface KeyboardInDropdownResponse {
  focusedItemIndex: number;
  handleKeyDown: (event: KeyboardEvent) => void;
}

const useKeyboardInDropdown = ({
  isDropdownOpen,
  setIsDropdownOpen,
  selectItemByKey,
  getItemIndexByChar,
  itemsLength,
  initialFocusedItemIndex = -1,
}: KeyboardInDropdownProps): KeyboardInDropdownResponse => {
  const [focusedItemIndex, setFocusedItemIndex] = useState(initialFocusedItemIndex);

  const handleKeyDown = useCallback((event: KeyboardEvent): void => {
    const { key } = event;

    switch (key) {
      case 'ArrowUp': {
        if (!isDropdownOpen || (focusedItemIndex <= 0)) {
          return;
        }

        setFocusedItemIndex(focusedItemIndex - 1);
        event.preventDefault();

        break;
      }
      case 'ArrowDown': {
        if (!isDropdownOpen) {
          setIsDropdownOpen(true);

          return;
        }

        if (focusedItemIndex >= itemsLength - 1) {
          return;
        }

        setFocusedItemIndex(focusedItemIndex + 1);
        event.preventDefault();

        break;
      }
      case 'Enter': {
        event.preventDefault();

        if (isDropdownOpen) {
          selectItemByKey(focusedItemIndex);

          return;
        }

        setIsDropdownOpen(true);

        break;
      }
      default: {
        if (!getItemIndexByChar || !isDropdownOpen || !/^[a-zA-Z0-9]$/.test(key)) {
          return;
        }

        const newIndex = getItemIndexByChar(key.toLowerCase());

        setFocusedItemIndex(newIndex);
      }
    }
  }, [
    focusedItemIndex, isDropdownOpen, setIsDropdownOpen, selectItemByKey, getItemIndexByChar, itemsLength,
  ]);

  useEffect(() => {
    if (isDropdownOpen && (focusedItemIndex !== initialFocusedItemIndex)) {
      setFocusedItemIndex(initialFocusedItemIndex);
    }
  }, [isDropdownOpen, initialFocusedItemIndex]);

  return {
    focusedItemIndex,
    handleKeyDown,
  };
};

export default useKeyboardInDropdown;
