import React, { FC, useRef } from 'react';
import { hasExternalForm } from '@/helpers/externalForm';
import { ModalName } from '@/common/contexts/globalModalContext';
import ListingLayout, { ListingLayoutProps } from '@/common/layouts/ListingLayout/ListingLayout';
import { LinkProps, ResponsiveImageUrls } from '@/types';
import { BannerProps } from '@/common/components/Banner/Banner';
import ListingGallery from '@/common/components/ListingGallery/ListingGallery';
import { ListCardProps } from '@/common/components/ListCard/ListCard';
import CustomReportModal, { CustomReportModalProps } from '@/common/components/CustomReportModal/CustomReportModal';
import ExternalModalForms from '@/common/components/Modal/ExternalModalForms/ExternalModalForms';
import styles from './IndustryInsightsListingPage.module.scss';

interface ArticleCardOld {
  mainText: string;
  textPart?: string;
  overlayText?: string;
  button: LinkProps;
  imageUrls: ResponsiveImageUrls;
}

export interface IndustryInsightsListingPageProps extends ListingLayoutProps {
  articles?: ArticleCardOld[];
  banner?: BannerProps;
  customInsightPopup?: CustomReportModalProps;
}

const IndustryInsightsListingPage: FC<IndustryInsightsListingPageProps> = ({
  articles,
  banner,
  customInsightPopup,
  pardot,
  signUpSection,
  ...listingLayoutProps
}) => {
  const itemRefToScroll = useRef<HTMLDivElement | null>(null);

  const shouldRenderNewsletterModal = !hasExternalForm({ pardot, modalName: ModalName.newsletter });

  const articleCards: ListCardProps[] | undefined = articles?.map(({
    button,
    overlayText,
    mainText,
    imageUrls,
  }) => ({
    title: mainText,
    description: overlayText || '',
    link: button?.href || '',
    imageUrls,
  }));

  const renderModal = () => {
    if (!customInsightPopup || hasExternalForm({ pardot, modalName: ModalName.customReport })) {
      return null;
    }

    return (
      <CustomReportModal {...customInsightPopup} />
    );
  };

  return (
    <ListingLayout
      itemRefToScroll={itemRefToScroll}
      shouldRenderNewsletterModal={shouldRenderNewsletterModal}
      signUpSection={signUpSection}
      {...listingLayoutProps}
    >
      <div ref={itemRefToScroll}>
        <ListingGallery banner={banner} cards={articleCards} className={styles.gallery} />
      </div>
      {!!pardot && (
        <ExternalModalForms
          pardot={pardot}
          titles={{
            [ModalName.customReport]: customInsightPopup?.modalWindowTitle,
            [ModalName.newsletter]: signUpSection?.modalWindowTitle,
          }}
        />
      )}
      {renderModal()}
    </ListingLayout>
  );
};

export default IndustryInsightsListingPage;
