import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './Pagination.module.scss';

export interface PageButtonProps {
  text: string;
  url: string;
  isActive: boolean;
}

const PageButton: FC<PageButtonProps> = ({
  url, text, isActive,
}: PageButtonProps) => (
  <li className={styles.paginationItem}>
    <a
      href={url}
      className={classNames(styles.paginationLink, isActive && styles.paginationLinkActive)}
    >
      {text}
    </a>
  </li>
);

export default PageButton;
