import React, { FC } from 'react';
import classNames from 'classnames';
import { LinkProps } from '@/types';
import Button from '@/common/components/Button/Button';
import styles from './Banner.module.scss';

interface ButtonProps extends LinkProps {
  onClick?: () => void;
}

export interface BannerProps {
  imageUrl?: string;
  title: string;
  description: string;
  button?: ButtonProps;
}

export interface BannerComponentProps extends BannerProps {
  customClassNames?: {
    container?: string;
    image?: string;
    title?: string;
    description?: string;
    button?: string;
  };
}

const Banner: FC<BannerComponentProps> = ({
  button, description, imageUrl, title, customClassNames = {},
}: BannerComponentProps) => {
  const { text, href, onClick } = button || {};

  return (
    <div className={classNames(customClassNames.container, styles.container)}>
      {!!imageUrl && (
        <img alt={title} className={classNames(customClassNames.image, styles.image)} src={imageUrl} />
      )}
      <div className={classNames(customClassNames.title, styles.title)}>{title}</div>
      <div className={classNames(customClassNames.description, styles.description)}>{description}</div>
      {text && (
        <Button
          customClassNames={{ button: classNames(customClassNames.button, styles.button) }}
          href={href}
          onClick={onClick}
          text={text}
        />
      )}
    </div>
  );
};
export default Banner;
