import React, { FC, memo } from 'react';
import 'lazysizes';
import 'lazysizes/plugins/respimg/ls.respimg';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import classNames from 'classnames';
import styles from './HeaderLogo.module.scss';

export interface LogoProps {
  mainLogo: string;
  scrollLogo: string;
  imageAltText: string;
  logoUrl: string;
  linkTitle?: string;
}

interface LogoComponentProps extends LogoProps {
  className?: string;
  isInverted: boolean;
}

const HeaderLogo: FC<LogoComponentProps> = memo(({
  mainLogo,
  imageAltText,
  logoUrl,
  linkTitle,
  scrollLogo,
  className,
  isInverted,
}: LogoComponentProps) => {
  const getImageWidthPart = (width: number) => (`${width} ${width}w, `);

  const imageUrlPart = `${isInverted ? scrollLogo : mainLogo}?anchor=center&width=`;
  const imageWidths = [
    79, 103, 130,
  ];
  let srcSet1x = '';
  let srcSet2x = '';

  imageWidths.forEach((imageWidth) => {
    srcSet1x += imageUrlPart + getImageWidthPart(imageWidth);
    srcSet2x += imageUrlPart + getImageWidthPart(imageWidth * 2);
  });

  const srcSet = srcSet1x + srcSet2x.slice(0, -2);

  return (
    <a
      className={styles.link}
      href={logoUrl}
      title={linkTitle}
    >
      <img
        alt={imageAltText}
        className={classNames(styles.image, className, 'lazyload')}
        data-sizes="auto"
        data-srcset={srcSet}
      />
    </a>
  );
});

export default HeaderLogo;
