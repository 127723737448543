import React, { FC, memo, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './FormInputWrapper.module.scss';

interface FormInputWrapperProps {
  label?: string;
  children: ReactNode;
  isActive?: boolean;
  isValid?: boolean;
  className?: string;
}

const FormInputWrapper: FC<FormInputWrapperProps> = ({
  label = '', isActive, isValid, children, className,
}: FormInputWrapperProps) => (
  <div className={classNames(styles.border, className, !isValid && styles.borderInvalid)}>
    {children}
    <label className={classNames(styles.label, isActive && styles.labelFocused)}>{label}</label>
  </div>
);
FormInputWrapper.displayName = 'FormInputWrapper';

export default memo(FormInputWrapper);
