import React, { FC, ReactNode, useMemo } from 'react';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import classNames from 'classnames';
import { ResponsiveImageUrls } from '@/types';
import ResponsiveImage from '@/common/components/ResponsiveImage/ResponsiveImage';
import styles from './IndustryPoster.module.scss';

export interface IndustryPosterProps {
  title?: string;
  imageUrl?: string;
  imageUrls?: ResponsiveImageUrls;
  label?: string;
}

export interface IndustryPosterComponentProps extends IndustryPosterProps {
  customClassNames?: {
    container?: string;
    title?: string;
    label?: string;
  };
}

const IndustryPoster: FC<IndustryPosterComponentProps> = ({
  title, imageUrls, imageUrl, label, customClassNames = {},
}: IndustryPosterComponentProps) => {
  const labelBlock = useMemo((): ReactNode => {
    if (!label) {
      return null;
    }

    const labelPartLength = Math.ceil(label.length / 2);
    const firstLabelPart = label.slice(0, labelPartLength);
    const secondLabelPart = label.slice(labelPartLength);

    return (
      <div className={classNames(styles.label, customClassNames.label)}>
        <div>
          {firstLabelPart}
        </div>
        <div>
          {secondLabelPart}
        </div>
      </div>
    );
  }, [label, customClassNames.label]);

  return (
    <div className={classNames(styles.container, customClassNames.container)}>
      {imageUrls && <ResponsiveImage urls={imageUrls} alt={title} className={styles.image} />}
      {imageUrl && <img src={imageUrl} alt={title} className={styles.image} />}
      <div className={classNames(styles.title, customClassNames.title)}>
        {title}
      </div>
      {labelBlock}
    </div>
  );
};

export default IndustryPoster;
