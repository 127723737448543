import React, { FC } from 'react';
import classNames from 'classnames';
import 'lazysizes';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import { ReactComponent as ArrowIcon } from '@/assets/icons/arrow.svg';
import styles from './ReadNext.module.scss';

export interface ReadNextProps {
  readNextText: string;
  title: string;
  imageUrl: string;
  navigateUrl: string;
}

const ReadNext: FC<ReadNextProps> = ({
  readNextText, title, imageUrl, navigateUrl,
}: ReadNextProps) => (
  <>
    <div className={styles.sectionTitle}>{readNextText}</div>
    <a href={navigateUrl} className={styles.container}>
      <div className={styles.imageWrapper}>
        <img src={imageUrl} alt={title} className={classNames(styles.image, 'lazyload')} />
      </div>
      <div className={styles.title}>
        {title}
      </div>
      <div className={styles.arrowWrapper}>
        <ArrowIcon className={styles.arrowIcon} />
      </div>
    </a>
  </>
);

export default ReadNext;
