import React, {
  FC, useCallback, useContext, useEffect, useRef,
} from 'react';
import classNames from 'classnames';
import { useToggleModalsContext } from '@/common/contexts/globalModalContext';
import { ScrollIntoViewContext } from '@/common/contexts/scrollIntoViewContext';
import { ProductInfo, SharedImagesProps } from '@/common/components/Products/types';
import Images from '@/common/components/Products/Images/Images';
import Button from '@/common/components/Button/Button';
import grid from '@/common/css/grid.module.scss';
import styles from './Product.module.scss';

interface ProductProps extends ProductInfo, SharedImagesProps {
}

const Product: FC<ProductProps> = ({
  title,
  description,
  button,
  target,
  modalName,
  customClassNames,
  isReversed,
  isImagesFullWidthMobile,
  isImagesNotFullWidthDesktop,
  ...imagesProps
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { addRef } = useContext(ScrollIntoViewContext);
  const {
    container: containerClassName,
    images: imagesClassNames,
    reversed: reversedClassName,
    textBlock: textBlockClassName,
    button: buttonClassName,
    title: titleClassName,
    description: descriptionClassName,
  } = customClassNames || {};

  useEffect(() => {
    if (!ref.current || !target) {
      return;
    }
    addRef(target, ref);
  }, []);

  const toggleModal = useToggleModalsContext();

  const showModal = useCallback((): void => {
    if (!modalName) {
      return;
    }

    toggleModal({ [modalName]: true });
  }, [modalName, toggleModal]);

  return (
    <div
      ref={ref}
      className={classNames(
        containerClassName,
        grid.row,
        styles.container,
        isReversed && [styles.reversed, reversedClassName],
        !!imagesProps.officeCards?.length && styles.containerWithCards,
      )}
    >
      <Images
        {...imagesProps}
        customClassNames={imagesClassNames}
        isImagesFullWidthMobile={isImagesFullWidthMobile}
        isImagesNotFullWidthDesktop={isImagesNotFullWidthDesktop}
        isReversed={isReversed}
      />
      <div className={classNames(!isImagesFullWidthMobile && grid.col3, grid.colSm1)} />
      <div className={classNames(styles.textBlock, textBlockClassName, grid.colSm5, grid.colMd4)}>
        <div className={classNames(
          styles.title,
          titleClassName,
          !description && styles.secondary,
        )}
        >
          {title}
        </div>
        <div
          className={classNames(styles.description, descriptionClassName)}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        {!!button && (
          <Button
            customClassNames={{ button: classNames(styles.button, buttonClassName) }}
            href={button.href}
            text={button.text}
            {...((!button.href && !!modalName) && { onClick: showModal })}
            additionalAttributes={{ ...(button.isTarget && { target: '_blank' }) }}
          />
        )}
      </div>
    </div>
  );
};

export default Product;
