import React, {
  FC, memo, useCallback, useEffect, useState,
} from 'react';
import classNames from 'classnames';
import { isBrowserEnv } from '@/helpers/checkIsBrowser';
import { getCookie, sendSettingsToServer } from '@/helpers/cookiesHelper';
import { ModalName, useToggleModalsContext } from '@/common/contexts/globalModalContext';
import { ReactComponent as Spinner } from '@/assets/img/spinner.svg';
import styles from './CookiesSettingsBar.module.scss';

export interface CookieSettingsBarProps {
  consentBarText: string;
  gaKey: string;
  link: { href: string; text: string };
  cookieSettingBtnText: string;
  acceptCookiesBtnText: string;
}

const CookieSettingsBar: FC<CookieSettingsBarProps> = memo(({
  consentBarText, gaKey, link, cookieSettingBtnText, acceptCookiesBtnText,
}: CookieSettingsBarProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isPending, setIsPending] = useState(false);

  useEffect(() => {
    if (isBrowserEnv() && !getCookie('cookieConsent')) {
      setIsOpen(true);
    }
  }, []);

  const toggleModal = useToggleModalsContext();

  const openCookieSettingsModal = useCallback(() => {
    toggleModal({ [ModalName.cookies]: true });
  }, [toggleModal]);

  const allowAllScripts = useCallback(() => {
    if (isPending) {
      return;
    }

    const body = {
      AllowPerformanceCookies: true,
      AllowAdvertisingCookies: true,
      AllowFunctionalCookies: true,
      AllowABTestingCookies: true,
    };
    setIsPending(true);
    sendSettingsToServer('', body, gaKey).then(() => {
      setIsOpen(false);
      setIsPending(false);
    });
  }, [gaKey, isPending]);

  return (
    <div className={classNames(styles.cookieSettingsBarContainer, isOpen && styles.barOpen)} id="cookiesConsentBar">
      <div className={styles.cookieSettingsBarMainText}>
        {`${consentBarText} `}
        <a
          className={styles.cookieSettingsBarLink}
          href={link.href}
          rel="noopener noreferrer"
          target="_blank"
        >
          {link.text}
        </a>
      </div>
      <button
        className={styles.cookieSettingsBarBntSecondary}
        onClick={openCookieSettingsModal}
        type="button"
      >
        {cookieSettingBtnText}
      </button>
      <button
        className={styles.cookieSettingsBarBntPrimary}
        onClick={allowAllScripts}
        type="button"
      >
        {isPending ? <Spinner /> : acceptCookiesBtnText}
      </button>
      <div className={styles.cookieSettingsBarBntClose} onClick={allowAllScripts}>&times;</div>
    </div>
  );
});

export default CookieSettingsBar;
