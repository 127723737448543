import React, {
  ChangeEvent, FC, memo, useCallback, useState,
} from 'react';
import classNames from 'classnames';
import ErrorMessage from '@/common/components/Form/ErrorMessage/ErrorMessage';
import FormInputWrapper from '@/common/components/Form/FormInputWrapper/FormInputWrapper';
import styles from './TextArea.module.scss';

interface TextAreaProps {
  label?: string;
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
  isValid?: boolean;
  errorMessage?: string;
}

const TextArea: FC<TextAreaProps> = ({
  label,
  onChange,
  value,
  placeholder,
  isValid = true,
  errorMessage,
}: TextAreaProps) => {
  const [isActive, setIsActive] = useState<boolean>(!!value);

  const changeHandler = useCallback((e: ChangeEvent<HTMLTextAreaElement>): void => {
    const val = e.target.value;
    onChange(val);
  }, [onChange]);

  const handleBlur = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    let newIsActiveState = true;
    const val = e.target.value;
    if (!val) {
      newIsActiveState = false;
    }
    setIsActive(newIsActiveState);
  }, []);

  return (
    <FormInputWrapper label={label} isValid={isValid} isActive={isActive} className={styles.container}>
      <textarea
        className={classNames(styles.textarea, !isValid && styles.isInvalid)}
        placeholder={placeholder}
        onChange={changeHandler}
        onFocus={() => setIsActive(true)}
        onBlur={handleBlur}
        value={value}
      />

      {!isValid && (
        <ErrorMessage title={errorMessage} />
      )}
    </FormInputWrapper>
  );
};
TextArea.displayName = 'TextArea';

export default memo(TextArea);
