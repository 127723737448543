import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './Author.module.scss';

export interface AuthorProps {
  name?: string;
  photo?: string;
  imageUrl?: string;
  extraInfoText?: string;
  backgroundColorLogo?: string;
}

enum Colors {
  white = 'White',
  grey = 'Grey',
}

const Author: FC<AuthorProps> = ({
  name, photo, imageUrl, extraInfoText, backgroundColorLogo,
}: AuthorProps) => (
  <div className={classNames(styles.container, !(photo || imageUrl) && styles.containerWithoutImage)}>
    {(photo || imageUrl) && (
      <div className={styles.photoWrapper}>
        <img
          alt={name}
          className={classNames(styles.photo,
            (backgroundColorLogo === Colors.grey) && styles.isGrey,
            (backgroundColorLogo === Colors.white) && styles.isWhite)}
          src={photo || imageUrl}
          title={name}
        />
      </div>
    )}
    {extraInfoText}
  </div>
);

export default Author;
