import React, { FC } from 'react';
import { ReactComponent as TwitterIcon } from '@/assets/icons/twitter.svg';
import LinkedInConversionPixel from '@/common/components/LinkedInConversionPixel/LinkedInConversionPixel';
import styles from './TwitterIconSuccess.module.scss';

export interface TwitterIconSuccessProps {
  thanksMessage: string;
  inboxMessage: string;
  conversionId: string;
  twitterImage: {
    text: string;
    link: string;
  };
}

const TwitterIconSuccess: FC<TwitterIconSuccessProps> = ({
  thanksMessage,
  inboxMessage,
  twitterImage: {
    text,
    link,
  },
  conversionId,
}: TwitterIconSuccessProps) => (
  <div className={styles.successMessage}>
    <div className={styles.thanksText} data-name="submit-success-title-newsletter">{thanksMessage}</div>
    <LinkedInConversionPixel conversionId={conversionId} />
    <div className={styles.detailsText}>{inboxMessage}</div>
    <TwitterIcon className={styles.twitterIcon} />
    <a className={styles.twitterLink} href={link}>{text}</a>
  </div>
);

export default TwitterIconSuccess;
