import React, { FC } from 'react';
import { ReactComponent as EmailIcon } from '@/assets/icons/emailDelivered.svg';
import LinkedInConversionPixel from '@/common/components/LinkedInConversionPixel/LinkedInConversionPixel';
import styles from './EmailIconSuccess.module.scss';

export interface EmailIconSuccessProps {
  successTitle: string;
  successSubtitle: string;
  conversionId: string;
}

const EmailIconSuccess: FC<EmailIconSuccessProps> = ({
  successTitle,
  successSubtitle,
  conversionId,
}: EmailIconSuccessProps) => (
  <div className={styles.container}>
    <EmailIcon className={styles.icon} />
    <div className={styles.title} data-name="submit-success-title">{successTitle}</div>
    <LinkedInConversionPixel conversionId={conversionId} />
    <div className={styles.subtitle}>{successSubtitle}</div>
  </div>
);

export default EmailIconSuccess;
