import React, { FC } from 'react';
import key from 'weak-key';
import { ProductClassNames, ProductsInfo, SmallImageViewNames } from '@/common/components/Products/types';
import Product from '@/common/components/Products/Product/Product';

interface ProductsPreviewSectionProps extends ProductsInfo {
  productClassNames?: ProductClassNames;
  smallImageViewNames?: SmallImageViewNames[];
  defaultModalName?: string;
  isImagesFullWidthMobile?: boolean;
  isImagesNotFullWidthDesktop?: boolean;
}

const Products: FC<ProductsPreviewSectionProps> = ({
  products,
  productClassNames,
  smallImageViewNames,
  defaultModalName,
  isImagesFullWidthMobile,
  isImagesNotFullWidthDesktop,
}) => (
  <>
    {products?.map((product, index) => (
      <Product
        key={key(product)}
        customClassNames={productClassNames}
        isImagesFullWidthMobile={isImagesFullWidthMobile}
        isImagesNotFullWidthDesktop={isImagesNotFullWidthDesktop}
        isReversed={!(index % 2)}
        modalName={defaultModalName}
        smallImageViewName={smallImageViewNames?.[index]}
        {...product}
      />
    ))}
  </>
);

export default Products;
