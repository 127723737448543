import React, {
  FC, memo, useCallback, useEffect, useState,
} from 'react';
import classNames from 'classnames';
import useMediaQuery from '@/helpers/useMediaQuery';
import { getUserData } from '@/helpers/auth';
import { checkItemActivity, selectItem } from '@/common/layouts/MasterLayout/components/Header/AccountMenu/helpers';
import { AccountMenuLink } from '@/common/layouts/MasterLayout/components/Header/AccountMenu/types';
import Dropdown, { ListItemType } from '@/common/components/Dropdown/Dropdown';
import AccountMenuIconButton, {
  AccountMenuIconButtonProps,
} from '@/common/layouts/MasterLayout/components/Header/AccountMenu/AccountMenuIconButton/AccountMenuIconButton';
import styles from './AccountMenu.module.scss';

export interface AccountMenuProps {
  loggedInUserLinks: AccountMenuLink[];
  unloggedUserLinks: AccountMenuLink[];
  loginUrl: string; // generated in BE, cannot be inserted in unloggedUserLinks
  tooltipText?: string;
}

interface AccountMenuComponentProps extends AccountMenuProps {
  isInverted: boolean;
  isActive?: boolean;
  setIsOpen: (menuState: boolean) => void;
  className?: string;
}

const offsetValues = {
  mobile: {
    mainAxis: 11,
    crossAxis: 0,
  },
  desktop: {
    mainAxis: -4,
    crossAxis: -20,
  },
};

const AccountMenu: FC<AccountMenuComponentProps> = ({
  loggedInUserLinks,
  unloggedUserLinks,
  loginUrl,
  tooltipText,
  isInverted,
  isActive,
  setIsOpen,
  className,
}) => {
  const isMobile = useMediaQuery({ maxBreakpoint: 'sm' });

  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  useEffect(() => {
    setIsUserLoggedIn(!!getUserData());
  }, []);

  const selectItemCallback = useCallback(async (item: AccountMenuLink) => {
    await selectItem(item, { loginUrl });
  }, [loginUrl]);

  const renderDropdownButton = useCallback((props: AccountMenuIconButtonProps) => (
    <AccountMenuIconButton
      {...props}
      isInverted={isInverted}
      tooltipText={tooltipText}
    />
  ), [isInverted, tooltipText]);

  const links = isUserLoggedIn ? loggedInUserLinks : unloggedUserLinks;

  if (!links?.length) {
    return null;
  }

  const offsetValue = isMobile ? offsetValues.mobile : offsetValues.desktop;

  return (
    <Dropdown
      ButtonComponent={renderDropdownButton}
      checkItemActivity={checkItemActivity}
      customClassNames={{
        container: classNames(styles.container, className),
        dropdown: styles.dropdown,
      }}
      isActive={isActive}
      items={links}
      listItemType={ListItemType.text}
      offsetValue={offsetValue}
      placement="bottom-end"
      selectItemCallback={selectItemCallback}
      shouldCloseOnScroll
      toggleCallback={setIsOpen}
    />
  );
};

export default memo(AccountMenu);
