import React, { FC } from 'react';
import styles from './PrivacyText.module.scss';

interface PrivacyTextProps {
  text: string;
}

const PrivacyText: FC<PrivacyTextProps> = ({
  text,
}: PrivacyTextProps) => (
  <div
    className={styles.privacyTextContainer}
    dangerouslySetInnerHTML={{ __html: text }}
  />
);

export default PrivacyText;
