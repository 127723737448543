import React, {
  ChangeEvent, FC, memo, useCallback, useEffect, useState,
} from 'react';
import classNames from 'classnames';
import ErrorMessage from '@/common/components/Form/ErrorMessage/ErrorMessage';
import FormInputWrapper from '@/common/components/Form/FormInputWrapper/FormInputWrapper';
import styles from './Input.module.scss';

interface InputProps {
  onChange?: (value: string) => void;
  label?: string;
  placeholder?: string;
  value: string;
  isValid?: boolean;
  type?: string;
  errorMessage?: string;
  customClassNames?: {
    input?: string;
  };
}

const Input: FC<InputProps> = ({
  value,
  label,
  onChange,
  isValid,
  errorMessage,
  type,
  customClassNames,
  placeholder,
}: InputProps) => {
  const [isValidAfterBlur, checkIsValidAfterBlur] = useState<boolean>(true);
  const [isActive, setIsActive] = useState<boolean>(!!value);

  useEffect(() => {
    if (isValidAfterBlur && !isValid && !value) {
      checkIsValidAfterBlur(false);
    }
  }, [
    value, isValid, isValidAfterBlur,
  ]);

  const handleInputChange = useCallback((e: ChangeEvent<HTMLInputElement>): void => {
    if (!onChange) {
      return;
    }

    onChange(e.target.value);
  }, [onChange]);

  const handleBlur = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    let newIsActiveState = true;
    const val = e.target.value;
    if (!val) {
      newIsActiveState = false;
    }
    setIsActive(newIsActiveState);

    checkIsValidAfterBlur(!!isValid);
  }, [isValid]);

  const maxLength = (!type || (type === 'text')) ? 40 : 255;

  return (
    <FormInputWrapper label={label} isValid={isValid} isActive={isActive} className={styles.container}>
      <input
        autoComplete="off"
        className={classNames(styles.input, !isValid && styles.isInvalid, customClassNames?.input)}
        maxLength={maxLength}
        onBlur={handleBlur}
        onChange={handleInputChange}
        onInput={() => checkIsValidAfterBlur(true)}
        onFocus={() => setIsActive(true)}
        type={type || 'text'}
        value={value}
        placeholder={placeholder}
      />
      {!isValid && (
        <ErrorMessage title={errorMessage} />
      )}
    </FormInputWrapper>
  );
};
Input.displayName = 'Input';

export default memo(Input);
