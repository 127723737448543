import React, { FC, ReactElement } from 'react';
import classNames from 'classnames';
import { CarouselImageType, CarouselSlideProps } from '@/common/components/Carousel/types';
import ResponsiveImage from '@/common/components/ResponsiveImage/ResponsiveImage';
import styles from '@/common/components/Carousel/Slide/Slide.module.scss';

interface SlideProps {
  item: CarouselImageType;
  slideProps: CarouselSlideProps;
  index: number;
  isActive?: boolean;
}

const cyNames = {
  image: 'carousel-slide-image',
  activeImage: 'carousel-slide-image-active',
};

const Slide: FC<SlideProps> = ({
  item,
  slideProps,
  index,
  isActive,
}) => {
  const {
    onSlideClick, className, imageClassName, itemRenderer, placeholderUrl,
  } = slideProps;

  const renderItem = (): ReactElement => {
    if (!itemRenderer) {
      return (
        <ResponsiveImage
          additionalAttributes={{ 'data-cy': isActive ? cyNames.activeImage : cyNames.image }}
          className={classNames(styles.image, imageClassName)}
          placeholderUrl={placeholderUrl}
          urls={item}
        />
      );
    }

    return itemRenderer(item, index);
  };

  const carouselSlideAttributes = { onClick: onSlideClick };

  return (
    <div className={classNames(styles.wrapper, className)} {...carouselSlideAttributes}>
      {renderItem()}
    </div>
  );
};
Slide.displayName = 'Slide';

export default Slide;
