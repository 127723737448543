import React, { FC, ReactNode, useMemo } from 'react';
import TwitterIconSuccess, {
  TwitterIconSuccessProps,
} from '@/common/components/Modal/successSteps/TwitterIconSuccess/TwitterIconSuccess';
import { FieldType, FormField, ModalFormBaseProps } from '@/common/components/Modal/ModalForm/ModalForm.types';
import { RegExpValidator, RequiredValidator, ValidatorType } from '@/helpers/validation';
import ModalForm from '@/common/components/Modal/ModalForm/ModalForm';
import { ModalName } from '@/common/contexts/globalModalContext';

export interface NewsletterModalProps extends ModalFormBaseProps, TwitterIconSuccessProps {
  emailPlaceholder: string;
  emailRegex: string;
  validationEmailMessage: string;
  requiredErrorMessage: string;
  signUpButtonText: string;
}

const NewsletterModal: FC<NewsletterModalProps> = ({
  emailPlaceholder,
  emailRegex,
  validationEmailMessage,
  requiredErrorMessage,
  signUpButtonText,
  thanksMessage,
  inboxMessage,
  twitterImage,
  conversionId,
  ...formProps
}: NewsletterModalProps) => {
  const fields: FormField[] = [
    {
      name: 'email',
      fieldType: FieldType.INPUT,
      fieldData: {
        label: emailPlaceholder,
      },
      validationSchema: [
        {
          type: ValidatorType.REQUIRED,
          isRequired: true,
          errorMessage: requiredErrorMessage,
        } as RequiredValidator,
        {
          type: ValidatorType.REGEXP,
          regExp: new RegExp(emailRegex),
          errorMessage: validationEmailMessage,
        } as RegExpValidator,
      ],
    },
  ];

  const columns = [{ fields }];

  const renderSuccessMessage = useMemo((): ReactNode => (
    <TwitterIconSuccess
      conversionId={conversionId}
      inboxMessage={inboxMessage}
      thanksMessage={thanksMessage}
      twitterImage={twitterImage}
    />
  ), [
    conversionId, inboxMessage, thanksMessage, twitterImage,
  ]);

  const getAdditionalParams = () => ({
    retURL: window.location.href,
  });

  return (
    <ModalForm
      columns={columns}
      endpoint="SalesforceSignUp"
      getAdditionalParams={getAdditionalParams}
      modalName={ModalName.newsletter}
      submitRequestButtonText={signUpButtonText}
      successMessage={renderSuccessMessage}
      {...formProps}
    />
  );
};

export default NewsletterModal;
