import { LinkProps, ResponsiveImageUrls } from '@/types';

export enum SmallImageViewNames {
  image,
  industryPoster,
  marketReportPoster,
  banner,
}

export interface ProductCard {
  title: string;
  description: string;
  imageCrops: ResponsiveImageUrls;
}

export interface ImagesClassNames {
  container?: string;
  small?: string;
  large?: string;
}

export interface SmallBlockData {
  headerText: string;
  middleText: string | null;
  bottomText: string;
  image: string;
}

export interface AdditionalContent {
  bigImageCrops?: ResponsiveImageUrls;
  bigImageTopText?: string;
  bigImageBottomText?: string;
  smallImageCrops?: ResponsiveImageUrls;
  smallImageTopText?: string;
  smallImageBottomText?: string;
}

export interface ImagesProps extends SmallImageProps {
  officeCards?: ProductCard[] | null;
  images?: ResponsiveImageUrls;
  backgroundImageCrops?: ResponsiveImageUrls | null;
  imageCrops?: ResponsiveImageUrls;
  additionalContents?: AdditionalContent[] | null;
}

export interface SmallImageProps {
  smallContentBlock?: SmallBlockData | null;
}

export interface SharedImagesProps {
  isImagesFullWidthMobile?: boolean;
  isImagesNotFullWidthDesktop?: boolean;
  smallImageViewName?: SmallImageViewNames;
  isReversed?: boolean;
}

export interface ProductInfo extends ImagesProps {
  title: string;
  description: string;
  button?: LinkProps;
  target?: string;
  modalName?: string;
  customClassNames?: ProductClassNames;
}

export interface ProductClassNames {
  container?: string;
  textBlock?: string;
  images?: ImagesClassNames;
  button?: string;
  reversed?: string;
  title?: string;
  description?: string;
}

export interface ProductsInfo {
  products?: ProductInfo[];
}
