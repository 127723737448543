import React, { FC, ReactNode, useMemo } from 'react';
import { RegExpValidator, RequiredValidator, ValidatorType } from '@/helpers/validation';
import { ModalName } from '@/common/contexts/globalModalContext';
import {
  FieldType,
  FormColumn,
  FormField,
  ModalFormBaseProps,
} from '@/common/components/Modal/ModalForm/ModalForm.types';
import { SelectProps } from '@/common/components/Form/Select/Select';
import ModalForm from '@/common/components/Modal/ModalForm/ModalForm';
import TickIconSuccess, {
  TickIconSuccessProps,
} from '@/common/components/Modal/successSteps/TickIconSuccess/TickIconSuccess';

export interface CustomRequestModalProps extends ModalFormBaseProps, TickIconSuccessProps {
  subtitleText: string;
  requiredErrorMessage: string;
  nameLabel: string;
  emailLabel: string;
  emailRegex: string;
  emailErrorMessage: string;
  companyLabel: string;
  jobTitleLabel?: string;
  phoneLabel: string;
  phoneRegex: string;
  phoneErrorMessage: string;
  messageLabel: string;
  countryLabel: string;
  countrySelect: SelectProps;
  leadTypeTitle: string;
  regionName: string;
}

interface CustomRequestModalComponentProps extends CustomRequestModalProps {
  modalName: ModalName;
  endpoint: string;
}

const CustomRequestModal: FC<CustomRequestModalComponentProps> = ({
  subtitleText,
  conversionId,
  nameLabel,
  emailLabel,
  emailRegex,
  emailErrorMessage,
  companyLabel,
  jobTitleLabel,
  phoneLabel,
  countryLabel,
  countrySelect,
  phoneRegex,
  phoneErrorMessage,
  messageLabel,
  requiredErrorMessage,
  leadTypeTitle,
  regionName,
  ...formProps
}) => {
  const fields: FormField[] = [
    {
      name: 'name',
      fieldType: FieldType.INPUT,
      fieldData: {
        label: nameLabel,
      },
      validationSchema: [],
    },
    {
      name: 'company',
      fieldType: FieldType.INPUT,
      fieldData: {
        label: companyLabel,
      },
      validationSchema: [],
    },
    ...(jobTitleLabel ? [
      {
        name: 'jobTitle',
        fieldType: FieldType.INPUT,
        fieldData: {
          label: jobTitleLabel,
        },
        validationSchema: [],
      },
    ] : []),
    {
      name: 'email',
      fieldType: FieldType.INPUT,
      fieldData: {
        label: emailLabel,
      },
      validationSchema: [],
    },
    {
      name: 'phone',
      fieldType: FieldType.INPUT,
      fieldData: {
        label: phoneLabel,
      },
      validationSchema: [],
    },
    {
      name: 'country',
      fieldType: FieldType.SELECT,
      fieldData: {
        label: countryLabel,
        ...countrySelect,
      },
      validationSchema: [],
    },
    {
      name: 'message',
      fieldType: FieldType.TEXT_AREA,
      fieldData: {
        label: messageLabel,
      },
      validationSchema: [],
    },
  ];

  fields.forEach((field: FormField): void => {
    if (field.name === 'country') {
      return;
    }

    field.validationSchema.push({
      type: ValidatorType.REQUIRED,
      isRequired: true,
      errorMessage: requiredErrorMessage,
    } as RequiredValidator);

    if (field.name === 'email') {
      field.validationSchema.push({
        type: ValidatorType.REGEXP,
        regExp: new RegExp(emailRegex),
        errorMessage: emailErrorMessage,
      } as RegExpValidator);
    }

    if (field.name === 'phone') {
      field.validationSchema.push({
        type: ValidatorType.REGEXP,
        regExp: new RegExp(phoneRegex),
        errorMessage: phoneErrorMessage,
      } as RegExpValidator);
    }
  });

  const columns: FormColumn[] = [
    {
      fields: fields.slice(0, 4),
    },
    {
      fields: fields.slice(4),
    },
  ];

  const renderSuccessMessage = useMemo((): ReactNode => (
    <TickIconSuccess
      conversionId={conversionId}
      successSubtitle={formProps.successSubtitle}
      successTitle={formProps.successTitle}
    />
  ), [
    conversionId, formProps.successSubtitle, formProps.successTitle,
  ]);

  const subtitleWithWrappedFirstSentence = useMemo((): ReactNode => {
    if (!subtitleText) {
      return null;
    }

    const firstSentenceEndIndex = subtitleText.search(/(!)|(\.)|(\?)/) + 1;

    return (
      <>
        {subtitleText.slice(0, firstSentenceEndIndex)}
        <br />
        {subtitleText.slice(firstSentenceEndIndex)}
      </>
    );
  }, [subtitleText]);

  const getAdditionalParams = () => ({
    leadTypeTitle,
    regionName,
    retURL: window.location.href,
  });

  return (
    <ModalForm
      columns={columns}
      getAdditionalParams={getAdditionalParams}
      subtitleSection={subtitleWithWrappedFirstSentence}
      successMessage={renderSuccessMessage}
      {...formProps}
    />
  );
};
CustomRequestModal.displayName = 'CustomRequestModal';

export default CustomRequestModal;
