import React, { FC, useMemo, useRef } from 'react';
import classNames from 'classnames';
import useTranslation from '@/common/components/Carousel/useTranslation';
import styles from '@/common/components/Carousel/Dots/Dots.module.scss';

interface DotsProps {
  count: number;
  activeSlideIndex: number;
  changeSlide: (index: number) => void;
  className?: string;
}

const Dots: FC<DotsProps> = ({
  count,
  activeSlideIndex,
  changeSlide,
  className,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const activeItemRef = useRef<HTMLLIElement>(null);
  const translationDependencies = useMemo(() => ([count, activeSlideIndex]), [count, activeSlideIndex]);
  const dotsTranslation = useTranslation(containerRef, activeItemRef, translationDependencies);

  const dotsList = useMemo(() => {
    const dots = [];
    for (let i = 0; i < count; i += 1) {
      const isActive = (i === activeSlideIndex);

      const refAttribute = isActive ? { ref: activeItemRef } : {};

      dots.push(<li
        key={i}
        className={classNames(styles.dot, isActive && styles.isActive)}
        onClick={(): void => {
          changeSlide(i);
        }}
        {...refAttribute}
      />);
    }

    return dots;
  }, [
    count, activeSlideIndex, changeSlide,
  ]);

  return useMemo(() => {
    const dotsStyle = { transform: `translateX(${dotsTranslation}px)` };

    return (
      <div ref={containerRef} className={classNames(className, styles.wrapper)}>
        <ul className={styles.container} style={dotsStyle}>
          {dotsList}
        </ul>
      </div>
    );
  }, [
    dotsList, dotsTranslation, className,
  ]);
};
Dots.displayName = 'Dots';

export default Dots;
