import React, { FC } from 'react';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import classNames from 'classnames';
import { AdditionalContent, SmallImageProps, SmallImageViewNames } from '@/common/components/Products/types';
import ResponsiveImage from '@/common/components/ResponsiveImage/ResponsiveImage';
import Banner from '@/common/components/Banner/Banner';
import IndustryPoster from '@/common/components/IndustryPoster/IndustryPoster';
import MarketReportPoster from '@/common/components/MarketReportPoster/MarketReportPoster';
import styles from './SmallImageBlock.module.scss';

interface SmallImageBlockProps extends SmallImageProps {
  additionalContent?: AdditionalContent | null;
  smallImageViewName?: SmallImageViewNames;
  className?: string;
  isReversed?: boolean;
}

const SmallImageBlock: FC<SmallImageBlockProps> = ({
  smallImageViewName,
  smallContentBlock,
  additionalContent,
  className,
  isReversed,
}: SmallImageBlockProps) => {
  if (!smallContentBlock && !additionalContent) {
    return null;
  }

  const imageUrl = smallContentBlock?.image;
  const imageUrls = additionalContent?.smallImageCrops;
  const title = additionalContent?.smallImageTopText || smallContentBlock?.headerText || '';
  const subTitle = smallContentBlock?.middleText || '';
  const description = additionalContent?.smallImageBottomText || smallContentBlock?.bottomText || '';

  const blockClassName = classNames(className, styles.container, isReversed && styles.isReversed);

  switch (smallImageViewName) {
    case SmallImageViewNames.industryPoster: {
      return (
        <IndustryPoster
          customClassNames={{
            container: classNames(blockClassName, styles.poster, styles.industry),
            title: styles.industryTitle,
            label: styles.industryLabel,
          }}
          title={title}
          label={description}
          imageUrl={imageUrl}
          imageUrls={imageUrls}
        />
      );
    }
    case SmallImageViewNames.marketReportPoster: {
      return (
        <MarketReportPoster
          customClassNames={{
            container: classNames(blockClassName, styles.poster, styles.marketReport),
            title: styles.marketReportTitle,
            quarter: styles.marketReportQuarter,
            label: styles.marketReportLabel,
          }}
          title={title}
          quarter={subTitle}
          marketReportLabel={description}
          imageUrls={imageUrls}
          imageUrl={imageUrl}
        />
      );
    }
    case SmallImageViewNames.banner: {
      return (
        <Banner
          customClassNames={{
            container: classNames(blockClassName, styles.banner),
            title: styles.bannerTitle,
            image: styles.bannerImage,
            description: styles.bannerDescription,
          }}
          imageUrl={imageUrl}
          title={title}
          description={description}
        />
      );
    }
    case SmallImageViewNames.image:
    default: {
      if (imageUrls) {
        return (
          <ResponsiveImage urls={imageUrls} alt={title} className={classNames(blockClassName, styles.image)} />
        );
      }

      if (imageUrl) {
        return (
          <img src={imageUrl} alt={title} className={classNames(blockClassName, styles.image)} />
        );
      }

      return null;
    }
  }
};

export default SmallImageBlock;
