import React, { FC, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { ReactComponent as TickIcon } from '@/assets/icons/tick.svg';
import styles from './Checkbox.module.scss';

interface CheckboxProps {
  value: string;
  label?: string;
  onChange: (value: string) => void;
}

const Checkbox: FC<CheckboxProps> = ({
  value,
  label,
  onChange,
}: CheckboxProps) => {
  const handleChange = useCallback((): void => {
    if (!onChange) {
      return;
    }
    const newValue = (value ? '' : '1'); // Hard-coded string value to simplify working with form fields

    onChange(newValue);
  }, [onChange, value]);

  return useMemo(() => (
    <div
      className={styles.container}
      onClick={handleChange}
    >
      <div className={classNames(styles.checkbox, !!value && styles.isActive)}>
        {!!value && (
          <TickIcon className={styles.checkboxIcon} />
        )}
      </div>
      <div className={styles.text}>
        {label}
      </div>
    </div>
  ), [
    value, handleChange, label,
  ]);
};

export default Checkbox;
