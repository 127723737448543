import React, { FC, useMemo, useRef } from 'react';
import classNames from 'classnames';
import key from 'weak-key';
import useTranslation from '@/common/components/Carousel/useTranslation';
import { CarouselImagesType } from '@/common/components/Carousel/types';
import ResponsiveImage from '@/common/components/ResponsiveImage/ResponsiveImage';
import styles from '@/common/components/Carousel/Thumbnails/Thumbnails.module.scss';

interface ThumbnailsProps {
  items: CarouselImagesType;
  activeSlideIndex: number;
  changeSlide: (index: number) => void;
}

const Thumbnails: FC<ThumbnailsProps> = ({
  items, activeSlideIndex, changeSlide,
}: ThumbnailsProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const activeItemRef = useRef<HTMLLIElement>(null);
  const translationDependencies = useMemo(() => ([items, activeSlideIndex]), [items, activeSlideIndex]);
  const thumbnailsTranslation = useTranslation(containerRef, activeItemRef, translationDependencies);

  const thumbnailsList = useMemo(() => items.map((item, index) => {
    const isActive = (index === activeSlideIndex);

    const refAttribute = isActive ? { ref: activeItemRef } : {};

    return (
      <li
        key={key(item)}
        className={styles.thumbnail}
        onClick={(): void => {
          changeSlide(index);
        }}
        {...refAttribute}
      >
        <ResponsiveImage
          className={classNames(styles.image, isActive && styles.isActive)}
          urls={item}
        />
      </li>
    );
  }), [
    items, activeSlideIndex, changeSlide,
  ]);

  return useMemo(() => {
    const thumbnailsStyle = { transform: `translateX(${thumbnailsTranslation}px)` };

    return (
      <div className={styles.fullscreenBottom}>
        <div ref={containerRef} className={styles.wrapper}>
          <ul className={styles.thumbnails} style={thumbnailsStyle}>
            {thumbnailsList}
          </ul>
        </div>
      </div>
    );
  }, [thumbnailsList, thumbnailsTranslation]);
};

export default Thumbnails;
