import React, { FC, memo } from 'react';

interface LinkedInConversionPixelProps {
  conversionId: string;
}

const LinkedInConversionPixel: FC<LinkedInConversionPixelProps> = ({ conversionId }: LinkedInConversionPixelProps) => {
  if (!conversionId) {
    return null;
  }

  return (
    <img
      height="1"
      width="1"
      style={{ display: 'none' }}
      alt=""
      src={`https://px.ads.linkedin.com/collect/?pid=181436&conversionId=${conversionId}&fmt=gif`}
    />
  );
};
LinkedInConversionPixel.displayName = 'LinkedInConversionPixel';

export default memo(LinkedInConversionPixel);
