import React, {
  CSSProperties, FC, useEffect, useState,
} from 'react';
import classNames from 'classnames';
import Button, { ButtonStyleType, ButtonType } from '@/common/components/Button/Button';
import grid from '@/common/css/grid.module.scss';
import styles from './WarningBar.module.scss';

export interface WarningBarProps {
  title?: string,
  text?: string,
  linkText?: string,
  bgColourHexCode?: string,
  textColourHexCode?: string,
  updateDate?: number,
  disabled?: boolean,
}

interface WarningBarData {
  closedCount: number;
  closedTime: number | null;
  updateDate: number | null;
}

const periodOfFirstHiding = 24 * 60 * 60 * 1000;

const warningBarDataStorageName = 'warningBarData';

const getWarningBarData = (): WarningBarData | null => {
  const data = localStorage.getItem(warningBarDataStorageName);

  if (data) {
    return JSON.parse(data);
  }

  return null;
};

const setWarningBarData = (data: WarningBarData) => {
  localStorage.setItem(warningBarDataStorageName, JSON.stringify(data));
};

const buttonClasses = { button: styles.button };
const buttonStylesConfig = {
  type: ButtonType.myAccount,
  styleType: ButtonStyleType.link,
};

const WarningBar: FC<WarningBarProps> = ({
  title,
  text,
  linkText,
  disabled: isDisabled,
  bgColourHexCode,
  textColourHexCode,
  updateDate,
}) => {
  const [isClosed, setIsClosed] = useState(true);

  const hasSomeInfo = !!(title || text);

  useEffect(() => {
    if (isDisabled) {
      return;
    }

    const warningBarData = getWarningBarData();
    const isWarningBarUpdated = warningBarData && updateDate !== warningBarData.updateDate;

    if (!warningBarData || isWarningBarUpdated) {
      setWarningBarData({
        updateDate: updateDate ?? null,
        closedCount: 0,
        closedTime: null,
      });

      setIsClosed(false);

      return;
    }

    if (warningBarData.closedCount === 0) {
      setIsClosed(false);

      return;
    }

    const prevClosedTime = warningBarData.closedTime;

    if (prevClosedTime === null) {
      return;
    }

    const currentTime = new Date().getTime();
    const isTimeOfFirstHidingOver = currentTime - prevClosedTime >= periodOfFirstHiding;

    if (warningBarData.closedCount === 1 && isTimeOfFirstHidingOver) {
      setIsClosed(false);
    }
  }, [isDisabled, updateDate]);

  const isWarningBarHidden = isDisabled || !hasSomeInfo || isClosed;

  useEffect(() => {
    if (isWarningBarHidden) {
      document.documentElement.style.removeProperty('--warning-bar-height');

      return;
    }

    document.documentElement.style.setProperty('--warning-bar-height', '50px');
  }, [isWarningBarHidden]);

  if (isWarningBarHidden) {
    return null;
  }

  const close = (): void => {
    setIsClosed(true);

    const warningBarData = getWarningBarData();

    if (!warningBarData) {
      return;
    }

    setWarningBarData({
      updateDate: warningBarData.updateDate,
      closedTime: new Date().getTime(),
      closedCount: warningBarData.closedCount + 1,
    });
  };

  const warningBarColors = {
    '--warning-bar-bg-color': bgColourHexCode,
    '--warning-bar-text-color': textColourHexCode,
  } as CSSProperties;

  return (
    <div
      className={classNames(styles.container, grid.container)}
      style={warningBarColors}
    >
      <div className={styles.text}>
        {!!title && (
          <>
            <b className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
            {' '}
          </>
        )}
        {!!text && (
          <span dangerouslySetInnerHTML={{ __html: text }} />
        )}
      </div>
      <Button
        buttonStylesConfig={buttonStylesConfig}
        customClassNames={buttonClasses}
        onClick={close}
        text={linkText}
      />
    </div>
  );
};

export default WarningBar;
