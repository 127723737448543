import React, {
  FC, memo, ReactNode, useCallback, useMemo, useState,
} from 'react';
import ModalLayout from '@/common/components/Modal/ModalLayout/ModalLayout';
import { ModalName, useModalsContext, useToggleModalsContext } from '@/common/contexts/globalModalContext';
import { FormProps, ModalFormBaseProps } from '@/common/components/Modal/ModalForm/ModalForm.types';
import Form from '@/common/components/Modal/ModalForm/Form/Form';

export interface ModalFormProps extends ModalFormBaseProps, FormProps {
  modalName: ModalName;
  successMessage: ReactNode;
}

const ModalForm: FC<ModalFormProps> = ({
  modalWindowTitle,
  modalName,
  successMessage,
  subtitleSection,
  columns,
  ...formProps
}: ModalFormProps) => {
  const toggleModal = useToggleModalsContext();
  const { modalsState } = useModalsContext();
  const isShown = modalsState[modalName];
  const [isFormSubmitted, setFormSubmitState] = useState(false);

  const closeHandler = useCallback((): void => {
    setFormSubmitState(false);
    toggleModal({ [modalName]: false });
  }, [modalName, toggleModal]);

  const onSubmitSuccess = (): void => {
    setFormSubmitState(true);
  };

  const renderModalContent = useMemo(() => {
    if (isFormSubmitted) {
      return successMessage;
    }

    return (
      <Form
        columns={columns}
        {...formProps}
        onSubmitSuccess={onSubmitSuccess}
        subtitleSection={subtitleSection}
      />
    );
  }, [
    columns, formProps, isFormSubmitted, subtitleSection, successMessage,
  ]);

  if (!isShown) {
    return null;
  }

  const isSingleColumn = columns.length === 1;

  return (
    <ModalLayout closeHandler={closeHandler} isSingleColumn={isSingleColumn} modalWindowTitle={modalWindowTitle}>
      {renderModalContent}
    </ModalLayout>
  );
};
ModalForm.displayName = 'ModalForm';

export default memo(ModalForm);
