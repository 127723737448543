const submitForm = (
  endpoint: string,
  body: any,
  successCallback: () => void,
  errorCallback: () => void,
  additionalParams?: any,
): void => {
  const url = `/umbraco/api/request/${endpoint}`;

  fetch(url, {
    method: 'POST',
    body: JSON.stringify({ ...body, ...additionalParams }),
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
  }).then((res: Response): void => {
    if (!res.ok) {
      errorCallback();

      return;
    }

    successCallback();
  }).catch((): void => {
    errorCallback();
  });
};

export default submitForm;
