import React, { FC, ReactNode, useCallback } from 'react';
import classNames from 'classnames';
import key from 'weak-key';
import Banner, { BannerProps } from '@/common/components/Banner/Banner';
import ListCard, { ListCardProps } from '@/common/components/ListCard/ListCard';
import { ModalName, useToggleModalsContext } from '@/common/contexts/globalModalContext';
import grid from '@/common/css/grid.module.scss';
import styles from './ListingGallery.module.scss';

interface ListingGalleryProps {
  banner?: BannerProps;
  cards?: ListCardProps[];
  className?: string;
}

const ListingGallery: FC<ListingGalleryProps> = ({
  cards, banner, className,
}: ListingGalleryProps) => {
  const articlesBeforeBanner = cards?.slice(0, 4)?.map((article) => <ListCard key={key(article)} {...article} />);
  const articlesAfterBanner = cards?.slice(4)?.map((article) => <ListCard key={key(article)} {...article} />);

  const toggleModal = useToggleModalsContext();

  const showModal = useCallback((): void => {
    toggleModal({ [ModalName.customReport]: true });
  }, [toggleModal]);

  const renderBanner = (): ReactNode => {
    if (!banner) {
      return null;
    }

    const bannerProps = { ...banner, button: { ...banner.button, onClick: showModal } };

    return (
      <Banner
        customClassNames={{
          container: classNames(grid.colSm6, grid.colMd4, styles.banner, !cards?.length && styles.isSingle),
        }}
        {...bannerProps}
      />
    );
  };

  return (
    <div className={classNames(grid.row, className)}>
      {articlesBeforeBanner}
      {renderBanner()}
      {articlesAfterBanner}
    </div>
  );
};

export default ListingGallery;
