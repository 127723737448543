import React, { FC, KeyboardEvent, memo } from 'react';
import classNames from 'classnames';
import { ReactComponent as AccountIcon } from '@/assets/icons/account.svg';
import styles from './AccountMenuIconButton.module.scss';

export interface AccountMenuIconButtonProps {
  isActive: boolean;
  isFocused: boolean;
  onClick: () => void;
  onKeyDown: (event: KeyboardEvent) => void;
  tooltipText?: string;
  className?: string;
}

interface AccountMenuIconButtonComponentProps extends AccountMenuIconButtonProps {
  tooltipText?: string;
  isInverted?: boolean;
}

const AccountMenuIconButton: FC<AccountMenuIconButtonComponentProps> = ({
  isActive,
  isFocused,
  isInverted,
  onClick,
  onKeyDown,
  tooltipText,
  className,
}) => (
  <button
    aria-label="Account Menu"
    className={classNames(
      styles.button,
      className,
      !isInverted && styles.isNotInverted,
      isActive && styles.isActive,
      isFocused && styles.isFocused,
    )}
    onClick={onClick}
    onKeyDown={onKeyDown}
    type="button"
  >
    <AccountIcon className={styles.icon} />
    {!isActive && !isFocused && (
      <div className={styles.tooltip}>
        {tooltipText}
      </div>
    )}
  </button>
);

export default memo(AccountMenuIconButton);
