import { ModalName } from '@/common/contexts/globalModalContext';
import {
  ExternalFormsProps,
  ExternalModalFormsProps,
} from '@/common/components/Modal/ExternalModalForms/ExternalModalForms';

interface ExternalFormExistenceProps extends ExternalModalFormsProps {
  modalName: ModalName;
}
type ExternalFormsMappingResponse = {
  [key in ModalName]?: boolean;
};

export const hasExternalForm = ({ pardot, modalName }: ExternalFormExistenceProps) => (
  pardot?.forms?.some((form) => (
    (form.name === modalName) && !!form.url
  ))
);

export const getExternalFormsMapping = (pardot?: ExternalFormsProps): ExternalFormsMappingResponse => {
  if (!pardot?.forms?.length) {
    return {};
  }

  const externalForms: ExternalFormsMappingResponse = {};

  pardot.forms.forEach(({ name, url }) => {
    if (name) {
      externalForms[name] = !!url;
    }
  });

  return externalForms;
};
