import React, { FC } from 'react';
import classNames from 'classnames';
import key from 'weak-key';
import IntroCard, { IntroCardData } from '@/common/components/IntroCard/IntroCard';
import grid from '@/common/css/grid.module.scss';
import styles from './IntroSection.module.scss';

interface IntroSectionProps {
  cards: IntroCardData[];
}

const IntroSection: FC<IntroSectionProps> = ({
  cards,
}: IntroSectionProps) => (
  <div className={classNames(grid.container, styles.container)}>
    <div className={classNames(grid.row, styles.row)}>
      {cards.map((card) => (
        <div
          key={key(card)}
          className={grid.colMd4}
        >
          <IntroCard {...card} />
        </div>
      ))}
    </div>
  </div>
);

export default IntroSection;
