import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import ScrollIntoViewProvider from '@/common/contexts/scrollIntoViewContext';
import ParallaxPageLayout, { ParallaxPageLayoutProps } from '@/common/layouts/ParallaxPageLayout/ParallaxPageLayout';
import { IntroCardData } from '@/common/components/IntroCard/IntroCard';
import IntroSection from '@/common/components/IntroSection/IntroSection';
import grid from '@/common/css/grid.module.scss';
import styles from './ScrollingIntroLayout.module.scss';

export interface ScrollingIntroLayoutProps extends ParallaxPageLayoutProps {
  introSection?: IntroCardData[];
  containerClassName?: string;
  children?: ReactNode;
}

const ScrollingIntroLayout: FC<ScrollingIntroLayoutProps> = ({
  introSection,
  containerClassName,
  children,
  ...pageLayoutProps
}: ScrollingIntroLayoutProps) => (
  <ParallaxPageLayout {...pageLayoutProps}>
    <ScrollIntoViewProvider>
      {!!introSection && (
        <IntroSection cards={introSection} />
      )}
      <div className={classNames(styles.container, grid.container, containerClassName)}>
        {children}
      </div>
    </ScrollIntoViewProvider>
  </ParallaxPageLayout>
);

export default ScrollingIntroLayout;
