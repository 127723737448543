enum Breakpoints {
  xs = 375,
  sm = 680,
  md = 1020,
  lg = 1280,
  xl = 1600,
}

export type BreakpointsKeys = keyof typeof Breakpoints;

export default Breakpoints;
