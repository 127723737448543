import React, { FC, useCallback, useState } from 'react';
import classNames from 'classnames';
import lazySizes from 'lazysizes';
import { CarouselImagesType, CarouselSlideProps } from '@/common/components/Carousel/types';
import Slides, { SlidesProps } from '@/common/components/Carousel/Slides/Slides';
import Dots from '@/common/components/Carousel/Dots/Dots';
import Thumbnails from '@/common/components/Carousel/Thumbnails/Thumbnails';
import Arrows from '@/common/components/Carousel/Arrows/Arrows';
import { ReactComponent as CloseIcon } from '@/assets/icons/close.svg';
import styles from '@/common/components/Carousel/Carousel.module.scss';

interface CarouselProps {
  items: CarouselImagesType;
  className?: string;
  slideProps?: CarouselSlideProps;
  dotsProps?: {
    className?: string;
  };
  arrowsProps?: {
    classNameLeft?: string;
    classNameRight?: string;
  };
  shouldOpenFullscreen?: boolean;
}

const Carousel: FC<CarouselProps> = ({
  items,
  className,
  slideProps = {},
  arrowsProps = {},
  dotsProps = {},
  shouldOpenFullscreen,
}) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const getNewIndex = useCallback((index: number): number => {
    if (index >= items.length) {
      return 0;
    }

    if (index < 0) {
      return (items.length - 1);
    }

    return index;
  }, [items]);

  const changeSlide = useCallback((index) => {
    const newIndex = getNewIndex(index);

    setActiveSlideIndex(newIndex);
  }, [getNewIndex]);

  const goToPrevSlide = useCallback(() => {
    changeSlide(activeSlideIndex - 1);
  }, [activeSlideIndex, changeSlide]);

  const goToNextSlide = useCallback(() => {
    changeSlide(activeSlideIndex + 1);
  }, [activeSlideIndex, changeSlide]);

  const toggleFullScreen = (isFullscreenOpen: boolean): void => {
    setIsFullscreen(isFullscreenOpen);
    lazySizes.autoSizer.checkElems();
  };

  const openFullscreen = useCallback(() => {
    toggleFullScreen(true);
  }, []);

  const closeFullScreen = useCallback(() => {
    toggleFullScreen(false);
  }, []);

  if (!items || !items.length) {
    return null;
  }

  const hasSeveralSlides = (items.length > 1);
  const carouselSlidesProps: SlidesProps = {
    activeSlideIndex,
    goToNextSlide,
    goToPrevSlide,
    items,
    slideProps: {
      ...slideProps,
      className: classNames(isFullscreen && styles.fullscreenCarouselImageWrapper, slideProps.className),
      imageClassName: classNames(isFullscreen && styles.fullscreenCarouselImage, slideProps.imageClassName),
    },
  };
  if (shouldOpenFullscreen) {
    carouselSlidesProps.slideProps.onSlideClick = openFullscreen;
  }

  return (
    <div className={classNames(
      className,
      styles.container,
      shouldOpenFullscreen && styles.withFullscreen,
      isFullscreen && styles.fullscreenContainer,
    )}
    >
      <Slides {...carouselSlidesProps} />
      {(hasSeveralSlides && !isFullscreen) && (
        <Dots
          activeSlideIndex={activeSlideIndex}
          changeSlide={changeSlide}
          count={items.length}
          {...dotsProps}
        />
      )}
      {hasSeveralSlides && (
        <Arrows
          goToNextSlide={goToNextSlide}
          goToPrevSlide={goToPrevSlide}
          {...arrowsProps}
        />
      )}
      {isFullscreen && (
        <>
          <div className={styles.closeButtonWrapper}>
            <button
              className={styles.closeButton}
              onClick={closeFullScreen}
              type="button"
            >
              <CloseIcon className={styles.closeButtonIcon} />
            </button>
          </div>
          <Thumbnails
            activeSlideIndex={activeSlideIndex}
            changeSlide={changeSlide}
            items={items}
          />
        </>
      )}
    </div>
  );
};

export default Carousel;
