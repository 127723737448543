import React, { FC, ReactNode, useState } from 'react';
import classNames from 'classnames';
import styles from './SocialWidget.module.scss';

export interface SocialWidgetProps {
  iconUrl: string;
  links: SocialLinkProps[];
}

interface SocialLinkProps {
  socialName: string;
  iconUrl: string;
  socialLink: string;
}

const SocialWidget: FC<SocialWidgetProps> = ({
  iconUrl,
  links,
}: SocialWidgetProps) => {
  const [isLinksVisible, setLinksVisibility] = useState<boolean>(false);

  const renderIcon = (url: string): ReactNode => (
    <div
      className={styles.icon}
      style={{ backgroundImage: `url(${url})` }}
    />
  );

  const renderSocialLink = ({
    socialName,
    socialLink,
    iconUrl: linkIconUrl,
  }: SocialLinkProps): ReactNode => (
    <a
      className={classNames(styles.socialLink, isLinksVisible && styles.socialLink_visible)}
      href={socialLink}
      title={socialName}
      target="_blank"
      rel="noopener noreferrer"
      key={socialLink}
    >
      {renderIcon(linkIconUrl)}
    </a>
  );

  const toggleLinksVisibility = (): void => {
    setLinksVisibility(!isLinksVisible);
  };

  return (
    <div className={styles.container}>
      {links.map(renderSocialLink)}
      <button
        className={classNames(styles.shareButton, isLinksVisible && styles.shareButton_active)}
        type="button"
        onClick={toggleLinksVisibility}
      >
        {renderIcon(iconUrl)}
      </button>
    </div>
  );
};

export default SocialWidget;
