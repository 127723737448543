import React, { FC } from 'react';
import { hasExternalForm } from '@/helpers/externalForm';
import { ModalName } from '@/common/contexts/globalModalContext';
import { ArticleBodyProps } from '@/common/layouts/ArticlePreviewLayout/types';
import { AuthorProps } from '@/common/components/Author/Author';
import ArticlePreviewLayout, {
  ArticlePreviewLayoutProps,
} from '@/common/layouts/ArticlePreviewLayout/ArticlePreviewLayout';
import ArticleContent from '@/common/components/ArticleContent/ArticleContent';
import { BannerProps } from '@/common/components/Banner/Banner';
import SidebarCard from '@/pages/ResearchArticlesPreviewPage/components/SidebarCard/SidebarCard';
import CustomReportModal, { CustomReportModalProps } from '@/common/components/CustomReportModal/CustomReportModal';
import ExternalModalForms from '@/common/components/Modal/ExternalModalForms/ExternalModalForms';

export interface ResearchArticlesPreviewPageProps extends ArticlePreviewLayoutProps {
  banner?: BannerProps;
  articleBody?: ArticleBodyProps;
  author?: AuthorProps;
  customInsightPopup?: CustomReportModalProps;
}

const ResearchArticlesPreviewPage: FC<ResearchArticlesPreviewPageProps> = ({
  banner,
  articleBody,
  author,
  customInsightPopup,
  pardot,
  ...articleLayoutProps
}) => {
  const sidebarContent = !!banner && <SidebarCard {...banner} />;

  const articlePreviewProps = {
    ...articleLayoutProps,
    sidebarContent,
    title: articleBody?.overlayText,
    date: articleBody?.title,
  };

  const renderModal = () => {
    if (!customInsightPopup || hasExternalForm({ pardot, modalName: ModalName.customReport })) {
      return null;
    }

    return (
      <CustomReportModal {...customInsightPopup} />
    );
  };

  return (
    <ArticlePreviewLayout {...articlePreviewProps}>
      <ArticleContent
        author={author}
        content={articleBody?.content}
        downloadButton={articleBody?.downloadButton}
      />
      {!!pardot && (
        <ExternalModalForms
          pardot={pardot}
          titles={{
            [ModalName.customReport]: customInsightPopup?.modalWindowTitle,
          }}
        />
      )}
      {renderModal()}
    </ArticlePreviewLayout>
  );
};

export default ResearchArticlesPreviewPage;
