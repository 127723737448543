import React, { FC, memo, useMemo } from 'react';
import classNames from 'classnames';
import { TabContentProps, CookieItem } from './types';
import styles from './CookieSettingsModal.module.scss';

const TabContent: FC<TabContentProps> = memo(({
  onToggle, commonLabels, content,
}: TabContentProps) => {
  const activityBlock = useMemo(() => {
    if (content.id === 'GeneralInfo') {
      return null;
    }

    if (content.alwaysActive) {
      return (
        <div className={styles.switchCheckBoxLabel}>{commonLabels.alwaysActive}</div>
      );
    }

    return (
      <>
        <label className={styles.switchCheckBoxLabel}>
          {content.active ? commonLabels.active : commonLabels.inActive}
        </label>
        <div
          className={styles.switchCheckBox}
          onClick={onToggle}
        />
      </>
    );
  }, [
    content.id,
    content.alwaysActive,
    content.active,
    onToggle,
    commonLabels.alwaysActive,
    commonLabels.active,
    commonLabels.inActive,
  ]);

  return (
    <div className={styles.modalTabContent}>
      <div className={styles.modalTabContentHeader}>
        <div
          className={styles.modalTabContentTitle}
          dangerouslySetInnerHTML={{ __html: content.title }}
        />

        <div
          className={classNames(styles.modalTabContentSwitchWrapper, content.active && styles.modalTabContentSwitchOn)}
        >
          {activityBlock}
        </div>
      </div>
      <div
        className={styles.modalTabContentDescription}
        dangerouslySetInnerHTML={{ __html: content.description || '' }}
      />
      {content.cookieList && (
        <>
          <div className={styles.modalTabContentCookiesListTitle}>{commonLabels.cookiesListTitle}</div>
          <ul className={styles.modalTabContentCookiesList}>
            {content.cookieList.map((cookie: CookieItem) => (
              <li key={cookie.name}>
                <a
                  className={styles.modalTabContentCookie}
                  href={cookie.link}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {cookie.name}
                </a>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
});

export default TabContent;
