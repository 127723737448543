import React, {
  FC, memo, ReactElement, useCallback, useEffect,
} from 'react';
import classNames from 'classnames';
import keys from 'weak-key';
import Breakpoints from '@/common/constants/breakpoints';
import lockPageScroll from '@/helpers/lockPageScroll';
import styles from './NavBar.module.scss';

interface NavLink {
  text: string;
  href: string;
  modalClass?: string;
  isTarget?: boolean;
  isActive: boolean;
}

export interface NavBarProps {
  newLabel: string;
  links: NavLink[];
}

interface NavBarComponentProps extends NavBarProps {
  isInverted: boolean;
  isMenuOpen: boolean;
  setIsMenuOpen: (menuState: boolean) => void;
  burgerClassName?: string;
}

const NavBar: FC<NavBarComponentProps> = memo(({
  links,
  isInverted,
  isMenuOpen,
  setIsMenuOpen,
  burgerClassName,
}: NavBarComponentProps) => {
  const toggleMenu = useCallback((menuState): void => {
    setIsMenuOpen(menuState);
    lockPageScroll(menuState);
  }, [setIsMenuOpen]);

  const onButtonClick = (): void => {
    toggleMenu(!isMenuOpen);
  };

  // in case of orientation change while mobile sidebar is opened
  const handleScroll = useCallback((): void => {
    if ((window.innerWidth >= Breakpoints.md) && isMenuOpen) {
      toggleMenu(false);
    }
  }, [isMenuOpen, toggleMenu]);

  useEffect(() => {
    window.addEventListener('resize', handleScroll);

    return (): void => {
      window.removeEventListener('resize', handleScroll);
    };
  }, [handleScroll]);

  return (
    <>
      <nav className={classNames(styles.navBar, !isMenuOpen && styles.navBarHidden)}>
        <ul className={styles.menu}>
          {links?.map((link: NavLink): ReactElement => (
            <li
              key={keys(link)}
              className={styles.menuItem}
            >
              <a
                href={link.href}
                title={link.text}
                {...(link.isTarget && { target: '_blank' })}
                className={classNames(
                  styles.menuLink,
                  link.isActive && styles.activeLink,
                  isInverted && styles.isInverted,
                )}
              >
                {link.text}
              </a>
            </li>
          ))}
        </ul>
      </nav>
      <button
        aria-label="Toggle menu"
        className={styles.hamburgerBtn}
        onClick={onButtonClick}
        type="button"
      >
        <div className={classNames(styles.hamburgerElement, burgerClassName, isMenuOpen && styles.isActive)} />
      </button>
    </>
  );
});

export default NavBar;
