import React, { FC } from 'react';
import { ModalName } from '@/common/contexts/globalModalContext';
import CustomRequestModal, { CustomRequestModalProps } from '@/common/components/CustomRequestModal/CustomRequestModal';

export type ManagedOfficesModalProps = CustomRequestModalProps;

const ManagedOfficesModal: FC<ManagedOfficesModalProps> = (
  props,
) => (
  <CustomRequestModal
    {...props}
    endpoint="managedoffices"
    modalName={ModalName.managedOffices}
  />
);

export default ManagedOfficesModal;
