import React, { FC } from 'react';
import classNames from 'classnames';
import SocialWidget, { SocialWidgetProps }
  from '@/common/layouts/ArticlePreviewLayout/components/BreadcrumbsSection/SocialWidget/SocialWidget';
import { ReactComponent as ChevronIcon } from '@/assets/icons/chevron.svg';
import grid from '@/common/css/grid.module.scss';
import styles from './BreadcrumbsSection.module.scss';

export interface BreadcrumbsSectionProps {
  backToListingButton?: {
    text: string;
    href: string;
  } | null;
  socialLinksBlock?: SocialWidgetProps;
}

const BreadcrumbsSection: FC<BreadcrumbsSectionProps> = ({
  backToListingButton,
  socialLinksBlock,
}: BreadcrumbsSectionProps) => (
  <div className={classNames(grid.container, styles.container)}>
    {!!backToListingButton && (
      <a className={styles.listingLink} href={backToListingButton.href}>
        <ChevronIcon className={styles.linkChevron} />
        {backToListingButton.text}
      </a>
    )}
    {!!socialLinksBlock && (
      <SocialWidget {...socialLinksBlock} />
    )}
  </div>
);

export default BreadcrumbsSection;
