import React, {
  FC, memo, ReactElement,
} from 'react';
import { MasterLayoutProps } from '@/common/layouts/MasterLayout/types';
import MasterLayout from '@/common/layouts/MasterLayout/MasterLayout';
import LoginModal, { LoginModalProps } from '@/common/layouts/ProtectedLayout/components/LoginModal/LoginModal';
import styles from './ProtectedLayout.module.scss';

export interface ProtectedProps {
  loginModal?: LoginModalProps;
  isAuthorized?: boolean;
}

interface ProtectedLayoutProps extends ProtectedProps {
  masterLayoutProps: MasterLayoutProps;
  children: ReactElement;
}

const ProtectedLayout: FC<ProtectedLayoutProps> = memo(({
  loginModal,
  isAuthorized,
  children,
  masterLayoutProps,
}: ProtectedLayoutProps) => {
  if (isAuthorized) {
    return children;
  }

  const { options: gdprDataOptions, gald: gaId } = masterLayoutProps?.gdprData || {};

  const gdprOptions = {
    allowABTestingCookies: gdprDataOptions ? gdprDataOptions.allowABTestingCookies : true,
    allowAdvertisingCookies: gdprDataOptions ? gdprDataOptions.allowAdvertisingCookies : true,
    allowFunctionalCookies: gdprDataOptions ? gdprDataOptions.allowFunctionalCookies : true,
    allowPerformanceCookies: gdprDataOptions ? gdprDataOptions.allowPerformanceCookies : true,
    id: gdprDataOptions?.id || '',
    gaId,
  };

  return (
    <div className={styles.container}>
      <MasterLayout {...masterLayoutProps}>
        {!!loginModal && <LoginModal {...loginModal} isAuthorized={isAuthorized} gdprOptions={gdprOptions} />}
      </MasterLayout>
    </div>
  );
});

export default ProtectedLayout;
