import React, { FC, useEffect, useRef } from 'react';
import styles from './ServerErrorMessage.module.scss';

interface ServerErrorMessageProps {
  hasError: boolean;
  errorMessage: string;
}

const ServerErrorMessage: FC<ServerErrorMessageProps> = ({
  hasError, errorMessage,
}: ServerErrorMessageProps) => {
  const messageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!messageRef.current) {
      return;
    }

    messageRef.current.scrollIntoView();
  });

  if (!hasError) {
    return null;
  }

  return (
    <div className={styles.serverError} ref={messageRef}>
      {errorMessage}
    </div>
  );
};

export default ServerErrorMessage;
