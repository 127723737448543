import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { RequiredValidator, ValidatorType } from '@/helpers/validation';
import { FormState, useForm, ValidationSchema } from '@/common/hooks/useForm';
import { login } from '@/api/login';
import { GDPROptions } from '@/common/layouts/ProtectedLayout/components/LoginModal/LoginForm/types';
import ServerErrorMessage from '@/common/components/Form/ServerErrorMessage/ServerErrorMessage';
import Password from '@/common/components/Form/Password/Password';
import Checkbox from '@/common/components/Form/Checkbox/Checkbox';
import Captcha from '@/common/components/Form/Captcha/Captcha';
import PrivacyText from '@/common/components/Form/PrivacyText/PrivacyText';
import {
  checkUserLock,
  redirectToHomePage,
  refreshPage,
  updateCookieSettings,
} from '@/common/layouts/ProtectedLayout/components/LoginModal/LoginForm/helpers';
import Button from '@/common/components/Button/Button';
import commonFormStyles from '@/common/css/modalFormsCommon.module.scss';
import styles from './LoginForm.module.scss';

export interface LoginFormProps {
  passwordLabel: string;
  rememberMeLabel: string;
  privacyAndPolicy: string;
  submitButtonText: string;
  captchaSiteKey: string;
  captchaErrorMessage: string;
  requiredErrorMessage: string;
  serverErrorMessage: string;
  functionalRememberMessage: string;
  cookieButtonLabel: string;
  noThanksButtonLabel: string;
  userLockedError: string;
  isoCode?: string;
}

interface LoginFormComponentProps extends LoginFormProps {
  gdprOptions: GDPROptions;
}

const gRecaptchaResponseFieldName = 'gRecaptchaResponse';

const LoginForm: FC<LoginFormComponentProps> = ({
  passwordLabel,
  rememberMeLabel,
  privacyAndPolicy,
  submitButtonText,
  captchaSiteKey,
  captchaErrorMessage,
  requiredErrorMessage,
  serverErrorMessage,
  functionalRememberMessage,
  cookieButtonLabel,
  noThanksButtonLabel,
  userLockedError,
  isoCode,
  gdprOptions,
}) => {
  const initialState: FormState = {
    password: { value: '', isValid: true, errorMessage: '' },
    isRememberMe: { value: '', isValid: true, errorMessage: '' },
    gRecaptchaResponse: { value: '', isValid: true, errorMessage: '' },
  };

  const validationSchema: ValidationSchema = {
    password: [
      {
        type: ValidatorType.REQUIRED,
        isRequired: true,
        errorMessage: requiredErrorMessage,
      } as RequiredValidator,
    ],
    gRecaptchaResponse: [
      {
        type: ValidatorType.REQUIRED,
        isRequired: true,
        errorMessage: captchaErrorMessage,
      } as RequiredValidator,
    ],
  };

  const [
    state, handleFieldChange, handleFormSubmit,
  ] = useForm(initialState, validationSchema);

  const {
    password,
    isRememberMe,
    gRecaptchaResponse,
  } = state;

  const [errorMessage, setErrorMessage] = useState('');
  const [isInProcess, setIsInProcess] = useState(false);
  const [isUserBlocked, setIsUserBlocked] = useState(false);
  const [shouldDisplayCookiesMessage, setShouldDisplayCookiesMessage] = useState(false);

  const submitErrorHandler = (text?: string): void => {
    setIsInProcess(false);

    const newErrorMessage = text?.replace('{}', '5') || serverErrorMessage;
    setErrorMessage(newErrorMessage);

    if (checkUserLock()) {
      setIsUserBlocked(true);
      redirectToHomePage();
    }
  };

  const submitSuccessHandler = (): void => {
    setIsInProcess(false);

    if (!isRememberMe.value || gdprOptions.allowFunctionalCookies) {
      refreshPage();

      return;
    }

    setShouldDisplayCookiesMessage(true);
  };

  const fieldChangeHandler = (fieldName: string, value: string): void => {
    setErrorMessage('');
    handleFieldChange(fieldName, value);
  };

  const onCaptchaChange = (token: string | null): void => {
    const gRecaptchaToken = token ?? '';
    handleFieldChange(gRecaptchaResponseFieldName, gRecaptchaToken);
  };

  const submitHandler = (): void => {
    if (isUserBlocked) {
      return;
    }

    setIsInProcess(true);
    setErrorMessage('');

    login(
      {
        password: password.value,
        isRememberMe: !!isRememberMe.value && gdprOptions.allowFunctionalCookies,
        gRecaptchaResponse: gRecaptchaResponse.value,
      },
      submitSuccessHandler,
      submitErrorHandler,
    );
  };

  useEffect(() => {
    if (checkUserLock()) {
      setErrorMessage(userLockedError);
      setIsUserBlocked(true);
    }
  }, [userLockedError]);

  if (shouldDisplayCookiesMessage) {
    return (
      <div
        className={classNames(commonFormStyles.fieldset, styles.mainFieldset)}
      >
        <div className={styles.warningMessage}>
          {functionalRememberMessage}
        </div>
        <div className={styles.warningButtonsContainer}>
          <Button
            customClassNames={{ button: styles.acceptButton }}
            onClick={(): void => updateCookieSettings(gdprOptions)}
            text={cookieButtonLabel}
          />
          <Button
            customClassNames={{ button: styles.declineButton }}
            onClick={refreshPage}
            text={noThanksButtonLabel}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <ServerErrorMessage
        errorMessage={errorMessage}
        hasError={!!errorMessage}
      />
      <div
        className={classNames(commonFormStyles.fieldset, styles.mainFieldset, isUserBlocked && styles.lockedContainer)}
      >
        <Password
          errorMessage={password.errorMessage}
          isValid={password.isValid}
          onChange={(value: string): void => fieldChangeHandler('password', value)}
          placeholder={passwordLabel}
          value={password.value}
        />

        <div className={classNames(styles.rememberMeField)}>
          <Checkbox
            label={rememberMeLabel}
            onChange={(value: string): void => fieldChangeHandler('isRememberMe', value)}
            value={isRememberMe?.value}
          />
        </div>
      </div>

      <div className={classNames(commonFormStyles.captchaAndSubmitFieldset, isUserBlocked && styles.lockedContainer)}>
        <Captcha
          errorMessage={gRecaptchaResponse.errorMessage}
          isoCode={isoCode}
          isValid={gRecaptchaResponse.isValid}
          onChange={onCaptchaChange}
          onErrored={(): void => onCaptchaChange('')}
          onExpired={(): void => onCaptchaChange('')}
          siteKey={captchaSiteKey}
        />

        <PrivacyText text={privacyAndPolicy} />

        <Button
          customClassNames={{ button: commonFormStyles.submitButton }}
          isDisabled={isInProcess}
          onClick={(): void => handleFormSubmit(submitHandler)}
          text={submitButtonText}
        />
      </div>
    </>
  );
};

export default LoginForm;
