import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import { ImagesClassNames, ImagesProps, SharedImagesProps } from '@/common/components/Products/types';
import ProductCards from '@/common/components/Products/ProductCards/ProductCards';
import SmallImageBlock from '@/common/components/Products/SmallImageBlock/SmallImageBlock';
import LargeImageBlock from '@/common/components/Products/LargeImageBlock/LargeImageBlock';
import grid from '@/common/css/grid.module.scss';
import styles from './Images.module.scss';

interface ImagesComponentProps extends ImagesProps, SharedImagesProps {
  customClassNames?: ImagesClassNames;
}

const Images: FC<ImagesComponentProps> = ({
  customClassNames,
  isReversed,
  smallImageViewName,
  isImagesFullWidthMobile,
  isImagesNotFullWidthDesktop,
  smallContentBlock,
  additionalContents,
  officeCards,
  images,
  backgroundImageCrops,
  imageCrops,
}: ImagesComponentProps) => {
  const content = useMemo(() => {
    if (officeCards?.length) {
      return <ProductCards cards={officeCards} />;
    }

    return (
      <>
        <LargeImageBlock
          additionalContent={additionalContents?.[0]}
          className={customClassNames?.large}
          images={backgroundImageCrops || images || imageCrops}
        />
        <SmallImageBlock
          additionalContent={additionalContents?.[0]}
          className={customClassNames?.small}
          isReversed={isReversed}
          smallContentBlock={smallContentBlock}
          smallImageViewName={smallImageViewName}
        />
      </>
    );
  }, [
    officeCards,
    backgroundImageCrops,
    images,
    imageCrops,
    additionalContents,
    smallContentBlock,
    smallImageViewName,
    customClassNames,
    isReversed,
  ]);

  const hasCards = !!officeCards?.length;

  const imagesBlockClasses = {
    [styles.imagesContainer]: !hasCards,
    [styles.cardsContainer]: hasCards,
    [grid.col10]: !isImagesFullWidthMobile,
    [grid.colSm6]: true,
    [grid.colMd5]: isImagesNotFullWidthDesktop,
    [grid.colMd6]: !isImagesNotFullWidthDesktop,
    [grid.offsetMd1]: isImagesNotFullWidthDesktop,
  };

  return (
    <div className={classNames(imagesBlockClasses, customClassNames?.container)}>
      {content}
    </div>
  );
};

export default Images;
