import React, { FC, memo, useCallback } from 'react';
import { ModalName, useToggleModalsContext } from '@/common/contexts/globalModalContext';
import ModalFrame from '@/common/layouts/MasterLayout/components/ModalFrame/ModalFrame';
import styles from './CommunicationTermsPopup.module.scss';

export interface CommunicationTermsProps {
  headerText: string;
  headerImageUrl: string;
  lightBoxContent: string;
  closeBtnText: string;
}

const CommunicationTermsPopup: FC<CommunicationTermsProps> = memo(({
  headerText,
  headerImageUrl,
  lightBoxContent,
  closeBtnText,
}: CommunicationTermsProps) => {
  const toggleModal = useToggleModalsContext();

  const onCloseModal = useCallback(() => {
    toggleModal({ [ModalName.communicationTerms]: false });
  }, [toggleModal]);

  return (
    <ModalFrame
      logoUrl={headerImageUrl}
      modalName={ModalName.communicationTerms}
      title={headerText}
    >
      <div className={styles.content}>
        <div
          className={styles.text}
          dangerouslySetInnerHTML={{ __html: lightBoxContent }}
        />
        <button className={styles.closeButton} onClick={onCloseModal} type="button">
          {closeBtnText}
        </button>
      </div>
    </ModalFrame>
  );
});

export default CommunicationTermsPopup;
