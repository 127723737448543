import { RefObject, useCallback, useEffect } from 'react';
import { getHeaderHeight } from '@/helpers/domElements';

const useScrollPosition = (
  itemRef?: RefObject<HTMLDivElement> | null,
): void => {
  const handleScrollPosition = useCallback((): void => {
    if (!itemRef || !itemRef.current) {
      return;
    }

    const currentPathname = window.location.pathname;
    const currentUrl = window.location.href;
    const previousUrl = document.referrer || '';
    if (!previousUrl.includes(currentPathname) || (currentUrl === previousUrl)) {
      return;
    }

    const itemTop = itemRef.current.getBoundingClientRect().top;

    if (itemTop) {
      const headerHeight = getHeaderHeight();

      window.scrollBy(0, itemTop - headerHeight);
    }
  }, [itemRef]);

  useEffect((): void => {
    setTimeout(handleScrollPosition, 0);
  }, [handleScrollPosition]);
};

export default useScrollPosition;
