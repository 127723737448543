import React, {
  FC, memo, useCallback, useState,
} from 'react';
import classNames from 'classnames';
import keys from 'weak-key';
import {
  autoUpdate, flip, offset, useClick, useDismiss, useFloating, useInteractions,
} from '@floating-ui/react';
import { ReactComponent as ArrowBoldIcon } from '@/assets/icons/arrowBold.svg';
import { ReactComponent as TickIcon } from '@/assets/icons/tick.svg';
import styles from './LangSwitcher.module.scss';

export interface LangSwitcherProps {
  footerLanguages: LangSwitcherItem[];
}

interface LangSwitcherItem {
  text: string;
  newLang: string | null;
  isActive?: boolean;
}

const offsetValue = -42;

const getLanguageUrl = (language: string): string => {
  const { pathname, search } = window.location;
  const searchParams = new URLSearchParams(search);

  searchParams.set('newLang', language);

  return `${pathname}?${searchParams.toString()}`;
};

const LangSwitcher: FC<LangSwitcherProps> = ({
  footerLanguages: items,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = useCallback((): void => {
    setIsDropdownOpen((prevValue) => !prevValue);
  }, []);

  const {
    x,
    y,
    reference,
    floating,
    strategy,
    context,
  } = useFloating({
    open: isDropdownOpen,
    onOpenChange: toggleDropdown,
    middleware: [
      offset(offsetValue),
      flip(),
    ],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);

  const { getFloatingProps } = useInteractions([
    click,
    dismiss,
  ]);

  const langActiveLabel = items?.find((item: LangSwitcherItem) => item.isActive === true);

  return (
    <div
      ref={reference}
      className={styles.container}
    >
      <button
        className={styles.button}
        onClick={toggleDropdown}
        type="button"
      >
        {langActiveLabel?.text}
        <ArrowBoldIcon className={styles.arrowIcon} />
      </button>
      {isDropdownOpen && (
        <ul
          ref={floating}
          className={classNames(styles.dropdown, isDropdownOpen && styles.isOpen)}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
          }}
          {...getFloatingProps()}
        >
          {items?.map((item: LangSwitcherItem) => (
            <li key={keys(item)}>
              <a
                className={classNames(styles.dropdownItem, item.isActive && styles.isSelected)}
                {...(item.newLang && { href: getLanguageUrl(item.newLang) })}
              >
                {item.text}
                {item.isActive && (
                  <TickIcon className={styles.tickIcon} />
                )}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
LangSwitcher.displayName = 'LangSwitcher';

export default memo(LangSwitcher);
