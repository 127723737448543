import React, { FC } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import ErrorMessage from '@/common/components/Form/ErrorMessage/ErrorMessage';
import styles from './Captcha.module.scss';

interface CaptchaProps {
  siteKey: string;
  isoCode?: string;
  isValid?: boolean;
  errorMessage?: string;
  onChange?: (value: string) => void;
  onExpired?: () => void;
  onErrored?: () => void;
}

const Captcha: FC<CaptchaProps> = ({
  siteKey,
  isoCode,
  isValid,
  errorMessage,
  onChange,
  onExpired,
  onErrored,
}: CaptchaProps) => {
  if (!siteKey) {
    return null;
  }

  return (
    <div className={styles.reCaptchaContainer}>
      <ReCAPTCHA
        hl={isoCode}
        onChange={onChange}
        onErrored={onErrored}
        onExpired={onExpired}
        sitekey={siteKey}
        size="normal"
      />
      {!isValid && <ErrorMessage title={errorMessage} />}
    </div>
  );
};

export default Captcha;
