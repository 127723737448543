import React, {
  createContext, FC, ReactNode, RefObject, useMemo, useState,
} from 'react';
import { getHeaderHeight } from '@/helpers/domElements';

interface ScrollIntoViewProviderProps { children: ReactNode }

interface ScrollIntoViewContextValue {
  scrollIntoView: (viewKey: string) => void;
  addRef: (viewKey: string, ref: RefObject<HTMLElement>) => void;
}

export const ScrollIntoViewContext = createContext<ScrollIntoViewContextValue>({
  addRef: () => {},
  scrollIntoView: () => {},
});

const ScrollIntoViewProvider: FC<ScrollIntoViewProviderProps> = ({
  children,
}: ScrollIntoViewProviderProps) => {
  const [viewRefs, setViewRefs] = useState<{ [viewKey: string]: RefObject<HTMLElement> | undefined }>({});
  const value: ScrollIntoViewContextValue = useMemo(() => ({
    addRef: (viewKey, ref): void => {
      setViewRefs((prevState) => ({ ...prevState, [viewKey]: ref }));
    },
    scrollIntoView: (viewKey): void => {
      const currentRef = viewRefs[viewKey];
      if (!currentRef || !currentRef.current) {
        return;
      }

      const headerHeight = getHeaderHeight();
      const scrollPosition = currentRef.current.getBoundingClientRect().top - headerHeight;

      window.scrollBy(0, scrollPosition);
    },
  }), [viewRefs]);

  return (
    <ScrollIntoViewContext.Provider value={value}>
      {children}
    </ScrollIntoViewContext.Provider>
  );
};

export default ScrollIntoViewProvider;
