import React, {
  FC, SyntheticEvent, useCallback, useEffect, useMemo, useState,
} from 'react';
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/respimg/ls.respimg';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import classNames from 'classnames';
import { ResponsiveImageUrls } from '@/types';
import styles from './ResponsiveImage.module.scss';

interface ResponsiveImageProps {
  urls: ResponsiveImageUrls;
  placeholderUrl?: string;
  className?: string;
  alt?: string;
  title?: string;
  shouldRegardDPR?: boolean;
  additionalAttributes?: {
    [key: string]: string;
  };
}

const areImagesBroken = {};

const ResponsiveImage: FC<ResponsiveImageProps> = ({
  urls,
  placeholderUrl,
  className,
  alt = '',
  title = '',
  shouldRegardDPR,
  additionalAttributes,
}) => {
  const [srcSet, setSrcSet] = useState<string>('');
  const [isHidden, setIsHidden] = useState(true);
  const [areImagesPrepared, setAreImagesPrepared] = useState(false); // IE fix

  const getValidImageSizes = useCallback((): string[] => {
    const imageSizes: string[] = [];
    Object.keys(urls).forEach((size) => {
      const imageBySize = urls[size];

      if (imageBySize && !areImagesBroken[imageBySize]) {
        imageSizes.push(size);
      }
    });

    return imageSizes;
  }, [urls]);

  const updateSrcSet = useCallback((): void => {
    const dpr = shouldRegardDPR ? 1 : window.devicePixelRatio;
    const imageSizes = getValidImageSizes();

    const newSrcSet = imageSizes.reduce((srcset, size) => {
      const imageUrl = urls[size];
      const imageSize = Math.round(Number(size) * dpr);

      if (Number.isNaN(imageSize)) {
        return srcset;
      }

      return `${srcset ? `${srcset}, ` : ''}${imageUrl} ${imageSize}w`;
    }, '');

    setSrcSet(newSrcSet);
    setAreImagesPrepared(true);
  }, [getValidImageSizes, urls]);

  const handleError = useCallback((error: SyntheticEvent): void => {
    const errorTarget = error?.target as HTMLImageElement;
    if (!errorTarget) {
      return;
    }

    setIsHidden(true);
    areImagesBroken[errorTarget.currentSrc] = true;

    updateSrcSet();
  }, [updateSrcSet]);

  const handleLoad = (): void => {
    setIsHidden(false);
  };

  useEffect(() => {
    updateSrcSet();
  }, [updateSrcSet]);

  return useMemo(() => {
    if (!areImagesPrepared || !(srcSet || placeholderUrl)) {
      return null;
    }

    return (
      <img
        alt={alt}
        className={classNames(styles.image, isHidden && styles.isHidden, className, 'lazyload')}
        data-sizes="auto"
        data-srcset={srcSet || placeholderUrl}
        onError={handleError}
        onLoad={handleLoad}
        title={title}
        {...additionalAttributes}
      />
    );
  }, [
    className, alt, title, srcSet, placeholderUrl, areImagesPrepared, isHidden, handleError, additionalAttributes,
  ]);
};

export default ResponsiveImage;
