import React, { FC, memo, MouseEvent } from 'react';
import classNames from 'classnames';
import useScroll from '@/common/hooks/useScroll';
import styles from './BackToTop.module.scss';

export interface BackToTopButtonProps {
  shouldBeHiddenInViewport?: {
    mobile?: boolean;
    tablet?: boolean;
  };
}

const minScrollOffset = 100;

const BackToTop: FC<BackToTopButtonProps> = memo(({
  shouldBeHiddenInViewport,
}: BackToTopButtonProps) => {
  const isScrolled = useScroll(minScrollOffset);

  const scrollToTop = (e: MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    window.scrollTo(0, 0);
  };

  const buttonClassNames = {
    [styles.button]: true,
    [styles.isVisible]: isScrolled,
    [styles.isHiddenOnMobile]: shouldBeHiddenInViewport?.mobile,
    [styles.isHiddenOnTablet]: shouldBeHiddenInViewport?.tablet,
  };

  return (
    <div
      className={classNames(buttonClassNames)}
      onClick={scrollToTop}
    />
  );
});

export default BackToTop;
