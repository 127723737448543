import React, { FC, memo, ReactNode } from 'react';
import classNames from 'classnames';
import { ReactComponent as CloseIcon } from '@/assets/icons/close.svg';
import styles from './ModalLayout.module.scss';

export interface ModalLayoutProps {
  modalWindowTitle?: string;
  closeHandler?: () => void;
}

interface ModalLayoutComponentProps extends ModalLayoutProps {
  isSingleColumn?: boolean;
  customClassNames?: {
    container?: string;
    title?: string;
    closeButton?: string;
  };
  children?: ReactNode;
}

const ModalLayout: FC<ModalLayoutComponentProps> = ({
  modalWindowTitle,
  isSingleColumn,
  children,
  closeHandler,
  customClassNames,
}: ModalLayoutComponentProps) => (
  <div className={styles.overlay}>
    <div className={classNames(
      styles.container,
      isSingleColumn && styles.containerSingleColumn,
      customClassNames?.container,
    )}
    >
      <div className={classNames(customClassNames?.title, styles.title)}>
        { modalWindowTitle }
        <button
          className={classNames(customClassNames?.closeButton, styles.closeButton)}
          onClick={closeHandler}
          type="button"
        >
          <CloseIcon />
        </button>
      </div>
      { children }
    </div>
  </div>
);
ModalLayout.displayName = 'ModalLayout';

export default memo(ModalLayout);
