import { useCallback, useEffect, useState } from 'react';

const useScroll = (minScrollOffset: number): boolean => {
  const [isScrolled, setIsScrolled] = useState(false);

  const checkScroll = useCallback((): void => {
    const scrollOffset = window.pageYOffset;
    setIsScrolled(scrollOffset > minScrollOffset);
  }, [minScrollOffset]);

  useEffect((): () => void => {
    checkScroll();

    window.addEventListener('scroll', checkScroll);

    return (): void => {
      window.removeEventListener('scroll', checkScroll);
    };
  }, [checkScroll]);

  return isScrolled;
};

export default useScroll;
