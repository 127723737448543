import { RefObject, useEffect, useState } from 'react';

const itemOffset = 10;

const useTranslation = (
  containerRef: RefObject<HTMLElement>,
  activeItemRef: RefObject<HTMLElement>,
  dependencies: any[],
): number => {
  const [translation, setTranslation] = useState(0);

  let translationTimeoutId: number | undefined;

  const translate = (): void => {
    if (!containerRef || !activeItemRef) {
      return;
    }

    const containerElement = containerRef.current;
    const activeItemElement = activeItemRef.current;

    if (!containerElement || !activeItemElement) {
      return;
    }

    const { width: containerVisibleWidth, left: containerPosition } = containerElement.getBoundingClientRect();
    const containerWidth = containerElement.scrollWidth;

    if (!containerWidth || !containerVisibleWidth || (containerVisibleWidth >= containerWidth)) {
      if (translation) {
        setTranslation(0);
      }

      return;
    }

    const {
      width: activeItemWidth, left: activeItemPosition,
    } = activeItemElement.getBoundingClientRect();
    const activeItemRelativePosition = activeItemPosition - containerPosition;

    const minVisiblePosition = activeItemRelativePosition - activeItemWidth - itemOffset * 2;

    if (minVisiblePosition < 0) {
      const newPosition = translation - minVisiblePosition;

      setTranslation(Math.min(newPosition, 0));

      return;
    }

    const maxVisiblePosition = activeItemRelativePosition + (activeItemWidth + itemOffset) * 2 - containerVisibleWidth;
    const maxContainerPositionOffset = containerWidth - containerVisibleWidth;

    if (maxVisiblePosition > 0) {
      const newPosition = translation - maxVisiblePosition;

      setTranslation(Math.max(newPosition, -maxContainerPositionOffset));

      return;
    }

    if (translation > 0) {
      setTranslation(0);

      return;
    }

    if (translation < -maxContainerPositionOffset) {
      setTranslation(-maxContainerPositionOffset);
    }
  };

  const updateTranslation = (): void => {
    if (translationTimeoutId) {
      clearTimeout(translationTimeoutId);
    }

    translationTimeoutId = window.setTimeout(translate, 100);
  };

  useEffect(() => {
    updateTranslation();
  }, dependencies);

  useEffect(() => {
    window.addEventListener('resize', updateTranslation);

    return (): void => {
      window.removeEventListener('resize', updateTranslation);
    };
  });

  return translation;
};

export default useTranslation;
