import React, { FC, memo } from 'react';
import ModalsProvider from '@/common/contexts/globalModalContext';
import { MasterLayoutProps } from '@/common/layouts/MasterLayout/types';
import Header from '@/common/layouts/MasterLayout/components/Header/Header';
import CommunicationTermsPopup
  from '@/common/layouts/MasterLayout/components/CommunicationTermsPopup/CommunicationTermsPopup';
import CookieSettingsBar from '@/common/layouts/MasterLayout/components/CookiesSettingsBar/CookiesSettingsBar';
import CookieSettingsModal from '@/common/layouts/MasterLayout/components/CookieSettingsModal/CookieSettingsModal';
import BackToTop from '@/common/layouts/MasterLayout/components/BackToTop/BackToTop';
import Footer from '@/common/layouts/MasterLayout/components/Footer/Footer';
import '@/common/css';

const MasterLayout: FC<MasterLayoutProps> = memo(({
  header,
  customHeaderProps,
  communicationTerms,
  cookiesConsentBar,
  gdprData,
  children,
  backToTopButtonProps,
  footer,
  warningBar,
}: MasterLayoutProps) => (
  <ModalsProvider>
    {!!header && (
      <Header {...header} {...customHeaderProps} warningBar={warningBar} />
    )}

    {children}

    {!!communicationTerms && (
      <CommunicationTermsPopup {...communicationTerms} />
    )}
    {!!gdprData && (
      <CookieSettingsModal gdprData={gdprData} />
    )}
    {!!cookiesConsentBar && (
      <CookieSettingsBar {...cookiesConsentBar} />
    )}
    {!!footer && (
      <Footer {...footer} />
    )}
    <BackToTop {...backToTopButtonProps} />
  </ModalsProvider>
));

export default MasterLayout;
