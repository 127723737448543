import React, {
  FC, memo, ReactNode, useCallback, useMemo, useState,
} from 'react';
import classNames from 'classnames';
import key from 'weak-key';
import submitForm from '@/api/submitForm';
import {
  RequiredValidator, validate, Validator, ValidatorType,
} from '@/helpers/validation';
import ServerErrorMessage from '@/common/components/Form/ServerErrorMessage/ServerErrorMessage';
import Captcha from '@/common/components/Form/Captcha/Captcha';
import PrivacyText from '@/common/components/Form/PrivacyText/PrivacyText';
import Password from '@/common/components/Form/Password/Password';
import TextArea from '@/common/components/Form/TextArea/TextArea';
import Select from '@/common/components/Form/Select/Select';
import Input from '@/common/components/Form/Input/Input';
import {
  FieldType,
  FormColumn,
  FormField,
  FormProps,
  FormState,
} from '@/common/components/Modal/ModalForm/ModalForm.types';
import Button from '@/common/components/Button/Button';
import styles from './Form.module.scss';

const gRecaptchaResponseFieldName = 'gRecaptchaResponse';

const Form: FC<FormProps> = ({
  columns,
  mainFieldsetHeadline,
  endpoint,
  privacyAndPolicy,
  submitRequestButtonText,
  serverErrorMessage,
  captchaSiteKey,
  captchaErrorMessage,
  subtitleSection,
  onSubmitSuccess,
  isoCode,
  getAdditionalParams,
}) => {
  const isSingleColumn = columns.length === 1;
  const fields: FormField[] = useMemo(() => {
    const columnsArray = columns.map((column: FormColumn) => column.fields);
    const fieldsCaptchaOnly: FormField[] = [
      {
        name: gRecaptchaResponseFieldName,
        fieldType: FieldType.CAPTCHA,
        validationSchema: [
          {
            type: ValidatorType.REQUIRED,
            isRequired: true,
            errorMessage: captchaErrorMessage,
          } as RequiredValidator,
        ],
        fieldData: null,
      },
    ];

    return fieldsCaptchaOnly.concat(...columnsArray);
  }, [captchaErrorMessage, columns]);

  const initialState: FormState = useMemo(() => {
    const state: FormState = {};

    const initValue = ({ fieldType, fieldData }: FormField) => {
      if (fieldType === FieldType.SELECT) {
        return fieldData.defaultValue || '';
      }

      return '';
    };

    fields.forEach((field: FormField) => {
      const { name } = field;

      state[name] = {
        value: initValue(field),
        isValid: true,
        errorMessage: '',
      };
    });

    return state;
  }, [fields]);

  const formValidationSchema: { [name: string]: Validator[] } = useMemo(() => {
    const schema = {};

    fields.forEach(({ name, validationSchema }: FormField) => {
      schema[name] = validationSchema;
    });

    return schema;
  }, [fields]);

  const [state, setState] = useState<FormState>(initialState);
  const [hasServerError, setServerErrorState] = useState(false);
  const [isInProcess, setIsInProcess] = useState(false);

  const handleFieldChange = (name: string, value: string): void => {
    setServerErrorState(false);
    setState((prevState: FormState) => ({
      ...prevState,
      [name]: { value, isValid: true, errorMessage: '' },
    }));
  };

  const handleFormSubmit = useCallback((onSubmit: () => void): void => {
    let hasError = false;

    const validateField = (name: string): void => {
      const fieldSchema = formValidationSchema[name];
      const field = state[name];

      if (!field || !fieldSchema || !fieldSchema.length) {
        return;
      }

      const { value } = field;

      const { isValid, errorMessage } = validate(value, fieldSchema);

      setState((prevState: FormState) => ({
        ...prevState,
        [name]: { value, isValid, errorMessage },
      }));

      if (!isValid) {
        hasError = true;
      }
    };

    Object.keys(state).map(validateField);

    if (hasError) {
      return;
    }

    onSubmit();
  }, [
    state,
    formValidationSchema,
  ]);

  const renderField = (field: FormField): ReactNode => {
    const { name, fieldType, fieldData } = field;

    const fieldProps = {
      ...state[name],
      ...fieldData,
      onChange: (value: string): void => handleFieldChange(name, value),
    };

    let FieldComponent;

    switch (fieldType) {
      case FieldType.PASSWORD:
        FieldComponent = Password;
        break;
      case FieldType.TEXT_AREA:
        FieldComponent = TextArea;
        break;
      case FieldType.SELECT:
        FieldComponent = Select;
        break;
      case FieldType.INPUT:
        FieldComponent = Input;
        break;
      case FieldType.CAPTCHA:
      default:
        return null;
    }

    return <FieldComponent key={key(field)} {...fieldProps} />;
  };

  const onCaptchaChange = (token: string | null): void => {
    const gRecaptchaToken = token ?? '';
    handleFieldChange(gRecaptchaResponseFieldName, gRecaptchaToken);
  };

  const renderColumn = (column: FormColumn): ReactNode => (
    <div key={key(column)} className={styles.column}>
      {column.fields.map(renderField)}
    </div>
  );

  const submitErrorHandler = (): void => {
    setIsInProcess(false);
    setServerErrorState(true);
  };

  const submitHandler = useCallback((): void => {
    setIsInProcess(true);
    setServerErrorState(false);

    const submitSuccessHandler = (): void => {
      setIsInProcess(false);

      if (!onSubmitSuccess) {
        return;
      }

      onSubmitSuccess();
    };

    const body = {};
    Object.keys(state).forEach((name: string) => {
      body[name] = state[name].value;
    });

    const additionalParams = getAdditionalParams && getAdditionalParams();

    submitForm(
      endpoint,
      { ...body, ...additionalParams },
      submitSuccessHandler,
      submitErrorHandler,
    );
  }, [
    getAdditionalParams, endpoint, onSubmitSuccess, state,
  ]);

  return (
    <>
      <ServerErrorMessage
        errorMessage={serverErrorMessage}
        hasError={hasServerError}
      />

      {!!subtitleSection && (
        <div className={styles.subtitleSection}>
          {subtitleSection}
        </div>
      )}

      {!!mainFieldsetHeadline && (
      <div className={styles.mainFieldsetHeadline}>
        {mainFieldsetHeadline}
      </div>
      )}

      <div className={styles.fieldsContainer}>
        {columns.map(renderColumn)}
      </div>

      <div className={classNames(
        styles.captchaAndSubmitContainer,
        isSingleColumn && styles.captchaAndSubmitContainerSingleColumn,
      )}
      >
        <Captcha
          errorMessage={state[gRecaptchaResponseFieldName].errorMessage}
          isoCode={isoCode}
          isValid={state[gRecaptchaResponseFieldName].isValid}
          onChange={onCaptchaChange}
          onErrored={(): void => onCaptchaChange('')}
          onExpired={(): void => onCaptchaChange('')}
          siteKey={captchaSiteKey}
        />

        <PrivacyText text={privacyAndPolicy} />

        <Button
          customClassNames={{ button: styles.submitButton }}
          isDisabled={isInProcess}
          onClick={(): void => handleFormSubmit(submitHandler)}
          text={submitRequestButtonText}
        />
      </div>
    </>
  );
};
Form.displayName = 'Form';

export default memo(Form);
