import React, { FC } from 'react';
import key from 'weak-key';
import { ProductCard } from '@/common/components/Products/types';
import ResponsiveImage from '@/common/components/ResponsiveImage/ResponsiveImage';
import styles from './ProductCards.module.scss';

export interface ProductCardsProps {
  cards: ProductCard[];
}

const ProductCards: FC<ProductCardsProps> = ({
  cards,
}: ProductCardsProps) => (
  <>
    {cards.map((productCard: ProductCard) => {
      const { title, description, imageCrops } = productCard;

      return (
        <div className={styles.container} key={key(productCard)}>
          <div className={styles.imageContainer}>
            <ResponsiveImage urls={imageCrops} />
          </div>
          <div className={styles.textContainer}>
            <div className={styles.title}>{title}</div>
            <div className={styles.description}>{description}</div>
          </div>
        </div>
      );
    })}
  </>
);

export default ProductCards;
