import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import Breakpoints, { BreakpointsKeys } from '@/common/constants/breakpoints';

interface MediaQuery {
  minBreakpoint?: BreakpointsKeys;
  maxBreakpoint?: BreakpointsKeys;
}

const getMaxWidth = (breakpoint: BreakpointsKeys): number => {
  const breakpointNames = Object.keys(Breakpoints);
  const currentBreakpointIndex = breakpointNames.indexOf(breakpoint);
  const nextBreakpoint = Breakpoints[breakpointNames[currentBreakpointIndex + 1]];

  return Number(nextBreakpoint) ? (nextBreakpoint - 0.02) : 0;
};

const useMediaQuery = ({ minBreakpoint, maxBreakpoint }: MediaQuery): boolean => {
  const [shouldBeVisible, setShouldBeVisible] = useState(false);

  const mediaQuery = useMemo((): string => {
    if (!minBreakpoint && !maxBreakpoint) {
      return '';
    }

    const minBreakpointWidth = minBreakpoint && Breakpoints[minBreakpoint];
    const minMediaQuery = minBreakpointWidth ? `(min-width: ${minBreakpointWidth}px)` : '';

    const maxBreakpointWidth = maxBreakpoint && getMaxWidth(maxBreakpoint);
    const maxMediaQuery = maxBreakpointWidth ? `(max-width: ${maxBreakpointWidth}px)` : '';

    return minMediaQuery + ((minMediaQuery && maxMediaQuery) ? ' and ' : '') + maxMediaQuery;
  }, [minBreakpoint, maxBreakpoint]);

  const handleMatchMedia = useCallback((mediaQueryList: MediaQueryList | MediaQueryListEvent): void => {
    setShouldBeVisible(mediaQueryList.matches);
  }, []);

  useEffect(() => {
    if (!mediaQuery) {
      return () => {
      };
    }

    const mediaQueryList = window.matchMedia(mediaQuery);
    handleMatchMedia(mediaQueryList);

    mediaQueryList.addEventListener('change', handleMatchMedia);

    return (): void => {
      mediaQueryList.removeEventListener('change', handleMatchMedia);
    };
  }, [mediaQuery, handleMatchMedia]);

  return shouldBeVisible;
};

export default useMediaQuery;
