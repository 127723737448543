import React, { FC, useState } from 'react';
import Input from '@/common/components/Form/Input/Input';
import { ReactComponent as EyeOpenIcon } from '@/assets/icons/eyeOpen.svg';
import { ReactComponent as EyeCloseIcon } from '@/assets/icons/eyeClose.svg';
import styles from './Password.module.scss';

interface PasswordProps {
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
  isValid?: boolean;
  errorMessage?: string;
}

const Password: FC<PasswordProps> = ({
  value,
  placeholder,
  onChange,
  isValid,
  errorMessage,
}: PasswordProps) => {
  const [isValueShown, setIsValueShown] = useState(false);

  const toggleValueVisibility = (): void => setIsValueShown((prevIsValueShown) => !prevIsValueShown);

  return (
    <div className={styles.container}>
      <Input
        customClassNames={{ input: styles.input }}
        errorMessage={errorMessage}
        isValid={isValid}
        onChange={onChange}
        label={placeholder}
        type={isValueShown ? 'text' : 'password'}
        value={value}
      />
      <button className={styles.toggleButton} onClick={toggleValueVisibility} type="button">
        {isValueShown ? (
          <EyeOpenIcon className={styles.iconOpen} />
        ) : (
          <EyeCloseIcon className={styles.iconClose} />
        )}
      </button>
    </div>
  );
};

export default Password;
