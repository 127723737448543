import { getCookie, sendSettingsToServer, setCookie } from '@/helpers/cookiesHelper';
import { GDPROptions } from '@/common/layouts/ProtectedLayout/components/LoginModal/LoginForm/types';

const updateRememberCookie = (): void => {
  setCookie('act-remember', '1', 365 * 2);
};

const allowFunctionalCookies = async (gdprOptions: GDPROptions): Promise<void> => {
  const { gaId, ...options } = gdprOptions;

  options.allowFunctionalCookies = true;

  return sendSettingsToServer(options.id, options, gaId);
};

export const refreshPage = (): void => {
  window.location.reload();
};

export const checkUserLock = (): boolean => {
  const accountSecurityCookie = getCookie('Acc_sec_lock');

  return (accountSecurityCookie === 'locked');
};

export const updateCookieSettings = (gdprOptions: GDPROptions): void => {
  allowFunctionalCookies(gdprOptions).then(() => {
    updateRememberCookie();
    refreshPage();
  });
};

export const redirectToHomePage = (): void => {
  setTimeout(() => {
    window.location.replace('/');
  }, 5000);
};
