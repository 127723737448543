import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import PageHeader, { PageHeaderProps } from '@/common/layouts/ParallaxPageLayout/components/PageHeader/PageHeader';
import MasterLayout from '@/common/layouts/MasterLayout/MasterLayout';
import { MasterLayoutProps } from '@/common/layouts/MasterLayout/types';
import styles from './ParallaxPageLayout.module.scss';

export interface ParallaxPageLayoutProps extends MasterLayoutProps {
  pageHeader?: PageHeaderProps;
  pageHeaderContent?: ReactNode;
  contentClassName?: string;
  children?: ReactNode;
}

const ParallaxPageLayout: FC<ParallaxPageLayoutProps> = ({
  pageHeader, pageHeaderContent, contentClassName, children, ...masterLayoutProps
}: ParallaxPageLayoutProps) => (
  <MasterLayout {...masterLayoutProps}>
    {(pageHeader && !pageHeaderContent) ? (
      <PageHeader {...pageHeader} />
    ) : pageHeaderContent}
    <div className={classNames((pageHeader || pageHeaderContent) && styles.contentContainer, contentClassName)}>
      {children}
    </div>
  </MasterLayout>
);

export default ParallaxPageLayout;
