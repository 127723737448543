import React, { FC, useCallback } from 'react';
import Banner, { BannerProps } from '@/common/components/Banner/Banner';
import { ModalName, useToggleModalsContext } from '@/common/contexts/globalModalContext';
import styles from './SidebarCard.module.scss';

const SidebarCard: FC<BannerProps> = ({
  button,
  ...bannerProps
}) => {
  const toggleModal = useToggleModalsContext();

  const showModal = useCallback((): void => {
    toggleModal({ [ModalName.customReport]: true });
  }, [toggleModal]);

  const buttonProps = {
    ...button,
    onClick: showModal,
  };

  return (
    <div className={styles.sidebarContainer}>
      <Banner
        customClassNames={{
          container: styles.bannerContainer,
          image: styles.bannerImage,
          title: styles.bannerTitle,
          description: styles.bannerDescription,
          button: styles.bannerButton,
        }}
        {...bannerProps}
        button={buttonProps}
      />
    </div>
  );
};

export default SidebarCard;
